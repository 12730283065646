import { JakoLogoPosition, ModelStyle } from './types';
import { useState, useRef, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Base64 } from 'js-base64';

import { Scrollbar } from 'react-scrollbars-custom';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import type { LogoTabInterface , LogoAddPosition} from './types';

import jerseyFrontTopCenter from '../assets/jakoImages/position/jakoLogo/center.jpg';
import jerseyFrontTopLeft from '../assets/jakoImages/position/jakoLogo/left.jpg';
import jerseyFrontTopRight from '../assets/jakoImages/position/jakoLogo/right.jpg';
import { LOGO_ADD_POSITION, CM_FACTOR } from './constants';
import { JfnetServices } from '../helpers/services';

import IconPosition from './icons/IconPosition';
import IconSize from './icons/IconSize';
import IconMoveForward from './icons/IconMoveForward';
import IconMoveBackward from './icons/IconMoveBackward';
import IconTrash from './icons/IconTrash';
import IconCross from './icons/IconCross';


enum LogoTabType {
  UploadLogo,
  LogoPosition
}

enum MobileLogoFunction {
  JakoLogo,
  Home,
  Position,
  Size,
}

export type JakoLogoTabHandler = {
  openLogoOptionByName: (objectName: string) => void,
};

const JakoLogoTab = forwardRef<JakoLogoTabHandler, LogoTabInterface>(({
  modelStyle,
  selectedAddedLogoKey,
  addedLogoItems,
  jakoLogoPosition,
  currentCuttingFormCode,
  colorList,
  onAddLogoInput,
  onClickLogoLock,
  onClickDeleteLogo,
  onSelectLogo,
  onChangeLogoPosition,
  onChangeWidthSize,
  onChangeHeightSize,
  onChangeLogoColor,
  onSelectAddLogoPosition,
  onSendLogoToFront,
  onSendLogoToBack,
  onSelectJakoLogoPosition,
  onSetLoading,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile
}, ref) => {
    useImperativeHandle(ref, () => ({
      openLogoOptionByName(logoName: string) {
        if (showMoreLogoOption[logoName]) {
          return;
        }
        let results = {};
        Object.keys(addedLogoItems).forEach((itemKey) => {
          results = {
            ...results,
            [itemKey]: logoName === itemKey
          };
        });
        setShowMoreLogoOption(results);
      },
    }));

    const [selectMobileFunction, setSelectMobileFunction] = useState<MobileLogoFunction | null>(MobileLogoFunction.Home);
    const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
    const scrollBarRef = useRef(null as any);

    const [selectTab, setSelectTab] = useState<LogoTabType>(LogoTabType.UploadLogo);
    const [showAddPosition, setShowAddPosition] = useState(false);
    const [showJakoLogoPosition, setShowJakoLogoPosition] = useState(true);
    const [selectedPosition, setSelectedPosition] = useState<{[key: string]: LogoAddPosition | null}>({});

    const [showMoreLogoOption, setShowMoreLogoOption] = useState<any>({});

    const inputWidthRef = useRef(null as any);
    const inputHeightRef = useRef(null as any);
    const [inputSizeWidth, setInputSizeWidth] = useState({} as { [key: string]: number });
    const [inputSizeHeight, setInputSizeHeight] = useState({} as { [key: string]: number });

    const [isUploadError, setIsUploadError] = useState(false);

    const { t } = useTranslation('translations');

    const isPolo = useMemo(() => currentCuttingFormCode.includes('PW'), [currentCuttingFormCode]);
    const isNoPositionInput = useMemo(() =>
      selectedAddedLogoKey
        ? selectedPosition[selectedAddedLogoKey] === null || selectedPosition[selectedAddedLogoKey] === undefined
        : false
    , [selectedPosition, selectedAddedLogoKey]);

    const mobileTabTitle = useMemo(() => {
      if (!selectedAddedLogoKey && selectMobileFunction === MobileLogoFunction.JakoLogo) {
        return t('configurator_3d_edit_logo');
      }
      if (!selectedAddedLogoKey) {
        return t('configurator_3d_add_logo');
      }
      if (isNoPositionInput) {
        return t('configurator_3d_choose_position');
      }
      if (selectMobileFunction !== null && selectMobileFunction !== MobileLogoFunction.Home) {
        return '';
      }
      if (selectMobileFunction !== null) {
        return '';
      }
      return '';
    }, [selectedAddedLogoKey, selectMobileFunction, t, isNoPositionInput]);

    const addPositionOptionKeys = useMemo(() => modelStyle === ModelStyle.Short
      ? Object.keys(LOGO_ADD_POSITION).filter((key) => key.includes('Short_'))
      : Object.keys(LOGO_ADD_POSITION).filter((key) => !key.includes('Short_'))
    , [modelStyle]);

    useEffect(() => {
      if (!scrollBarRef.current) {
        return;
      }

      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
      scrollBarRef.current.getScrollElement()
        .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
    }, []);

    useEffect(() => {
      setTimeout(() => {
        if (scrollBarRef.current) {
          onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
        }
      }, 100);
    }, [selectMobileFunction]);

    useEffect(() => {
      if (selectedAddedLogoKey && addedLogoItems[selectedAddedLogoKey]) {
        const widthCm = addedLogoItems[selectedAddedLogoKey].scaledWidth;
        const widthFloatToFixedOneBackToFloat = Math.round(widthCm * 10) / 10;
        if (inputWidthRef.current) {
          inputWidthRef.current.value = widthFloatToFixedOneBackToFloat;
        }
        const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = widthFloatToFixedOneBackToFloat;
        }

        setInputSizeWidth((prevState) => ({
          ...prevState,
          [selectedAddedLogoKey]: widthFloatToFixedOneBackToFloat
        }));

        const heightCm = addedLogoItems[selectedAddedLogoKey].scaledHeight;
        const heightFloatToFixedOneBackToFloat = Math.round(heightCm * 10) / 10;
        if (inputHeightRef.current) {
          inputHeightRef.current.value = heightFloatToFixedOneBackToFloat;
        }
        const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = heightFloatToFixedOneBackToFloat;
        }

        setInputSizeHeight((prevState) => ({
          ...prevState,
          [selectedAddedLogoKey]: heightFloatToFixedOneBackToFloat
        }));
        setSelectedPosition({
          ...selectedPosition,
          [selectedAddedLogoKey]:
            selectedPosition[selectedAddedLogoKey]
              ? selectedPosition[selectedAddedLogoKey]
              : addedLogoItems[selectedAddedLogoKey] ? addedLogoItems[selectedAddedLogoKey].position : null
        });
      } else {
        let results = {};
        Object.keys(addedLogoItems).forEach((itemKey) => {
          results = {
            ...results,
            [itemKey]: false
          };
          if (addedLogoItems[itemKey].position) {
            setSelectedPosition({
              ...selectedPosition,
              [itemKey]: addedLogoItems[itemKey].position
            });
          }
        });
        setShowMoreLogoOption(results);
      }
      // eslint-disable-next-line
    }, [addedLogoItems, selectedAddedLogoKey]);

    useEffect(() => {
      if (!selectedAddedLogoKey) {
        return;
      }
      setTimeout(() => {
        const tooltipList = document.querySelectorAll('.logo-tab .tooltip');
        tooltipList.forEach(function(el) {
          const element = el as any;
          if (el.children.length) {
            return;
          }
          const tooltip = document.createElement('label');
          tooltip.classList.add('tooltipBubble');
          tooltip.innerHTML = element.dataset.tooltip;
          element.appendChild(tooltip);
        });
      }, 300);
      // eslint-disable-next-line
    }, [selectedAddedLogoKey, colorList]);

    const { getRootProps, getInputProps } = useDropzone({
      // accept: {
      //   'image/*': ['.png', '.svg', '.pdf', '.ai', '.eps'],
      // },
      onDrop: onDropFile
    });

    function onDropFile(e: any, rejects: any) {
      setIsUploadError(false);

      if (rejects.length) {
        setIsUploadError(true);
        return;
      }
      setTimeout(() => {
        uploadChecking(e);
      }, 100);
    }

    function showSelectAddPositionHandler(data: {
      fileName: string,
      src: string,
      isUnprocessable: boolean,
      reference: string
    }) {
      if (!data.reference) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary !bg-theme-primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        return;
      }
      // testing get image by reference purpose
      setTimeout(() => {
        JfnetServices.getLogoByReference(data.reference)
          .then((res) => {
            onAddLogoInput({
              ...data,
              src: res.data
            });
          })
          .catch(() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          });
        }, 500);
    }

    function toBase64(file: File): Promise<string | ArrayBuffer | null> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

    function uploadChecking(files: any) {
      const file = files[0];
      onSetLoading(true);

      function onConfirmAlertClose(closeFn: () => void, imageBase64String: string, file: any, reference: string) {
        if (imageBase64String) {
          let svgString = Base64.decode(imageBase64String);
          if (svgString.slice(0, 10).includes('<?xml')) {
            svgString = svgString.substring(svgString.indexOf('<svg')+1);
            svgString = '<' + svgString;
          }
          showSelectAddPositionHandler({
            fileName: file.name,
            src: svgString,
            isUnprocessable: true,
            reference: reference || ''
          });
          closeFn();
        } else if (file && file.type && (file.type.toString().includes('png') || file.type.toString().includes('jp'))) {
          toBase64(file)
            .then((resBase64) => {
              if (!resBase64) {
                return;
              }
              showSelectAddPositionHandler({
                fileName: file.name,
                src: resBase64.toString(),
                isUnprocessable: true,
                reference: reference || ''
              });
            }).finally(() => {
              onSetLoading(false);
              closeFn();
            });
        } else {
          onSetLoading(false);
          closeFn();
        }
      }

      JfnetServices.checkLogoUpload(file)
        .then((res) => {
          if (!res.data.canBeUsed) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      <Trans>
                        { t('configurator_3d_invalid_filetype') }
                      </Trans>
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={() => onConfirmAlertClose(onClose, res.data.image, file, res.data.reference)}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            return;
          }
          if (res.data) {
            let svgString = Base64.decode(res.data.image);
            if (svgString.slice(0, 10).includes('<?xml')) {
              svgString = svgString.substring(svgString.indexOf('<svg')+1);
              svgString = '<' + svgString;
            }
            showSelectAddPositionHandler({
              fileName: file.name,
              src: svgString,
              isUnprocessable: false,
              reference: res.data.reference || ''
            });
          } else {
            onSetLoading(false);
          }
        })
        .catch((err) => {
          confirmAlert({
            customUI: ({ onClose }) => {
              if (err.response && err.response.status === 413) {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_file_too_large') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
              return (
                <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                  <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary !bg-theme-primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
        })
        .finally(() => {
          onSetLoading(false);
        });
    }

    function handleOnDecreaseSize(dimension: string) {
      if (!selectedAddedLogoKey) {
        return;
      }
      if (dimension === 'width') {
        const currentValue = inputSizeWidth[selectedAddedLogoKey];
        if (currentValue - 0.1 < 1) {
          return;
        }
        const widthCmToPixel = (currentValue - 0.1) * CM_FACTOR;
        onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
      } else {
        const currentValue = inputSizeHeight[selectedAddedLogoKey];
        if (currentValue - 0.1 < 1) {
          return;
        }
        const heightCmToPixel = (currentValue - 0.1) * CM_FACTOR;
        onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
      }
  }
  function handleOnIncreaseSize(dimension: string) {
    if (!selectedAddedLogoKey) {
      return;
    }
    if (dimension === 'width') {
      const currentValue = inputSizeWidth[selectedAddedLogoKey];
      const widthCmToPixel = (currentValue + 0.1) * CM_FACTOR;
      onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
    } else {
      const currentValue = inputSizeHeight[selectedAddedLogoKey];
      const heightCmToPixel = (currentValue + 0.1) * CM_FACTOR;
      onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
    }
  }
  function handleOnChangeWidthSize(event: any) {
    if (!selectedAddedLogoKey) {
      return;
    }
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = 1;
          const widthCmToPixel = 1 * CM_FACTOR;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
        const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = 1;
          const widthCmToPixel = 1 * CM_FACTOR;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
      } else {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = inputSizeWidth[selectedAddedLogoKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedLogoKey] * CM_FACTOR;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
        const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = inputSizeWidth[selectedAddedLogoKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedLogoKey] * CM_FACTOR;
          onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputWidthRef.current) {
      inputWidthRef.current.value = floatToFixedOneBackToFloat;
    }
    const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedLogoKey}`) as any;
    if (getWidthInput) {
      getWidthInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeWidth((prevState) => ({
      ...prevState,
      [selectedAddedLogoKey]: floatToFixedOneBackToFloat
    }));

    const widthCmToPixel = parseFloat(event.target.value) * CM_FACTOR;
    onChangeWidthSize(selectedAddedLogoKey, widthCmToPixel);
  }
  function handleOnChangeHeightSize(event: any) {
    if (!selectedAddedLogoKey) {
      return;
    }
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = 1;
          const heightCmToPixel = 1 * CM_FACTOR;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
        const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = 1;
          const heightCmToPixel = 1 * CM_FACTOR;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
      } else {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = inputSizeHeight[selectedAddedLogoKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedLogoKey] * CM_FACTOR;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
        const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = inputSizeHeight[selectedAddedLogoKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedLogoKey] * CM_FACTOR;
          onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputHeightRef.current) {
      inputHeightRef.current.value = floatToFixedOneBackToFloat;
    }
    const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedLogoKey}`) as any;
    if (getHeightInput) {
      getHeightInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeHeight((prevState) => ({
      ...prevState,
      [selectedAddedLogoKey]: floatToFixedOneBackToFloat
    }));

    const heightCmToPixel = parseFloat(event.target.value) * CM_FACTOR;
    onChangeHeightSize(selectedAddedLogoKey, heightCmToPixel);
  }

  function handleOnSelectLogo(logoName: string) {
    setSelectMobileFunction(MobileLogoFunction.Home);
    if (logoName === selectedAddedLogoKey) {
      handleSetShowMoreLogoOption(logoName);

      if (showMoreLogoOption[logoName]) {
        onSelectLogo(null);
      } else {
        onSelectLogo(logoName);
      }
      return;
    }
    onSelectLogo(logoName);
  }

  function handleSetShowMoreLogoOption(logoName: string) {
    let results = {};
    Object.keys(addedLogoItems).forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      ...results,
      [logoName]: !showMoreLogoOption[logoName],
    };
    setShowMoreLogoOption(results);
  }

  function handleOnChangePosition(name: string, positionKey: string, isInit?: boolean) {
    setSelectedPosition({
      ...selectedPosition,
      [name]: LOGO_ADD_POSITION[positionKey].position
    });
    onSelectAddLogoPosition(LOGO_ADD_POSITION[positionKey].jerseySection, LOGO_ADD_POSITION[positionKey].position, true);
    onChangeLogoPosition(name, positionKey, isInit);
    onCloseMobile();

    setTimeout(() => {
      if (scrollBarRef.current) {
        onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
      }
    }, 1000);
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }

    if (!selectedAddedLogoKey && selectMobileFunction === MobileLogoFunction.JakoLogo) {
      setSelectMobileFunction(null);
      return;
    }

    if (!selectedAddedLogoKey) {
      onCloseMobile();
      return;
    }
    if (isNoPositionInput) {
      onSelectLogo(null);
      return;
    }
    if (selectMobileFunction !== null && selectMobileFunction !== MobileLogoFunction.Home) {
      setSelectMobileFunction(MobileLogoFunction.Home);
      return;
    }
    if (selectMobileFunction !== null) {
      onSelectLogo(null);
      return;
    }
    onCloseMobile();
  }

  function handleOnSelectJakoLogoMobile() {
    setSelectMobileFunction(MobileLogoFunction.JakoLogo);
    onSelectLogo(null);
  }

  return isMobile
    ? <div
      className={
        "relative w-full px-6 pt-10 pb-6 "
        // + (
        //   selectedAddedLogoKey
        //     ? isNoPositionInput || selectMobileFunction === MobileLogoFunction.Position
        //       ? 'h-[280px]'
        //       : 'h-[180px]'
        //     : 'h-[280px]'
        // )
      }
    >
      <button
        className="absolute top-[21px] right-[7px] z-10"
        onClick={handleOnClickCloseMobile}
      >
        <IconCross className="h-[26px]" />
      </button>
      <p className="text-[#141414] text-[14px] font-semibold text-center min-h-[21px] pb-5">
        { mobileTabTitle }
      </p>
      <SimpleBar
        ref={scrollBarRef}
        style={{
          width: '100%',
          height: selectedAddedLogoKey
            ? isNoPositionInput || selectMobileFunction === MobileLogoFunction.Position
              ? '221px'
              : 'auto'
            : Object.keys(addedLogoItems).length ? '205px' : '214px',
          overflowX: 'hidden'
        }}
        autoHide={false}
        onScroll={onScrollContainerUpdate}
      >
        {
          selectedAddedLogoKey
            ? isNoPositionInput
              ? <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10">
                {
                  addPositionOptionKeys.map((addPositionKey, index) => {
                    const position = addPositionKey as LogoAddPosition;
                    return <button
                      key={'OPEN_LOGO_ADD_POSITION' + selectedAddedLogoKey + index}
                      className="bg-white group flex flex-col items-center w-[125px]"
                      onClick={() => handleOnChangePosition(selectedAddedLogoKey, addPositionKey, true)}
                    >
                      <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                        { t(LOGO_ADD_POSITION[position].label) }
                      </p>
                      <div
                        className={
                          "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                          + (selectedPosition[selectedAddedLogoKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                        }
                      >
                        <img
                          src={LOGO_ADD_POSITION[position].thumbnail}
                          alt=""
                          className="absolute !w-full h-[220px] object-cover"
                          style={{
                            objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                          }}
                        />
                      </div>
                    </button>;
                  })
                }
              </div>
              : <>
                {
                  selectMobileFunction === MobileLogoFunction.Home
                    ? <div className="mt-5">
                      <div className="flex items-center justify-between mb-5">
                        <p
                          className={
                            'font-bold text-[14px] truncate max-w-[80%]'
                            + (addedLogoItems[selectedAddedLogoKey].isUnprocessable ? ' text-[#FF6C00]' : '')
                          }
                        >
                          { addedLogoItems[selectedAddedLogoKey].fileName }
                        </p>
                        <button
                          className="relative iconButton delete z-20 text-[40px] text-[#141414]"
                          onClick={() => onClickDeleteLogo(selectedAddedLogoKey)}
                        >
                          <IconTrash />
                        </button>
                      </div>
                      <div className="grid grid-cols-4 justify-center overflow-x-hidden">
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileLogoFunction.Position)}>
                          <IconPosition className="h-[40px] text-[#141414]" />
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_position') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileLogoFunction.Size)}>
                          <IconSize className="h-[40px] text-[#141414]" />
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_size') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => onSendLogoToFront(selectedAddedLogoKey)}>
                          <IconMoveForward className="h-[40px] text-[#141414]" />
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_foreground') }
                          </span>
                        </button>
                        <button className="flex flex-col items-center group" onClick={() => onSendLogoToBack(selectedAddedLogoKey)}>
                          <IconMoveBackward className="h-[40px] text-[#141414]" />
                          <span className="text-[12px] font-medium text-[#141414]">
                            { t('configurator_3d_background') }
                          </span>
                        </button>
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileLogoFunction.Position
                    ? <div>
                      <p className="text-[#141414] text-[14px] font-semibold text-center">
                        { t('configurator_3d_choose_position') }
                      </p>
                      <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10">
                        {
                          addPositionOptionKeys.map((addPositionKey, index) => {
                            const position = addPositionKey as LogoAddPosition;
                            return <button
                              key={'OPEN_LOGO_ADD_POSITION' + selectedAddedLogoKey + index}
                              className="bg-white group flex flex-col items-center w-[125px]"
                              onClick={() => handleOnChangePosition(selectedAddedLogoKey, addPositionKey)}
                            >
                              <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                                { t(LOGO_ADD_POSITION[position].label) }
                              </p>
                              <div
                                className={
                                  "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                  + (selectedPosition[selectedAddedLogoKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                }
                              >
                                <img
                                  src={LOGO_ADD_POSITION[position].thumbnail}
                                  alt=""
                                  className="absolute !w-full h-[220px] object-cover"
                                  style={{
                                    objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                                  }}
                                />
                              </div>
                            </button>;
                          })
                        }
                      </div>
                    </div>
                    : <></>
                }
                {
                  selectMobileFunction === MobileLogoFunction.Size
                    ? <div className="mt-10">
                      <div className="flex items-center justify-around">
                        <div className="flex flex-col items-center">
                          <p className="text-[14px] mb-5">
                            {t('configurator_3d_height_cm')}
                          </p>
                          <div className="quantity quantitySizes">
                            <div className="spinner_wrapper">
                              <div
                                className="spinner_down"
                                onClick={() => handleOnDecreaseSize('height')}
                              >
                                <i className="icon icon-spinner-down"/>
                              </div>
                              {/* // 1cm = 37.79527559055118px */}
                              <input
                                ref={inputHeightRef}
                                defaultValue={Math.round((addedLogoItems[selectedAddedLogoKey].scaledHeight) * 10) / 10}
                                className="input_quantity_line"
                                name="input_item_quantity_sizes_"
                                id="input_item_quantity_sizes_"
                                type="number"
                                onFocus={(e) => e.target.select()}
                                onChange={(event) => handleOnChangeHeightSize(event)}
                              />
                              <div
                                className="spinner_up"
                                onClick={() => handleOnIncreaseSize('height')}
                              >
                                <i className="icon icon-spinner-up"/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col items-center">
                          <p className="text-[14px] mb-5">
                            {t('configurator_3d_width_cm')}
                          </p>
                          <div className="quantity quantitySizes">
                            <div className="spinner_wrapper">
                              <div
                                className="spinner_down"
                                onClick={() => handleOnDecreaseSize('width')}
                              >
                                <i className="icon icon-spinner-down"/>
                              </div>
                              {/* // 1cm = 37.79527559055118px */}
                              <input
                                ref={inputWidthRef}
                                defaultValue={Math.round((addedLogoItems[selectedAddedLogoKey].scaledWidth) * 10) / 10}
                                className="input_quantity_line"
                                name="input_item_quantity_sizes_"
                                id="input_item_quantity_sizes_"
                                type="number"
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => handleOnChangeWidthSize(e)}
                              />
                              <div
                                className="spinner_up"
                                onClick={() => handleOnIncreaseSize('width')}
                              >
                                <i className="icon icon-spinner-up"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <></>
                }
              </>
            : selectMobileFunction === MobileLogoFunction.JakoLogo
              ? <div className="grid grid-cols-2 gap-x-5 gap-y-4 mt-5 pr-4 justify-items-center">
                  {
                    ModelStyle.Short === modelStyle
                      ? <>
                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_short_right') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopLeft}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>

                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_short_left') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopRight}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>
                      </>
                      : <>
                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_front_top_right') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Right ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopRight}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>

                        <button
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <p
                            className={
                              "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? '  text-[#008AC9]' : ' text-[#141414]')
                            }
                          >
                            { t('configurator_3d_front_top_left') }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (jakoLogoPosition === JakoLogoPosition.Left ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={jerseyFrontTopLeft}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center -25px'
                              }}
                            />
                          </div>
                        </button>
                      </>
                  }
                  {
                    !isPolo
                      ? <button
                        className="bg-white group flex flex-col items-center w-[125px]"
                        onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Center)}
                      >
                        <p
                          className={
                            "font-semibold  mb-3 text-[12px] whitespace-nowrap"
                            + (jakoLogoPosition === JakoLogoPosition.Center ? '  text-[#008AC9]' : ' text-[#141414]')
                          }
                        >
                          { t('configurator_3d_front_top_center') }
                        </p>
                        <div
                          className={
                            "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                            + (jakoLogoPosition === JakoLogoPosition.Center ? ' !border-b-2 !border-b-[#008AC9]' : '')
                          }
                        >
                          <img
                            src={jerseyFrontTopCenter}
                            alt=""
                            className="absolute !w-full h-[220px] object-cover"
                            style={{
                              objectPosition: 'center -25px'
                            }}
                          />
                        </div>
                      </button>
                      : <></>
                  }
              </div>
              : Object.keys(addedLogoItems).length
                ? <div className="grid grid-cols-2 gap-x-5 gap-y-4 mt-5 pr-4">
                  <div className="form-group logoUpload h-[100px]">
                    <div
                      {
                        ...getRootProps(
                          {
                            id: 'dropArea',
                            className: 'dropzone h-[100px]'
                          }
                        )
                      }
                      >
                      <input {...getInputProps()} />
                      <form className="flex flex-col items-center justify-center">
                        <span className="icon icon-3d-upload !mb-0" />
                        <div className="text-[14px] text-center">{t('configurator_3d_drop_file_here')}</div>
                      </form>
                      {
                        isUploadError && <p className="text-[#FF6C00]">{ t('configurator_3d_file_type_error')}</p>
                      }
                    </div>
                  </div>
                  <button
                    className="w-full h-[100px] flex items-center justify-center bg-[#F0F0F0]"
                    onClick={handleOnSelectJakoLogoMobile}
                  >
                    { t('configurator_3d_edit_logo') }
                  </button>
                  {
                    Object.keys(addedLogoItems).map((itemKey, index: number) =>
                      <button
                        key={'addedLogoItems' + itemKey + index}
                        className="relative group w-full flex flex-col items-center"
                        onClick={() => handleOnSelectLogo(itemKey)}
                      >
                        {
                          addedLogoItems[itemKey].isUnprocessable
                            ? <span className="absolute top-px right-1 icon icon-info-circle text-[#FF6C00]" />
                            : <></>
                        }
                        <p
                          className={
                            'font-semibold text-[12px] mb-3 truncate w-[80%]'
                            + (
                              showMoreLogoOption[itemKey]
                                ? addedLogoItems[itemKey].isUnprocessable
                                  ? ' text-[#FF6C00]' : ' text-[#008AC9]'
                                : addedLogoItems[itemKey].isUnprocessable ? ' text-[#FF6C00]' : ''
                            )
                          }
                        >
                          { addedLogoItems[itemKey].fileName }
                        </p>
                        <div className="relative w-full h-[100px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]">
                          {
                            addedLogoItems[itemKey].src.includes('<svg')
                              ? <img src={`data:image/svg+xml;utf8,${encodeURIComponent(addedLogoItems[itemKey].src)}`} alt="" className="absolute !w-full h-[100px] object-contain"/>
                              : <img src={addedLogoItems[itemKey].src} alt="" className="absolute !w-full h-[100px] object-contain"/>
                          }
                        </div>
                      </button>
                    )
                  }
                </div>
                : <>
                  <div className="form-group logoUpload mt-5 mb-2">
                    <div
                      {
                        ...getRootProps(
                          {
                            id: 'dropArea',
                            className: 'marginBottom--small dropzone h-[90px]'
                          }
                        )
                      }
                      >
                      <input {...getInputProps()} />
                      <form className="flex flex-col items-center justify-center">
                        <span className="icon icon-3d-upload !mb-0" />
                        <div className="text-[14px] text-center">{t('configurator_3d_drop_file_here')} ({t('configurator_3d_logo_upload_maximum')})</div>
                        <div className="text-[12px] text-[#C3C3C3] text-center">{t('configurator_3d_accept_only_file')}</div>
                      </form>
                      {
                        isUploadError && <p className="text-[#FF6C00]">{ t('configurator_3d_file_type_error')}</p>
                      }
                    </div>
                  </div>
                  <button
                    className="w-full h-[90px] flex items-center justify-center bg-[#F0F0F0]"
                    onClick={handleOnSelectJakoLogoMobile}
                  >
                    { t('configurator_3d_edit_logo') }
                  </button>
                </>
        }
      </SimpleBar>
      {
        hasMoreScrollContainer
          ? <div
            className={
              "absolute w-full h-[40px] bottom-0 left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
            }
          />
          : <></>
      }
    </div>
    : <>
      <div className="tabs">
        <ul className="tabs__list">
          <li
            className={"tabs__li" + (selectTab === LogoTabType.UploadLogo ? ' active' : '')}
            onClick={() => setSelectTab(LogoTabType.UploadLogo)}
          >
            { t('configurator_3d_add_logo') }
          </li>
          <li
            className={"tabs__li " + (selectTab === LogoTabType.LogoPosition ? ' active' : '')}
            onClick={() => setSelectTab(LogoTabType.LogoPosition)}
          >
            { t('configurator_3d_edit_logo') }
          </li>
        </ul>
        <div
          className={
            "tabs__content mt-10 "
            + (selectTab === LogoTabType.UploadLogo ? ' active' : '')
          }
        >
          <div className="h1 text-center">{ t('configurator_3d_add_logo') }</div>
          <div className="text-center marginBottom--small">{ t('configurator_3d_add_logo_text') }</div>
          <div className="form-group logoUpload mb-10">
            <div className="form-group--headline">
              { t('configurator_3d_add_logo') }
            </div>
            <div
              {
                ...getRootProps(
                  {
                    id: 'dropArea',
                    className: 'marginBottom--small dropzone'
                  }
                )
              }
              >
              <input {...getInputProps()} />
              <form className="flex flex-col items-center justify-center">
                <span className="icon icon-3d-upload" />
                <div>{t('configurator_3d_drop_file_here')}</div>
                <div>{t('configurator_3d_or')}</div>
                <label className="link">{t('configurator_3d_browse_your_folder')}</label>
                <div>{t('configurator_3d_accept_only_file')}</div>
                <div>({t('configurator_3d_logo_upload_maximum')})</div>
              </form>
              {
                isUploadError && <p className="text-[#FF6C00]">{ t('configurator_3d_file_type_error')}</p>
              }
            </div>
          </div>

          <div className="slideContent slideContent__borderTop">
            {
              Object.keys(addedLogoItems).length
                ? <div className="relative py-4">
                    <div className="slideContainer__headline">
                      { t('configurator_3d_added_logos') }
                    </div>

                    <div className="flex flex-col">
                      {
                        Object.keys(addedLogoItems).map((itemKey, index: number) =>
                          <div
                            key={'addedLogoObj' + itemKey + index}
                          >
                            <div
                              id={'trigger' + itemKey + index}
                              key={addedLogoItems[itemKey].fileName + '_' + index}
                              className={
                                "relative w-full flex items-center justify-between py-8 px-4 border-b"
                                + (showMoreLogoOption[itemKey] ? ' border-transparent' : ' border-[#F0F0F0]')
                              }
                            >
                              <button
                                className="absolute w-full h-full inset-0 z-10"
                                onClick={() => handleOnSelectLogo(itemKey)}
                              />
                              <p
                                className={
                                  'font-semibold'
                                  + (
                                    showMoreLogoOption[itemKey]
                                      ? addedLogoItems[itemKey].isUnprocessable
                                        ? ' text-[#FF6C00]' : ' text-[#008AC9]'
                                      : addedLogoItems[itemKey].isUnprocessable ? ' text-[#FF6C00]' : ''
                                  )
                                }
                              >
                                { index + 1 }. { addedLogoItems[itemKey].fileName }
                              </p>
                              <div className="relative flex items-center gap-4 z-20">
                                <button
                                  className="relative iconButton delete z-20"
                                  onClick={() => onClickDeleteLogo(itemKey)}
                                >
                                  <i className="icon icon-3d-trash"/>
                                </button>
                                <button onClick={() => handleOnSelectLogo(itemKey)}>
                                  <i
                                    className={
                                      "icon icon-angle-right transform transition-all duration-500"
                                      + (showMoreLogoOption[itemKey] ? ' rotate-90' : ' rotate-0')
                                    }
                                  />
                                </button>
                              </div>
                            </div>

                            {
                              selectedPosition[itemKey] === null || selectedPosition[itemKey] === undefined
                                ? <div className={showMoreLogoOption[itemKey] ? ' block' : ' hidden'}>
                                  {/* Add Position */}
                                  <div className="slideContainer">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[#141414]">
                                        <span className="font-semibold">{ t('configurator_3d_choose_position') }</span>
                                      </p>
                                      <button
                                        className="flex items-center gap-4"
                                        onClick={() => setShowAddPosition(!showAddPosition)}
                                      >
                                        <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                          <div className="absolute top-0 left-0 w-[100px] h-[115px] object-cover bg-white" />
                                        </div>
                                        <i className={
                                          "icon icon-angle-right transform rotate-90"
                                        } />
                                      </button>
                                    </div>

                                    <div
                                      className={
                                        "slideContent__show active"
                                      }
                                    />
                                    <div className="slideContent__contentContainer mt-4">
                                      <div
                                        className={
                                          "relative border-t-2 border-[#f0f0f0]"
                                          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                        }
                                      >
                                        <Scrollbar
                                          disableTrackYWidthCompensation
                                          trackYProps={{ style: { width: '12px', background: 'none' } }}
                                          className='!h-[370px]'
                                        >
                                          <div className="relative itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                          {
                                            addPositionOptionKeys.map((addPositionKey, index) => {
                                              const position = addPositionKey as LogoAddPosition;
                                              return <button
                                                key={'OPEN_LOGO_ADD_POSITION' + itemKey + index}
                                                className="bg-white group flex flex-col min-w-[123px]"
                                                onClick={() => handleOnChangePosition(itemKey, addPositionKey, true)}
                                              >
                                                <div
                                                  className={
                                                    "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                    + (selectedPosition[itemKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                  }
                                                >
                                                  <img
                                                    src={LOGO_ADD_POSITION[position].thumbnail}
                                                    alt=""
                                                    className="absolute !w-full h-auto object-cover"
                                                    style={{
                                                      objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                                                    }}
                                                  />
                                                </div>
                                                <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                  { t(LOGO_ADD_POSITION[position].label) }
                                                </div>
                                              </button>;
                                            })
                                          }
                                          </div>
                                        </Scrollbar>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                : <div className={showMoreLogoOption[itemKey] ? ' block' : ' hidden'}>
                                  <div className="controlBarWrapper borderBottom">
                                    <div className="controlBar--left">
                                        {/* <button className="icon icon-3d-left-align"/>
                                        <button className="icon icon-3d-center-align"/>
                                        <button className="icon icon-3d-right-align"/>
                                        <button className="icon icon-3d-uppercase"/> */}
                                        {/* <button className="icon icon-3d-italic"/> */}
                                        <button
                                          className="icon icon-3d-lock"
                                          style={{
                                              color: addedLogoItems[itemKey].isLock ? '#008AC9' : 'inherit'
                                          }}
                                          onClick={() => onClickLogoLock(itemKey)}
                                        />
                                    </div>
                                  </div>
                                  {/* Add Position */}
                                  <div className="slideContainer">
                                    <div className="flex justify-between items-center">
                                      <p className="text-[#141414]">
                                        <span className="font-semibold">{ t('configurator_3d_choose_position') }</span> / { t(LOGO_ADD_POSITION[selectedPosition[itemKey] || addedLogoItems[itemKey].position].label) }
                                      </p>
                                      <button
                                        className="flex items-center gap-4"
                                        onClick={() => setShowAddPosition(!showAddPosition)}
                                      >
                                        <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                                          <img
                                            src={LOGO_ADD_POSITION[selectedPosition[itemKey] || addedLogoItems[itemKey].position].thumbnail}
                                            alt=''
                                            className="absolute top-0 left-0 w-[100px] h-[115px] object-cover"
                                            style={{
                                              objectPosition: 'center ' + LOGO_ADD_POSITION[selectedPosition[itemKey] || addedLogoItems[itemKey].position].thumbnailYPostionOption
                                            }}
                                          />
                                        </div>
                                        <i className={
                                          "icon icon-angle-right transform transition-all duration-500"
                                          + (showAddPosition ? ' rotate-90' : ' rotate-0')
                                        } />
                                      </button>
                                    </div>

                                    <div
                                      className={
                                        "slideContent__show"
                                        + (showAddPosition ? ' active' : '')
                                      }
                                    />
                                    <div className="slideContent__contentContainer mt-4">
                                      <div
                                        className={
                                          "relative border-t-2 border-[#f0f0f0]"
                                          + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                                        }
                                      >
                                        <Scrollbar
                                          disableTrackYWidthCompensation
                                          trackYProps={{ style: { width: '12px', background: 'none' } }}
                                          className='!h-[370px]'
                                        >
                                          <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                                            {
                                              addPositionOptionKeys.map((addPositionKey, index) => {
                                                const position = addPositionKey as LogoAddPosition;
                                                return <button
                                                  key={'OPEN_LOGO_ADD_POSITION' + itemKey + index}
                                                  className="bg-white group flex flex-col min-w-[123px]"
                                                  onClick={() => handleOnChangePosition(itemKey, addPositionKey)}
                                                >
                                                  <div
                                                    className={
                                                      "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                                      + (selectedPosition[itemKey] === LOGO_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                                    }
                                                  >
                                                    <img
                                                      src={LOGO_ADD_POSITION[position].thumbnail}
                                                      alt=""
                                                      className="absolute !w-full h-auto object-cover"
                                                      style={{
                                                        objectPosition: 'center ' + LOGO_ADD_POSITION[position].thumbnailYPostionOption
                                                      }}
                                                    />
                                                  </div>
                                                  <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                                    { t(LOGO_ADD_POSITION[position].label) }
                                                  </div>
                                                </button>;
                                              })
                                            }
                                          </div>
                                        </Scrollbar>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group borderBottom fontSize space--between">
                                    <div className="form-group--headline">
                                      {t('configurator_3d_width_cm')}
                                    </div>
                                    <div className="quantity quantitySizes">
                                      <div className="spinner_wrapper">
                                        <div
                                          className="spinner_down"
                                          onClick={() => handleOnDecreaseSize('width')}
                                        >
                                          <i className="icon icon-spinner-down"/>
                                        </div>
                                        {/* // 1cm = 37.79527559055118px */}
                                        <input
                                          id={`inputQtyWidth_${itemKey}`}
                                          defaultValue={Math.round((addedLogoItems[itemKey].scaledWidth) * 10) / 10}
                                          className="input_quantity_line"
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(e) => handleOnChangeWidthSize(e)}
                                        />
                                        <div
                                          className="spinner_up"
                                          onClick={() => handleOnIncreaseSize('width')}
                                        >
                                          <i className="icon icon-spinner-up"/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group borderBottom fontSize space--between">
                                    <div className="form-group--headline">
                                      {t('configurator_3d_height_cm')}
                                    </div>
                                    <div className="quantity quantitySizes">
                                      <div className="spinner_wrapper">
                                        <div
                                          className="spinner_down"
                                          onClick={() => handleOnDecreaseSize('height')}
                                        >
                                          <i className="icon icon-spinner-down"/>
                                        </div>
                                        {/* // 1cm = 37.79527559055118px */}
                                        <input
                                          id={`inputQtyHeight_${itemKey}`}
                                          defaultValue={Math.round((addedLogoItems[itemKey].scaledHeight) * 10) / 10}
                                          className="input_quantity_line"
                                          type="number"
                                          onFocus={(e) => e.target.select()}
                                          onChange={(event) => handleOnChangeHeightSize(event)}
                                        />
                                        <div
                                          className="spinner_up"
                                          onClick={() => handleOnIncreaseSize('height')}
                                        >
                                          <i className="icon icon-spinner-up"/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* {
                                    addedLogoItems[itemKey].fill
                                      ? <div className="slideContent">
                                        <div className="slideContainer">
                                          <div className="slideContainer__headline marginBottom--xsmall">
                                            { t('configurator_3d_choose_colors') }
                                          </div>
                                          <div className="colorWrapper">
                                            <div className="itemColor active ">
                                              <div className="itemColor--bgColor" style={{ backgroundColor: addedLogoItems[itemKey].fill }} />
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              "slideContent__show "
                                              + (showMoreColor ? 'active' : '')
                                            }
                                            onClick={() => setShowMoreColor(!showMoreColor)}
                                          >
                                            <div className="slideContent__plus">
                                              <div className="horizontal" />
                                              <div className="vertical" />
                                            </div>
                                          </div>
                                          <div className="logo-tab slideContent__contentContainer">
                                            <div className="colorWrapper">
                                              {
                                                colorList.map((itemColor, index) =>
                                                  <div
                                                    key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                                                    className={
                                                      "itemColor "
                                                      + (addedLogoItems[itemKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                                                    }
                                                    onClick={() => onChangeLogoColor('#' + itemColor.colorHexCode)}
                                                  >
                                                    <div
                                                      className="itemColor--bgColor tooltip"
                                                      style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                                                      data-tooltip={itemColor.description}
                                                    />
                                                  </div>
                                                )
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      : <></>
                                  } */}

                                  <div className="relative z-20 controlBarWrapper marginBottom--large">
                                    <div className="controlBar--left">
                                      <button
                                        className="icon icon-3d-foreground"
                                        onClick={() => onSendLogoToFront(itemKey)}
                                      />
                                      <button
                                        className="icon icon-3d-background"
                                        onClick={() => onSendLogoToBack(itemKey)}
                                      />
                                    </div>
                                    <div className="controlBar--right">
                                      <button
                                        className="icon icon-3d-trash"
                                        onClick={() => onClickDeleteLogo(itemKey)}
                                      />
                                    </div>
                                  </div>
                                </div>
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                : <></>
            }
          </div>
        </div>

        {/* JAKO Logo Position Tab */}
        <div
          className={
            "tabs__content mt-10 "
            + (selectTab === LogoTabType.LogoPosition ? ' active' : '')
          }
        >
          <div className="slideContent slideContent__borderTop">
            <div className="slideContainer">
              <div className="slideContainer__headline">
                { t('configurator_3d_edit_logo') }
              </div>
              <div
                className={
                  "slideContent__show slideContent__show--small "
                  + (showJakoLogoPosition ? 'active' : '')
                }
                onClick={() => setShowJakoLogoPosition(!showJakoLogoPosition)}
              >
                <div className="slideContent__plus">
                  <div className="horizontal" />
                  <div className="vertical" />
                </div>
              </div>
              <div className="slideContent__contentContainer">
                <div className="itemsWrapper--2cols">
                  {
                    ModelStyle.Short === modelStyle
                      ? <>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Right ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopLeft} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_short_right') }
                          </div>
                        </div>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Left ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopRight} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_short_left') }
                          </div>
                        </div>
                      </>
                      : <>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Right ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Right)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopRight} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_front_top_right') }
                          </div>
                        </div>
                        <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Left ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Left)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopLeft} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_front_top_left') }
                          </div>
                        </div>
                      </>
                  }
                  {
                    !isPolo
                      ? <div
                          className={
                            "itemTemplate "
                            + (jakoLogoPosition === JakoLogoPosition.Center ? 'active' : '')
                          }
                          onClick={() => onSelectJakoLogoPosition(JakoLogoPosition.Center)}
                        >
                          <div className="itemTemplate__image">
                            <img src={jerseyFrontTopCenter} alt="" className="w-full h-auto object-cover" />
                          </div>
                          <div className="itemTemplate__description">
                            { t('configurator_3d_front_top_center') }
                          </div>
                        </div>
                      : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-20">
        <button
          className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
          onClick={setSelectTabBack}
        >
          <div className="flex items-center">
            <i className="icon icon-angle-left text-[14px] mr-2" />
            <span>{ t('configurator_3d_back') }</span>
          </div>
        </button>
        <button
          className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
          onClick={setSelectTabNext}
        >
          <div className="flex items-center">
            <span>{ t('configurator_3d_basket_next') }</span>
            <i className="icon icon-angle-right text-[14px] ml-2" />
          </div>
        </button>
      </div>
    </>;
});

export default JakoLogoTab;
