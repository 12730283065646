import React from 'react';
import JakoReactComponent from './webcomponent/JakoReactComponent';
import BaseStore from './contexts/BaseStore';
import './styles/index.css';

//pages
export default function App() {
  return (
    <BaseStore>
      <JakoReactComponent />
    </BaseStore>
  );
}
