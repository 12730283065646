import axios from 'axios';

const wc = document.querySelector('web-configurator');

let baseapiurl: string = 'https://jako-api.dc-test.de/public';
let uiapiurl: string = 'https://jako.jfnet.de/api/v1/';

if (wc) {
  baseapiurl = wc.getAttribute('baseapiurl') || 'https://jako-api.dc-test.de/public';
  uiapiurl = wc.getAttribute('uiapiurl') || 'https://jako.jfnet.de/api/v1/';
}

export const apiClient = axios.create({
  baseURL: baseapiurl,
});
export const apiJfnet = axios.create({
  baseURL: uiapiurl,
});
export const newApiJfnet = axios.create({
  baseURL: 'https://jako-new-api.hosting.jfnet.de/api/v1',
});
