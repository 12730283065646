import type { DesignOption } from './types';
import type { CustomDesignResponseItem } from './responses';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext, useMemo, useRef } from 'react';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { JakoServices } from '../helpers/services';
import { BaseContext } from '../contexts/BaseContext';

import IconCross from './icons/IconCross';

enum DesignSelectTab {
  Design,
  Customer
}

export default function JakoDesignTab({
  designOptions,
  blankoDesignFile,
  blankoDesignPreviewFile,
  selectedDesign,
  onSelectDesign,
  onSelectCustomDesign,
  isMobile,
  onCloseMobile,
  isRaglan
}: {
  blankoDesignFile: string,
  blankoDesignPreviewFile: string,
  designOptions: {
    name: string,
    file: string,
    previewFile: string
  }[]
  selectedDesign: DesignOption | null,
  onSelectDesign: (design: DesignOption) => void,
  onSelectCustomDesign: (design: CustomDesignResponseItem) => void,
  isMobile: boolean,
  onCloseMobile: () => void,
  isRaglan: boolean
}) {
  const { t, i18n } = useTranslation('translations');
  const [jakoDesignOptions, setJakoDesignOptionsData] = useState<CustomDesignResponseItem[]>([]);
  const [selectTab, setSelectTab] = useState<DesignSelectTab>(DesignSelectTab.Design);
  const { correlationId, company, shopCode, languagecode } = useContext(BaseContext);

  const sortJakoDesignOptions = useMemo(() => jakoDesignOptions.sort((a, b) => a.configurationName.localeCompare(b.configurationName)), [jakoDesignOptions]);
  const sortStaticDesignOptions = useMemo(() => designOptions.sort((a, b) => a.name.toString().localeCompare(b.name.toString())), [designOptions]);

  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const scrollBarRef = useRef(null as any);

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    if (jakoDesignOptions.length) {
      return;
    }
    const payloadCorrelationId = correlationId || 'a6767fc135b792723ec27fd055a2cb8c';
    const payloadCompany = company || 'JAKO AG';
    const payloadShopCode = shopCode || 'B2C';
    JakoServices.getDesigns({
      correlationId: payloadCorrelationId,
      company: payloadCompany,
      shopCode: payloadShopCode,
      languageCode: i18n.language ? i18n.language.toUpperCase() : languagecode,
    }).then((res) => {
      setJakoDesignOptionsData(res.data);
    });
    // eslint-disable-next-line
  }, [i18n.language, designOptions]);

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    onCloseMobile();
  }

  if (!selectedDesign) {
    return <></>;
  }

  return isMobile
  ? <div className="relative w-full h-[320px] px-6 pt-10 pb-6">
    <button
      className="absolute top-[28px] right-[7px] z-20"
      onClick={handleOnClickCloseMobile}
    >
      <IconCross className="h-[26px]" />
    </button>
    <ul className="tabs__list mr-[18px]">
      <li
        className={
          "tabs__li"
          + (selectTab === DesignSelectTab.Design ? ' active' : '')
        }
        onClick={() => setSelectTab(DesignSelectTab.Design)}
      >
        { t('configurator_3d_design_templates') }
      </li>
      <li
        className={
          "tabs__li"
          + (selectTab === DesignSelectTab.Customer ? ' active' : '')
        }
        onClick={() => setSelectTab(DesignSelectTab.Customer)}
      >
        { t('configurator_3d_customer_templates') }
      </li>
    </ul>
    <SimpleBar
      ref={scrollBarRef}
      style={{
        width: '100%',
        height: 248
      }}
      autoHide={false}
      onScroll={onScrollContainerUpdate}
    >
      <div
        className={
          "tabs__content"
          + (selectTab === DesignSelectTab.Design ? ' active' : '')
        }
      >
        {
          designOptions.length
            ? <div className="grid grid-cols-2 gap-x-5 gap-y-4">
              <button
                key={'designOptionBlanko'}
                className="flex flex-col items-center"
                onClick={() => onSelectDesign({
                  file: blankoDesignFile,
                  name: 'Blanko',
                  previewFile: blankoDesignPreviewFile,
                })}
              >
                <div
                  className={
                    "relative border-2 border-[#D4D4D4] mt-2 overflow-hidden"
                      + (selectedDesign.file === blankoDesignFile
                        ? ' !border-b-[3px] !border-b-[#008AC9]'
                        : ''
                      )
                  }
                >
                  {
                    selectedDesign.file === blankoDesignFile
                      ? <span className="absolute top-2 right-1 icon icon-check-circle text-[#008AC9]" />
                      : <></>
                  }
                  <img
                    src={blankoDesignPreviewFile}
                    alt="Blanko"
                    className="w-[125px] h-[150px] object-contain"
                  />
                </div>
              </button>
              {
                sortStaticDesignOptions.map((design, index) =>
                  <button
                    key={'designOption' + design.file + index}
                    className="flex flex-col items-center"
                    onClick={() => onSelectDesign(design)}
                  >
                    <div
                      className={
                        "relative border-2 border-[#D4D4D4] mt-2 overflow-hidden"
                          + (selectedDesign.file === design.file
                            ? ' !border-b-[3px] !border-b-[#008AC9]'
                            : ''
                          )
                      }
                    >
                      {
                        selectedDesign.file === design.file
                          ? <span className="absolute top-2 right-1 icon icon-check-circle text-[#008AC9]" />
                          : <></>
                      }
                      <img
                        src={design.previewFile}
                        alt={design.name}
                        className="w-[125px] h-[150px] object-contain"
                      />
                    </div>
                  </button>
                )
              }
            </div>
            : <p>{ t('configurator_3d_no_design_available') }</p>
        }
      </div>
    </SimpleBar>
    {
      hasMoreScrollContainer
        ? <div
          className={
            "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
            + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
          }
        />
        : <></>
    }
  </div>
  : <>
    <div className="h1 text-center">{ t('configurator_3d_choose_design') }</div>
    <div className="text-center marginBottom--small">{ t('configurator_3d_choose_design_text') }</div>
    <div className="design-tab tabs">
    <ul className="tabs__list">
      <li
        className={
          "tabs__li"
          + (selectTab === DesignSelectTab.Design ? ' active' : '')
        }
        onClick={() => setSelectTab(DesignSelectTab.Design)}
      >
        { t('configurator_3d_design_templates') }
      </li>
      <li
        className={
          "tabs__li"
          + (selectTab === DesignSelectTab.Customer ? ' active' : '')
        }
        onClick={() => setSelectTab(DesignSelectTab.Customer)}
      >
        { t('configurator_3d_customer_templates') }
      </li>
    </ul>
      <div
        className={
          "tabs__content"
          + (selectTab === DesignSelectTab.Design ? ' active' : '')
        }
      >
        {
          designOptions.length
            ? <div className="itemsWrapper--2cols">
              <div
                key={'designOptionBlanko'}
                className={
                  "itemTemplate "
                  + (selectedDesign.file === blankoDesignFile ? "active" : "")
                }
                onClick={() => onSelectDesign({
                  file: blankoDesignFile,
                  name: 'Blanko',
                  previewFile: blankoDesignPreviewFile,
                })}
              >
                <div
                  className="itemTemplate__image"
                >
                  <img src={blankoDesignPreviewFile} alt="Blanko" className="object-contain" />
                </div>
              </div>
              {
                sortStaticDesignOptions.map((design, index) =>
                  <div
                    key={'designOption' + design.file + index}
                    className={
                      "itemTemplate "
                      + (selectedDesign.file === design.file ? "active" : "")
                    }
                    onClick={() => onSelectDesign(design)}
                  >
                    <div
                      className="itemTemplate__image"
                    >
                      <img src={design.previewFile} alt="" className="object-contain" />
                    </div>
                    <div className="itemTemplate__description">
                      { design.name.split('_').slice(2).join(' ') }
                    </div>
                  </div>
                )
              }
            </div>
            : <p>{ t('configurator_3d_no_design_available') }</p>
        }
      </div>
      <div
        className={
          "tabs__content"
          + (selectTab === DesignSelectTab.Customer ? ' active' : '')
        }
      >
        <div className="itemsWrapper--2cols">
          {
            sortJakoDesignOptions.map((customDesign, index) =>
              <div
                key={'designOption' + customDesign.designHeaderId}
                className="itemTemplate"
                onClick={() => onSelectCustomDesign(customDesign)}
              >
                <div
                  className="itemTemplate__image"
                >
                  <img
                    src={customDesign.previewImages[0].image} alt={customDesign.configurationName}
                    className="absolute top-0 w-auto h-[190px] object-cover"
                  />
                </div>
                {/* <div className="itemTemplate__description">
                  { customDesign.configurationName }
                </div> */}
              </div>
            )
          }
        </div>
      </div>
    </div>
  </>;
}