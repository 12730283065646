import { useGLTF } from '@react-three/drei';
import { useThree, useLoader } from '@react-three/fiber';
import { fabric } from 'fabric';
import React, { useState, useEffect, useCallback, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { CanvasTexture, MeshStandardMaterial, Raycaster, FrontSide, BufferGeometry, Mesh } from 'three';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { EquirectangularReflectionMapping, LinearFilter } from 'three/src/constants';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { v4 as uuidv4 } from 'uuid';
import { Base64 } from 'js-base64';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import {
  FunctionTab,
  JakoLogoPosition,
  JerseySection,
  ModelStyle
} from '../webcomponent/types';

import type { Transform } from 'fabric/fabric-impl';
import type { Vector3 } from 'three';
import type {
  LogoUploadObject,
  AddedTextItems,
  AddedLogoItems,
  AddedTextPosition,
  AddedImagePosition,
  AddTextInterface,
  AddImageInterface,
  AddedPatternJerseySectionItems,
  DownloadSvgFontToBase64,
  BasicAddPosition,
  LogoAddPosition,
  TextAddPosition,
  NameInitialAddPosition,
  NumberAddPosition,
  TeamnameAddPosition,
  TextEditorObject,
  LogoEditorObject
} from '../webcomponent/types';
import { JfnetServices } from '../helpers/services';
import { CM_FACTOR, MAX_TEXT_SIZE_HEIGHT_CM, MAX_LOGO_SIZE_HEIGHT_CM } from '../webcomponent/constants';

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

// Define the handle types which will be passed to the forwardRef
export type ConfiguratorFabricHandler = {
  rasterizeSVG: (base64Fonts: DownloadSvgFontToBase64[]) => void;
  getRasterizeSVGFile: (base64Fonts: DownloadSvgFontToBase64[]) => Blob | undefined

  lockObject: (objectName: string) => void,
  unlockObject: (objectName: string) => void,
  deleteObject: (objectName: string) => void,
  updateTextColor: (textName: string, color: string) => void,
  updateTextFontFamily: (textName: string, font: string) => void,
  updateTextNewTextValue: (name: string, textNames: string[], textNewValue: string, reference: string) => Promise<{
    itemName: string,
    reference: string,
    scaledHeight: number,
    scaledWidth: number,
  }[]> | Promise<[]>,
  setActiveObjectHandler: (obj: any) => void,

  updateTextFontSizeWidth: (textName: string, width: number) => void,
  updateTextFontSizeHeight: (textName: string, height: number) => void,

  updateLogoSizeWidth: (textName: string, width: number) => void,
  updateLogoSizeHeight: (textName: string, height: number) => void,
  updateLogoColor: (logoName: string, color: string) => void,

  editTextHandler: ({
    textName,
    text,
    svgData,
    fontFamily,
  }: {
    textName: string,
    text: string,
    svgData: string,
    fontFamily?: string
  }) => Promise<{
    reference: string,
    scaledHeight: number,
    scaledWidth: number,
  }>,

  updateObjectPosition: (
    position: string,
    name?: string,
    axis?: {
      left: number,
      top: number,
    },
  ) => void,
  resetDefaultPosition: (objectName: string, defaultX: number, defaultY: number) => void,

  bringToFront: (objectName: string) => void,
  sendToBack: (objectName: string) => void,

  updateCanvasTextureMap: () => void,
  scene: any,
  fabricCanvas: React.MutableRefObject<fabric.Canvas | undefined>,
  designSvgPatternObjectsBounding: any
};

type ConfiguratorFabricProps = {
  currentCuttingFormCode: string,
  modelStyle: ModelStyle,
  activateDesignOnBack: boolean,
  text: string,
  addedTextItems: AddedTextItems,
  jakoLogoPosition: JakoLogoPosition,
  logo: LogoUploadObject | null,
  addedLogoItems: AddedLogoItems,
  isAddingState: FunctionTab | null,
  addSectionPositionRef: BasicAddPosition | NameInitialAddPosition | NumberAddPosition | TeamnameAddPosition | TextAddPosition | LogoAddPosition | null
  addSectionPart: JerseySection,
  designSvg: string,
  selectedJerseySectionColor: any,
  selectedJerseySectionPattern: AddedPatternJerseySectionItems,
  selectedFontFamily: string,
  selectedTextFill: string,
  colorLogoHex: string,
  colorDotsLeftHex: string,
  colorDotsRightHex: string,
  colorInvertedBg: string,
  onAddTextPosition: ({ adding, object, position, reference, textSize }: AddTextInterface) => void,
  onAddImagePosition: ({ adding, object, imageObj, logoSize }: AddImageInterface) => void,
  onAddImageLogoInit: (object: any[]) => void,
  onDisableControl: () => void,
  onEnableControl: () => void,
  onTextTabBack: () => void,
  onSetPossibleDesignAreaId: (e: string[]) => void,
  onSetLoading: (e: boolean) => void,
  onSetLoadingInitial: (e: boolean) => void,
  onDeleteFromControl: (name: string) => void
  onUpdateTextFontSize: (name: string, data: {
    width: number,
    height: number,
  }) => void,
  onUpdateLogoSize: (name: string, data: {
    width: number,
    height: number,
  }) => void,
  onSelectObjectItem: (name: string) => void,
  onFocusCamera: (vec: Vector3) => void,
  onObjectModified: (type: string, id: string) => void,
  onObjectAdded: (type: string, id: string) => void,
  onObjectDeleted: (type: string, id: string) => void,
  onChangeColor: () => void,
  onChangeTextColor: () => void,
  onAddPattern: () => void,
  onCloseMobileTab: () => void,
  onSetHasBackDesign: (status: boolean) => void,

  modelFile: string,
  modelSeparateFile: string,
  normalMap: string,

  dotsLeft: string,
  dotsRight: string,
  jakoLogoLeft: string,
  jakoLogoRight: string,
  jakoLogoCenter: string,

  isMobile: boolean
};

export const ConfiguratorModelFabric = forwardRef<ConfiguratorFabricHandler, ConfiguratorFabricProps>((props, ref) => {
  const { gl, camera, mouse } = useThree();
  const { t } = useTranslation('translations');

  const { scene, nodes } = useGLTF(props.modelFile, 'https://jako-wc.hosting.jfnet.de/draco/gltf/') as any;
  const jerseyNormalMap = useLoader(TextureLoader, props.normalMap || '');
  // const [hovered, setHover] = useState(null)
  const fabricCanvas = useRef<fabric.Canvas | undefined>();
  const fabricInsideCanvas = useRef<fabric.Canvas | undefined>();
  const canvasRef = useRef<HTMLCanvasElement >();
  const canvasInsideRef = useRef<HTMLCanvasElement >();

  const currentDesignSvgString = useRef('');
  const designSvgPatternObject = useRef<any>();
  const designSvgPatternInsideObject = useRef<any>();
  const designSvgPatternObjectsBounding = useRef<{
    [key: string]: any
  }>({});
  const [initialized, setInitialized] = useState(false);
  const [selectedDesignSvgId, setSelectedDesignSvgId] = useState<string>();
  // const [activateDesignOnBackState, setActivateDesignOnBackState] = useState(false);
  const SVG_SIZE = useMemo(() => {
    return props.isMobile ? 512 : 2048;
  }, [props.isMobile]);
  const cmFactorAdjusted = useMemo(() => props.isMobile ? CM_FACTOR / 4 : CM_FACTOR ,[props.isMobile]);

  const jakoLogoPositionUv = useMemo(() => {
    if (props.jakoLogoPosition === JakoLogoPosition.Left) {
      return props.jakoLogoLeft;
    } else if (props.jakoLogoPosition === JakoLogoPosition.Right) {
      return props.jakoLogoRight;
    } else {
      return props.jakoLogoCenter;
    }
  }, [props.jakoLogoLeft, props.jakoLogoRight, props.jakoLogoCenter, props.jakoLogoPosition]);

  const jakoDotsUvLeft = useMemo(() => {
    return props.dotsLeft;
  }, [props.dotsLeft]);
  const jakoDotsUvRight = useMemo(() => {
    return props.dotsRight;
  }, [props.dotsRight]);
  const raycaster = useMemo(() => new Raycaster(), []);

  const centerLineColor = 'rgb(105,105,105)';
  const centerLineWidth = props.isMobile ? 2 : 5;
  const centerLineMargin = 5;
  const centerHorizontalLine = useRef<{
    startX: number,
    width: number,
    heightCenter: number,
    viewportTransform: number[] | undefined
  }>({
    startX: 0,
    width: 0,
    heightCenter: 0,
    viewportTransform: []
  });
  const centerVerticalLine = useRef<{
    startY: number,
    widthCenter: number,
    height: number,
    viewportTransform: number[] | undefined
  }>({
    startY: 0,
    widthCenter: 0,
    height: 0,
    viewportTransform: []
  });
  const frontWidthCenterMap = useRef({});
  const frontHeightCenterMap = useRef({});

  const backWidthCenterMap = useRef({});
  const backHeightCenterMap = useRef({});

  const leftWidthCenterMap = useRef({});
  const leftHeightCenterMap = useRef({});

  const rightWidthCenterMap = useRef({});
  const rightHeightCenterMap = useRef({});

  // ------ Alignment Guideline ------
  const viewportTransform = useRef<number[] | undefined>();
  const zoom = useRef<number>(1);
  const isInVerticalCenter = useRef(false);
  const isInHorizontalCenter = useRef(false);
  const verticalLines = useRef<{
    y1: number,
    y2: number,
    x: number
  }[]>([]);
  const horizontalLines = useRef<{
    x1: number,
    x2: number,
    y: number
  }[]>([]);
  const horizontalInTheRange = useRef(false);
  const verticalInTheRange = useRef(false);

  const aligningLineOffset = 15;
  const aligningLineMargin = 10;
  const aligningLineWidth = props.isMobile ? 2 : 5;
  const aligningLineColor = 'rgb(105,105,105)';
  // ------ End of Alignment Guideline ------

  useImperativeHandle(ref, () => ({
    rasterizeSVG (base64Fonts: DownloadSvgFontToBase64[]) {
      if (!fabricCanvas.current) {
        return;
      }

      const fileData = fabricCanvas.current.toSVG();
      let fontFaceText = ``;
      base64Fonts.forEach((item) => {
        fontFaceText += `
          @font-face{
            font-family: "${item.font}";
            src: url("${item.base64}");
          }
        `;
      });
      const addedCustomFont = fileData.replace('<defs>', `
      <defs>
        <style type="text/css">
          ${fontFaceText}
        </style>
      `);

      const locFile = new Blob([addedCustomFont], {type: "image/svg+xml;charset=utf-8"});
      const locFileSrc = URL.createObjectURL(locFile);
      const dwn = document.createElement('a');
      dwn.download = 'myshirt.svg';
      dwn.href = locFileSrc;
      dwn.click();
    },
    getRasterizeSVGFile (base64Fonts: DownloadSvgFontToBase64[]) {
      if (!fabricCanvas.current) {
        return;
      }

      const fileData = fabricCanvas.current.toSVG({
        width: 2048,
        height: 2048
      });
      let fontFaceText = ``;
      base64Fonts.forEach((item) => {
        fontFaceText += `
          @font-face{
            font-family: "${item.font}";
            src: url("${item.base64}");
          }
        `;
      });
      const addedCustomFont = fileData.replace('<defs>', `
      <defs>
        <style type="text/css">
          ${fontFaceText}
        </style>
      `);

      const locFile = new Blob([addedCustomFont], {type: "image/svg+xml;charset=utf-8"});
      // ----- localDevelopment_testResult -----
      // const locFileSrc = URL.createObjectURL(locFile);
      // const dwn = document.createElement('a');
      // dwn.download = 'myshirt.svg';
      // dwn.href = locFileSrc;
      // dwn.click();
      // ----- End of localDevelopment_testResult -----
      return locFile;
    },
    lockObject(textName: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object | fabric.Text;
      if (foundedTextObj) {
        foundedTextObj.set({
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
        });
        setTimeout(() => {
          fabricCanvas.current?.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }
    },
    unlockObject(textName: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object | fabric.Text;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.set({
        selectable: true,
        lockMovementX: false,
        lockMovementY: false,
        lockScalingX: false,
        lockScalingY: false,
        lockRotation: false,
      });
      fabricCanvas.current.setActiveObject(foundedTextObj);
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    deleteObject(textName: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      fabricCanvas.current.remove(foundedTextObj);
      if (foundedTextObj.name) {
        if (foundedTextObj.fontSize) {
          props.onObjectDeleted('text', foundedTextObj.name);
        } else {
          props.onObjectDeleted('logo', foundedTextObj.name);
        }
      }
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    updateTextColor(textName: string, color: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.set('selectable', true);
      foundedTextObj.set('fill', color);

      const canvas = fabricCanvas.current;
      setTimeout(() => {
        canvas.setActiveObject(foundedTextObj);
        setTimeout(() => {
          props.onChangeTextColor();
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateTextFontFamily(textName: string, font: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.set('selectable', true);
      foundedTextObj.set('fontFamily', font);

      const canvas = fabricCanvas.current;
      setTimeout(() => {
        canvas.setActiveObject(foundedTextObj);
        canvas.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    updateTextNewTextValue(name: string, textNames: string[], textNewValue: string, reference: string) {
      if (!fabricCanvas.current) {
        return Promise.resolve([]);
      }
      const foundedSelectedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as any;
      const getTextWithOutCurrentNameOfReplaceItems = textNames.slice().filter((itemKey) => itemKey !== name);
      const loadAndSetNewTextPromises: Promise<{
        itemName: string,
        reference: string,
        scaledHeight: number,
        scaledWidth: number,
      }>[] =
        getTextWithOutCurrentNameOfReplaceItems
          .map((textItemKey: any) => new Promise((resolve, reject) => {
            if (!fabricCanvas.current) {
              reject();
              return;
            }

            const foundedTextObjOfReplaceItem = fabricCanvas.current._objects.find((obj) => obj.name === textItemKey) as any;
            // if they are using the same font family
            if (foundedSelectedTextObj && foundedSelectedTextObj.fontFamily === foundedTextObjOfReplaceItem.fontFamily) {
              JfnetServices.getLogoByReference(reference)
                .then((textImageByRefRes) => {
                  if (textImageByRefRes.data) {
                    let svgTextString = textImageByRefRes.data;
                    if (svgTextString.slice(0, 10).includes('<?xml')) {
                      svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
                      svgTextString = '<' + svgTextString;
                    }
                    fabric.loadSVGFromString(
                      svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
                      function (objects) {
                        const svgImage = fabric.util.groupSVGElements(objects, {
                          left: foundedTextObjOfReplaceItem.left,
                          top: foundedTextObjOfReplaceItem.top,
                          selectable: true,
                          centeredScaling: true,
                          cornerStyle: 'circle',
                          transparentCorners: false,
                          snapAngle: 90,
                          snapThreshold: 5,
                          editable: false,
                          name: foundedTextObjOfReplaceItem.name,
                          noScaleCache: true,
                          lockUniScaling: true,
                          objectCaching: false,
                          padding: 0,
                          caching: false,
                          originX: 'center',
                          originY: 'center',
                        }) as any;

                        svgImage.name = foundedTextObjOfReplaceItem.name;
                        svgImage.left = foundedTextObjOfReplaceItem.left;
                        svgImage.top = foundedTextObjOfReplaceItem.top;
                        svgImage.originX = 'center';
                        svgImage.originY = 'center';
                        svgImage.centeredScaling = true;
                        svgImage.lockUniScaling = true;
                        svgImage.snapAngle = 90;
                        svgImage.snapThreshold = 5;
                        svgImage.noScaleCache = true;
                        svgImage.objectCaching = false;
                        svgImage.fontFamily = foundedTextObjOfReplaceItem.fontFamily;
                        svgImage.fill = foundedTextObjOfReplaceItem.fill;
                        svgImage.text = textNewValue;

                        svgImage.scaleToHeight(foundedTextObjOfReplaceItem.getScaledHeight());
                        svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;

                        fabricCanvas.current?.remove(foundedTextObjOfReplaceItem);
                        fabricCanvas.current?.add(svgImage);
                        resolve({
                          itemName: foundedTextObjOfReplaceItem.name,
                          reference: reference,
                          scaledHeight: foundedTextObjOfReplaceItem.getScaledHeight() / cmFactorAdjusted,
                          scaledWidth: svgImage.getScaledWidth() / cmFactorAdjusted
                        });
                      }
                    );
                  }
                });
            } else {
              JfnetServices.fontToSvg({
                text: textNewValue,
                font: foundedTextObjOfReplaceItem.fontFamily
              }).then((newTextFontRes) => {
                if (newTextFontRes.data) {
                  let svgTextString = newTextFontRes.data;
                  if (svgTextString.slice(0, 10).includes('<?xml')) {
                    svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
                    svgTextString = '<' + svgTextString;
                  }
                  fabric.loadSVGFromString(
                    svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
                    function (objects) {
                      const svgImage = fabric.util.groupSVGElements(objects, {
                        left: foundedTextObjOfReplaceItem.left,
                        top: foundedTextObjOfReplaceItem.top,
                        selectable: true,
                        centeredScaling: true,
                        cornerStyle: 'circle',
                        transparentCorners: false,
                        snapAngle: 90,
                        snapThreshold: 5,
                        editable: false,
                        name: foundedTextObjOfReplaceItem.name,
                        noScaleCache: true,
                        lockUniScaling: true,
                        objectCaching: false,
                        padding: 0,
                        caching: false,
                        originX: 'center',
                        originY: 'center',
                      }) as any;

                      svgImage.name = foundedTextObjOfReplaceItem.name;
                      svgImage.left = foundedTextObjOfReplaceItem.left;
                      svgImage.top = foundedTextObjOfReplaceItem.top;
                      svgImage.originX = 'center';
                      svgImage.originY = 'center';
                      svgImage.centeredScaling = true;
                      svgImage.lockUniScaling = true;
                      svgImage.snapAngle = 90;
                      svgImage.snapThreshold = 5;
                      svgImage.noScaleCache = true;
                      svgImage.objectCaching = false;
                      svgImage.fontFamily = foundedTextObjOfReplaceItem.fontFamily;
                      svgImage.fill = foundedTextObjOfReplaceItem.fill;
                      svgImage.text = textNewValue;

                      svgImage.scaleToHeight(foundedTextObjOfReplaceItem.getScaledHeight());
                      svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;

                      fabricCanvas.current?.remove(foundedTextObjOfReplaceItem);
                      fabricCanvas.current?.add(svgImage);

                      const file = new File([svgTextString], 'test.svg', {
                        type: 'image/svg+xml'
                      });
                      JfnetServices.checkLogoUpload(file)
                        .then((uploadRes) => {
                          resolve({
                            itemName: foundedTextObjOfReplaceItem.name,
                            reference: uploadRes.data.reference,
                            scaledHeight: foundedTextObjOfReplaceItem.getScaledHeight() / cmFactorAdjusted,
                            scaledWidth: svgImage.getScaledWidth() / cmFactorAdjusted
                          });
                        })
                        .catch((err) => {
                          reject();
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              if (err.response && err.response.status === 413) {
                                return (
                                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                                      { t('configurator_3d_error_file_too_large') }
                                    </h5>
                                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                                      { t('configurator_3d_ok') }
                                    </button>
                                  </div>
                                );
                              }
                              return (
                                <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                                  <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                                    { t('configurator_3d_error_something_wrong') }
                                  </h5>
                                  <button className="button_primary !bg-theme-primary" onClick={onClose}>
                                    { t('configurator_3d_ok') }
                                  </button>
                                </div>
                              );
                            }
                          });
                        });
                    }
                  );
                }
              });
            }
          })
        );
      return Promise.all(loadAndSetNewTextPromises)
        .then((res) => {
          if (!fabricCanvas.current) {
            return res;
          }
          const canvas = fabricCanvas.current;
          setTimeout(() => {
            if (foundedSelectedTextObj) {
              canvas.setActiveObject(foundedSelectedTextObj);
              setTimeout(() => {
                canvas.requestRenderAll();
                updateCanvasTextureMap();
              }, 100);
            }
          }, 100);
          return res;
        });
    },
    updateTextFontSizeWidth(textName: string, width: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.clone((obj:any) => {
        const getTabName = textName.split('_')[0];
        const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
        let maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
        if (FunctionTab.Number ===  getFunctionTab) {
          maxHeight = 30;
        } else {
          maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
        }
        let adjustedWidth = (width / CM_FACTOR) * cmFactorAdjusted;
        obj.scaleToWidth(adjustedWidth);
        if (obj.getScaledHeight() / cmFactorAdjusted > maxHeight) {
          foundedTextObj.set('selectable', true);
          foundedTextObj.scaleToHeight(maxHeight * cmFactorAdjusted);
          if (!fabricCanvas.current) {
            return;
          }
          const canvas = fabricCanvas.current;
          setTimeout(() => {
            props.onUpdateTextFontSize(textName, {
              width: foundedTextObj.getScaledWidth() / cmFactorAdjusted,
              height: foundedTextObj.getScaledHeight() / cmFactorAdjusted,
            });
            canvas.setActiveObject(foundedTextObj);
            setTimeout(() => {
              canvas.requestRenderAll();
              updateCanvasTextureMap();
            }, 100);
          }, 100);
          return;
        }

        foundedTextObj.set('selectable', true);
        foundedTextObj.scaleToWidth(adjustedWidth);

        if (!fabricCanvas.current) {
          return;
        }
        const canvas = fabricCanvas.current;
        setTimeout(() => {
          props.onUpdateTextFontSize(textName, {
            width: width / CM_FACTOR,
            height: foundedTextObj.getScaledHeight() / cmFactorAdjusted,
          });
          canvas.setActiveObject(foundedTextObj);
          setTimeout(() => {
            canvas.requestRenderAll();
            updateCanvasTextureMap();
          }, 100);
        }, 100);
      });
    },
    updateTextFontSizeHeight(textName: string, height: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedTextObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as any;
      if (!foundedTextObj) {
        return;
      }
      foundedTextObj.clone((obj:any) => {
        const getTabName = textName.split('_')[0];
        const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
        let maxHeight = 7.5;
        if (FunctionTab.Number ===  getFunctionTab) {
          maxHeight = 30;
        } else {
          maxHeight = 7.5;
        }
        let adjustedHeight = (height / CM_FACTOR) * cmFactorAdjusted;
        obj.scaleToHeight(adjustedHeight);
        if (obj.getScaledHeight() / cmFactorAdjusted > maxHeight) {
          foundedTextObj.set('selectable', true);
          foundedTextObj.scaleToHeight(maxHeight * cmFactorAdjusted);
          if (!fabricCanvas.current) {
            return;
          }
          const canvas = fabricCanvas.current;
          setTimeout(() => {
            props.onUpdateTextFontSize(textName, {
              width: foundedTextObj.getScaledWidth() / cmFactorAdjusted,
              height: foundedTextObj.getScaledHeight() / cmFactorAdjusted,
            });
            canvas.setActiveObject(foundedTextObj);
            setTimeout(() => {
              canvas.requestRenderAll();
              updateCanvasTextureMap();
            }, 100);
          }, 100);
          return;
        }

        foundedTextObj.set('selectable', true);
        foundedTextObj.scaleToHeight(adjustedHeight);

        if (!fabricCanvas.current) {
          return;
        }
        const canvas = fabricCanvas.current;
        setTimeout(() => {
          props.onUpdateTextFontSize(textName, {
            width: foundedTextObj.getScaledWidth() / cmFactorAdjusted,
            height: height / CM_FACTOR,
          });
          canvas.setActiveObject(foundedTextObj);
          setTimeout(() => {
            canvas.requestRenderAll();
            updateCanvasTextureMap();
          }, 100);
        }, 100);
      });
    },
    updateLogoSizeWidth(textName: string, width: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object;
      if (!foundedLogoObj) {
        return;
      }
      foundedLogoObj.set('selectable', true);
      let adjustedWidth = (width / CM_FACTOR) * cmFactorAdjusted;
      foundedLogoObj.scaleToWidth(adjustedWidth);
      const canvas = fabricCanvas.current;
      setTimeout(() => {
        props.onUpdateLogoSize(textName, {
          width: width / CM_FACTOR,
          height: foundedLogoObj.getScaledHeight() / cmFactorAdjusted,
        });
        canvas.setActiveObject(foundedLogoObj);
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateLogoSizeHeight(textName: string, height: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === textName) as fabric.Object;
      if (!foundedLogoObj) {
        return;
      }
      foundedLogoObj.set('selectable', true);
      let adjustedHeight = (height / CM_FACTOR) * cmFactorAdjusted;
      foundedLogoObj.scaleToHeight(adjustedHeight);
      const canvas = fabricCanvas.current;
      setTimeout(() => {
        props.onUpdateLogoSize(textName, {
          width: foundedLogoObj.getScaledWidth() / cmFactorAdjusted,
          height: height / CM_FACTOR,
        });
        canvas.setActiveObject(foundedLogoObj);
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateLogoColor(name: string, color: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedLogoObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      if (!foundedLogoObj) {
        return;
      }
      foundedLogoObj.set('selectable', true);
      foundedLogoObj.set('fill', color);
      const canvas = fabricCanvas.current;
      setTimeout(() => {
        canvas.setActiveObject(foundedLogoObj);
        setTimeout(() => {
          props.onChangeTextColor();
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }, 100);
    },
    updateObjectPosition(
      position: string,
      name?: string,
      axis?: {
        left: number,
        top: number,
      },
    ) {
      if (!fabricCanvas.current) {
        return;
      }
      let foundedObj = fabricCanvas.current.getActiveObject() as any;
      if (name) {
        foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      }
      if (!foundedObj) {
        return;
      }
      const canvas = fabricCanvas.current;
      if (position === 'left' && foundedObj.left) {
        foundedObj.left = foundedObj.left - 1;
      } else if (position === 'up' && foundedObj.top) {
        foundedObj.top = foundedObj.top - 1;
      } else if (position === 'right' && foundedObj.left) {
        foundedObj.left = foundedObj.left + 1;
      } else if (position === 'down' && foundedObj.top) {
        foundedObj.top = foundedObj.top + 1;
      }
      if (
        foundedObj.name
        && (
          position === 'left'
          || position === 'up'
          || position === 'right'
          || position === 'down'
        )
      ) {
        canvas.setActiveObject(foundedObj);
        if (foundedObj && foundedObj.fontFamily) {
          props.onObjectModified('text', foundedObj.name);
        } else if (foundedObj.name) {
          props.onObjectModified('logo', foundedObj.name);
        }
        setTimeout(() => {
          canvas.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
        return;
      }

      if (!axis) {
        return;
      }

      foundedObj.left = props.isMobile ? axis.left / 4 : axis.left;
      foundedObj.top = props.isMobile ? axis.top / 4 : axis.top;

      canvas.setActiveObject(foundedObj);
      if (foundedObj && foundedObj.fontFamily) {
        props.onObjectModified('text', foundedObj.name);
      } else if (foundedObj.name) {
        props.onObjectModified('logo', foundedObj.name);
      }

      setTimeout(() => {
        canvas.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    editTextHandler({
      textName,
      text,
      svgData,
      fontFamily
    }: {
      textName: string,
      text: string,
      svgData: string,
      fontFamily?: string,
    }) {
      return new Promise((resolve, reject) => {
        const foundedTextObj = fabricCanvas.current?._objects.find((obj) => obj.name === textName) as any;
        if (!foundedTextObj) {
          reject();
        }
        let svgTextString = svgData;
        if (svgTextString.slice(0, 10).includes('<?xml')) {
          svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
          svgTextString = '<' + svgTextString;
        }

        fabric.loadSVGFromString(
          svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
          function (objects) {
            const svgImage = fabric.util.groupSVGElements(objects, {
              left: foundedTextObj.left,
              top: foundedTextObj.top,
              selectable: true,
              centeredScaling: true,
              cornerStyle: 'circle',
              transparentCorners: false,
              snapAngle: 90,
              snapThreshold: 5,
              editable: false,
              name: textName,
              noScaleCache: true,
              lockUniScaling: true,
              objectCaching: false,
              padding: 0,
              caching: false,
              originX: 'center',
              originY: 'center',
            }) as any;

            svgImage.name = textName;
            svgImage.left = foundedTextObj.left;
            svgImage.top = foundedTextObj.top;
            svgImage.originX = 'center';
            svgImage.originY = 'center';
            svgImage.centeredScaling = true;
            svgImage.lockUniScaling = true;
            svgImage.snapAngle = 90;
            svgImage.snapThreshold = 5;
            svgImage.noScaleCache = true;
            svgImage.objectCaching = false;
            svgImage.fontFamily =
              fontFamily
                ? fontFamily
                : foundedTextObj.fontFamily
                  ? foundedTextObj.fontFamily
                  : props.selectedFontFamily;
            svgImage.fill = foundedTextObj.fill;
            svgImage.text = text;

            svgImage.scaleToHeight(foundedTextObj.getScaledHeight());
            svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;

            fabricCanvas.current?.remove(foundedTextObj);
            fabricCanvas.current?.add(svgImage);
            const file = new File([svgTextString], 'test.svg', {
              type: 'image/svg+xml'
            });
            JfnetServices.checkLogoUpload(file)
              .then((uploadRes) => {
                resolve({
                  reference: uploadRes.data.reference,
                  scaledHeight: foundedTextObj.getScaledHeight() / cmFactorAdjusted,
                  scaledWidth: svgImage.getScaledWidth() / cmFactorAdjusted
                });
              })
              .catch((err) => {
                reject();
                confirmAlert({
                  customUI: ({ onClose }) => {
                    if (err.response && err.response.status === 413) {
                      return (
                        <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                          <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                            { t('configurator_3d_error_file_too_large') }
                          </h5>
                          <button className="button_primary !bg-theme-primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                    return (
                      <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                        <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary !bg-theme-primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              });
          }
        );
      });
    },

    resetDefaultPosition(name: string, defaultX: number, defaultY: number) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as any;
      if (!foundedObj) {
        return;
      }
      const canvas = fabricCanvas.current;
      if (foundedObj.top && foundedObj.left) {
        foundedObj.top = props.isMobile ? defaultY / 4 : defaultY;
        foundedObj.left = props.isMobile ? defaultX / 4 : defaultX;
      }
      canvas.setActiveObject(foundedObj);

      if (foundedObj && foundedObj.fontFamily) {
        props.onObjectModified('text', name);
      } else if (foundedObj.name) {
        props.onObjectModified('logo', name);
      }

      setTimeout(() => {
        canvas.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },

    bringToFront(name: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      if (!foundedObj) {
        return;
      }
      foundedObj.bringForward();
      const logoObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as fabric.Object;
      const dotsLeftObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontleft') as fabric.Object;
      const dotsRightObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontright') as fabric.Object;
      const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;

      if (logoObj) {
        fabricCanvas.current.sendToBack(logoObj);
      }
      if (dotsLeftObj) {
        fabricCanvas.current.sendToBack(dotsLeftObj);
      }
      if (dotsRightObj) {
        fabricCanvas.current.sendToBack(dotsRightObj);
      }
      if (uvObj) {
        fabricCanvas.current.sendToBack(uvObj);
      }
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    sendToBack(name: string) {
      if (!fabricCanvas.current) {
        return;
      }
      const foundedObj = fabricCanvas.current._objects.find((obj) => obj.name === name) as fabric.Object;
      if (!foundedObj) {
        return;
      }
      foundedObj.sendBackwards();
      const logoObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as fabric.Object;
      const dotsLeftObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontleft') as fabric.Object;
      const dotsRightObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_frontright') as fabric.Object;
      const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;

      if (logoObj) {
        fabricCanvas.current.sendToBack(logoObj);
      }
      if (dotsLeftObj) {
        fabricCanvas.current.sendToBack(dotsLeftObj);
      }
      if (dotsRightObj) {
        fabricCanvas.current.sendToBack(dotsRightObj);
      }
      if (uvObj) {
        fabricCanvas.current.sendToBack(uvObj);
      }
      setTimeout(() => {
        fabricCanvas.current?.requestRenderAll();
        updateCanvasTextureMap();
      }, 100);
    },
    setActiveObjectHandler(obj: any) {
      const canvas = fabricCanvas.current;
      canvas?.setActiveObject(obj);
      canvas?.requestRenderAll();
      updateCanvasTextureMap();
    },
    updateCanvasTextureMap,
    scene,
    fabricCanvas,
    designSvgPatternObjectsBounding
  }));

  function getMouseUvPosition() {
    raycaster.setFromCamera( mouse, camera );
    const intersects = raycaster.intersectObjects([nodes['outside']]);
    if (intersects.length < 0) {
      return null;
    }
    if (!intersects[0]) {
      return null;
    }
    if (!intersects[0].uv) {
      return null;
    }

    const getOutsideIntersect = intersects.find((item) => item.object.name === 'outside');
    let pixelX = 0;
    let pixelY = 0;

    if (getOutsideIntersect && getOutsideIntersect.uv) {
      pixelX = Math.round(getOutsideIntersect.uv.x * SVG_SIZE);
      pixelY = Math.round(getOutsideIntersect.uv.y * SVG_SIZE);
    } else {
      pixelX = Math.round(intersects[0].uv.x * SVG_SIZE);
      pixelY = Math.round(intersects[0].uv.y * SVG_SIZE);
    }

    return {
      x: pixelX,
      y: pixelY,
    };
    // eslint-disable-next-line
  };

  const onAddTextItemHandler = useCallback((
    adding: FunctionTab,
    object: fabric.Object | fabric.Group,
    reference: string,
    textSize: {
      maxHeight: number,
      scaledWidth: number,
      scaledHeight: number
    },
    position?: BasicAddPosition | TextAddPosition | TeamnameAddPosition | NameInitialAddPosition | NumberAddPosition
  ) => {
    if (object.name) {
      props.onObjectAdded('text', object.name);
    }
    props.onAddTextPosition({
      adding,
      object,
      position,
      reference,
      textSize
    });
  }, [props]);

  const onAddImageItemHandler = useCallback((
    adding: FunctionTab,
    object: fabric.Object | fabric.Group,
    imageObj: LogoUploadObject,
    logoSize: {
      maxHeight: number,
      scaledWidth: number,
      scaledHeight: number,
    },
  ) => {
    if (object.name) {
      props.onObjectAdded('logo', object.name);
    }
    props.onAddImagePosition({
      adding,
      object,
      imageObj,
      logoSize
    });
  }, [props]);

  // ------ Centering Guideline --------
  function showCenterLine(x1: number, y1: number, x2: number, y2: number, viewportTransform: number[] | undefined) {
    if (!canvasRef.current) {
      return;
    }
    if (!viewportTransform) {
      return;
    }
    if (viewportTransform.length === 0) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }
    ctx.save();
    ctx.strokeStyle = centerLineColor;
    ctx.lineWidth = centerLineWidth;
    ctx.beginPath();
    ctx.setLineDash([5,5]);
    ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
    ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
    ctx.stroke();
    ctx.restore();
  }
  function showVerticalCenterLine({ startY, widthCenter, height, viewportTransform }: {
    startY: number,
    widthCenter: number,
    height: number,
    viewportTransform: number[] | undefined
  }) {
    showCenterLine(widthCenter + 0.5, startY, widthCenter + 0.5, startY + height, viewportTransform);
  }

  function showHorizontalCenterLine({ startX, width, heightCenter, viewportTransform }: {
    startX: number,
    width: number,
    heightCenter: number,
    viewportTransform: number[] | undefined
  }) {
    showCenterLine(startX, heightCenter + 0.5, startX + width, heightCenter + 0.5, viewportTransform);
  }
  // ------ End of Centering Guideline --------

  // ------ Alignment Guideline -------
  function drawLine(x1: number, y1: number, x2: number, y2: number, viewportTransform: number[] | undefined) {
    if (!canvasRef.current) {
      return;
    }
    if (!viewportTransform) {
      return;
    }
    if (viewportTransform.length === 0) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();
    ctx.setLineDash([5,5]);
    ctx.moveTo(((x1 + viewportTransform[4]) * zoom.current), ((y1 + viewportTransform[5]) * zoom.current));
    ctx.lineTo(((x2 + viewportTransform[4]) * zoom.current), ((y2 + viewportTransform[5]) * zoom.current));
    ctx.stroke();
    updateCanvasTextureMap();
    ctx.restore();
  }

  function drawVerticalLine(coords: {
    y1: number,
    y2: number,
    x: number
  }) {
    if (fabricCanvas.current) {
      drawLine(
        coords.x + 0.5,
        coords.y1 > coords.y2 ? coords.y2 : coords.y1,
        coords.x + 0.5,
        coords.y2 > coords.y1 ? coords.y2 : coords.y1,
        viewportTransform.current
      );
    }
  }

  function drawHorizontalLine(coords: {
    x1: number,
    x2: number,
    y: number
  }) {
    if (fabricCanvas.current) {
      drawLine(
        coords.x1 > coords.x2 ? coords.x2 : coords.x1,
        coords.y + 0.5,
        coords.x2 > coords.x1 ? coords.x2 : coords.x1,
        coords.y + 0.5,
        viewportTransform.current
      );
    }
  }
  function isInRange(value1: number, value2: number) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (let i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }
  // ------ End of Alignment Guideline -------

  function isClickOnObject(object: any) {
    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      return;
    }
    const isStillClickOnObject =
    (
      (object.oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (object.oCoords?.tr.x || 0)
    ) &&
    (
      (object.oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (object.oCoords?.bl.y || 0)
    );
    return isStillClickOnObject;
  }

  function onDocumentMouseDown(e: MouseEvent) {
    e.preventDefault();
    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      props.onCloseMobileTab();
      fabricCanvas.current?.discardActiveObject();
      fabricCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
      return;
    }

    const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
    if (fabricCanvas.current && hasActiveObject) {
      const objects = fabricCanvas.current.getActiveObjects();
      const isStillClickOnObject = ((objects[0].oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (objects[0].oCoords?.tr.x || 0))
        && ((objects[0].oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (objects[0].oCoords?.bl.y || 0));
      if (isStillClickOnObject) {
        props.onDisableControl();
      }
    } else {
      props.onEnableControl();
      props.onCloseMobileTab();
    }

    const getAddedObjects = fabricCanvas.current?.getObjects().filter((item:any) => !item.id);
    const getClickObject = getAddedObjects?.find((obj) => isClickOnObject(obj));
    if (getClickObject && getClickObject.selectable) {
      fabricCanvas.current?.setActiveObject(getClickObject);
    }

    // Dispatch mouse event to Fabric
    const pixelX = mouseUvPos.x;
    const pixelY = mouseUvPos.y;
    const fabricCanvasAny = fabricCanvas.current as any;
    const canvasRect = fabricCanvasAny._offset;
    const evt = new MouseEvent(e.type, {
      clientX: canvasRect.left + pixelX,
      clientY: canvasRect.top + pixelY
    });
    fabricCanvasAny.upperCanvasEl.dispatchEvent(evt);

    // eslint-disable-next-line
  }

  function onDocumentTouchDown(e: TouchEvent) {

    if (e.cancelable) {
      e.preventDefault();
    }
    const mouseCursorContainer = document.getElementById('configurator-container');
    if (!mouseCursorContainer) {
      return;
    }

    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      props.onCloseMobileTab();
      fabricCanvas.current?.discardActiveObject();
      fabricCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
      return;
    }

    const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
    if (fabricCanvas.current && hasActiveObject) {
      const objects = fabricCanvas.current.getActiveObjects();
      const isStillClickOnObject = ((objects[0].oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (objects[0].oCoords?.tr.x || 0))
        && ((objects[0].oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (objects[0].oCoords?.bl.y || 0));
      if (isStillClickOnObject) {
        props.onDisableControl();
      }
    } else {
      props.onEnableControl();
      props.onCloseMobileTab();
    }

    const getAddedObjects = fabricCanvas.current?.getObjects().filter((item:any) => !item.id);
    const getClickObject = getAddedObjects?.find((obj) => isClickOnObject(obj));
    if (getClickObject && getClickObject.selectable) {
      fabricCanvas.current?.setActiveObject(getClickObject);
    }

    // Dispatch mouse event to Fabric
    const pixelX = mouseUvPos.x;
    const pixelY = mouseUvPos.y;
    const fabricCanvasAny = fabricCanvas.current as any;
    const canvasRect = fabricCanvasAny._offset;
    const evt = new MouseEvent(e.type, {
      clientX: canvasRect.left + pixelX,
      clientY: canvasRect.top + pixelY
    });
    fabricCanvasAny.upperCanvasEl.dispatchEvent(evt);

    // eslint-disable-next-line
  };

  function onDocumentTouchMove(e: TouchEvent) {
    if (e.cancelable) {
      e.preventDefault();
    }
    const mouseCursorContainer = document.getElementById('configurator-container');
    if (!mouseCursorContainer) {
      return;
    }

    const mouseUvPos = getMouseUvPosition();
    if (!mouseUvPos) {
      props.onCloseMobileTab();
      fabricCanvas.current?.discardActiveObject();
      fabricCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
      return;
    }

    const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
    if (fabricCanvas.current && hasActiveObject) {
      const objects = fabricCanvas.current.getActiveObjects();
      const isStillClickOnObject = ((objects[0].oCoords?.tl.x || 0) <= mouseUvPos.x  && mouseUvPos.x <= (objects[0].oCoords?.tr.x || 0))
        && ((objects[0].oCoords?.tl.y || 0) <= mouseUvPos.y  && mouseUvPos.y <= (objects[0].oCoords?.bl.y || 0));
      if (isStillClickOnObject) {
        props.onDisableControl();
      }
    } else {
      props.onEnableControl();
      props.onCloseMobileTab();
    }

    const getAddedObjects = fabricCanvas.current?.getObjects().filter((item:any) => !item.id);
    const getClickObject = getAddedObjects?.find((obj) => isClickOnObject(obj));
    if (getClickObject && getClickObject.selectable) {
      fabricCanvas.current?.setActiveObject(getClickObject);
    }

    // Dispatch mouse event to Fabric
    const pixelX = mouseUvPos.x;
    const pixelY = mouseUvPos.y;
    const fabricCanvasAny = fabricCanvas.current as any;

    const evt = new MouseEvent('mousemove', {
      clientX: pixelX,
      clientY: pixelY
    });
    fabricCanvasAny.upperCanvasEl.dispatchEvent(evt);
    // eslint-disable-next-line
  }

  function onDocumentMouseUp(e: MouseEvent) {
    e.preventDefault();
    props.onEnableControl();
  }

  function onDocumentTouchUp(e: TouchEvent) {
    if (e.cancelable) {
      e.preventDefault();
    }
    props.onEnableControl();
  }

  function initDesignBoundingAndCenterMap(designObjects: any[]) {
    frontWidthCenterMap.current = {};
    frontHeightCenterMap.current = {};
    backWidthCenterMap.current = {};
    backHeightCenterMap.current = {};
    leftWidthCenterMap.current = {};
    leftHeightCenterMap.current = {};
    rightWidthCenterMap.current = {};
    rightHeightCenterMap.current = {};

    designSvgPatternObjectsBounding.current = {};

    designObjects.forEach((obj: any) => {
      if (obj.id === 'front_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: {
            ...obj,
            centerPoint: obj.getCenterPoint()
          }
        };
      } else if (obj.id === 'back_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      } else if (obj.id === 'left_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      } else if (obj.id === 'right_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      } else if (obj.id === 'collar_main') {
        designSvgPatternObjectsBounding.current = {
          ...designSvgPatternObjectsBounding.current,
          [obj.id]: obj
        };
      }
    });

    if (props.modelStyle === ModelStyle.Short) {
      initDesignBoundingAndCenterMapForShort();
      return;
    }

    initDesignBoundingAndCenterMapForShirt();
  }

  function initDesignBoundingAndCenterMapForShirt() {
    const fabricFrontObjCenterX = Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
    const fabricBackObjCenterX = Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
    const fabricLeftObjCenterX = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
    const fabricRightObjCenterX = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

    const fabricFrontObjCenterY = Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    const fabricBackObjCenterY = Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
    const fabricLeftObjCenterY = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    const fabricRightObjCenterY = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);

    for (let i = fabricFrontObjCenterX - centerLineMargin, len = fabricFrontObjCenterX + centerLineMargin; i <= len; i++) {
      frontWidthCenterMap.current = {
        ...frontWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricFrontObjCenterY - centerLineMargin, len = fabricFrontObjCenterY + centerLineMargin; i <= len; i++) {
      frontHeightCenterMap.current = {
        ...frontHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricBackObjCenterX - centerLineMargin, len = fabricBackObjCenterX + centerLineMargin; i <= len; i++) {
      backWidthCenterMap.current = {
        ...backWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricBackObjCenterY - centerLineMargin, len = fabricBackObjCenterY + centerLineMargin; i <= len; i++) {
      backHeightCenterMap.current = {
        ...backHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricLeftObjCenterX - centerLineMargin, len = fabricLeftObjCenterX + centerLineMargin; i <= len; i++) {
      leftWidthCenterMap.current = {
        ...leftWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricLeftObjCenterY - centerLineMargin, len = fabricLeftObjCenterY + centerLineMargin; i <= len; i++) {
      leftHeightCenterMap.current = {
        ...leftHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterX - centerLineMargin, len = fabricRightObjCenterX + centerLineMargin; i <= len; i++) {
      rightWidthCenterMap.current = {
        ...rightWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterY - centerLineMargin, len = fabricRightObjCenterY + centerLineMargin; i <= len; i++) {
      rightHeightCenterMap.current = {
        ...rightHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
  }

  function initDesignBoundingAndCenterMapForShort() {
    const fabricLeftObjCenterX = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
    const fabricRightObjCenterX = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

    const fabricLeftObjCenterY = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    const fabricRightObjCenterY = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);

    for (let i = fabricLeftObjCenterX - centerLineMargin, len = fabricLeftObjCenterX + centerLineMargin; i <= len; i++) {
      leftWidthCenterMap.current = {
        ...leftWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricLeftObjCenterY - centerLineMargin, len = fabricLeftObjCenterY + centerLineMargin; i <= len; i++) {
      leftHeightCenterMap.current = {
        ...leftHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterX - centerLineMargin, len = fabricRightObjCenterX + centerLineMargin; i <= len; i++) {
      rightWidthCenterMap.current = {
        ...rightWidthCenterMap.current,
        [Math.round(i)]: true
      };
    }
    for (let i = fabricRightObjCenterY - centerLineMargin, len = fabricRightObjCenterY + centerLineMargin; i <= len; i++) {
      rightHeightCenterMap.current = {
        ...rightHeightCenterMap.current,
        [Math.round(i)]: true
      };
    }
  }

  const setSvgDesignToFabric = () => {
    if (!fabricCanvas.current) {
      return;
    }
    if (!props.designSvg) {
      return;
    }

    const mainUvObject = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv');
    if (!mainUvObject || !currentDesignSvgString.current) {
      props.onSetLoading(true);
      const fileReader = new FileReader();
      fetch(props.designSvg).then((res) => {
        res.blob().then((blobRes) => {
          fileReader.readAsText(blobRes);
          fileReader.onload = () => {
            const svgFileTextResult = fileReader.result as string;
            if (!svgFileTextResult) {
              return;
            }
            setLoadSvgDesignFile(svgFileTextResult);
          };
        });
      });
    } else {
      updateSvgDesign(mainUvObject);
    }
  };

  function setLoadSvgDesignFile(svgFileTextResult: string) {
    const htmlObjectMain = document.createElement('div');
    htmlObjectMain.innerHTML = svgFileTextResult.toString();
    const mainSVG = htmlObjectMain.children[0];

    // mainSVG.setAttribute('viewBox', `0 0 ${SVG_SIZE} ${SVG_SIZE}`);
    mainSVG.setAttribute('width', SVG_SIZE.toString());
    mainSVG.setAttribute('height', SVG_SIZE.toString());

    const resetSvgNode = mainSVG.cloneNode(true) as any;
    Array.prototype.slice.call(resetSvgNode.children).forEach((ch: any) => {
      if (ch.id) {
        ch.remove();
      }
    });
    const resetSvgTextResult = resetSvgNode.outerHTML;
    const svgChildrenArrayMain = Array.prototype.slice.call(htmlObjectMain.children[0].children);
    // Handle Has Back Design Here
    const hasBackDesign = svgChildrenArrayMain.find((ch) => ch.id.includes('design-1_back'));
    props.onSetHasBackDesign(hasBackDesign);

    const onlyIdPathsMain = props.activateDesignOnBack
      ? svgChildrenArrayMain.slice().filter((ch) => ch.id)
      : svgChildrenArrayMain.slice().filter((ch) => ch.id && !ch.id.includes('design-1_back'));

    const newPathsMain = onlyIdPathsMain.slice().map((ch) => {
      const cloneCh = ch.cloneNode();
      return cloneCh;
    });
    const newHtmlStringPathsMain = newPathsMain.map((ch) => ch.outerHTML);

    const htmlObjectCopy = document.createElement('div');
    htmlObjectCopy.innerHTML = resetSvgTextResult;
    const filteredOnlyIdPathsCopy = onlyIdPathsMain.slice().map((ch) => {
      const cloneCh = ch.cloneNode();
      return cloneCh;
    });

    const newIdPathsCopy = filteredOnlyIdPathsCopy.map((ch) => {
      const newIdCh = ch;
      newIdCh.setAttribute('id', 'copy-' + ch.id);
      return newIdCh;
    });
    const newIdHtmlStringPathsCopy = newIdPathsCopy.map((ch) => ch.outerHTML);

    // BG AGAIN
    const htmlObjectBG = document.createElement('div');
    htmlObjectBG.innerHTML = resetSvgTextResult;
    const filteredOnlyIdPathsBG = onlyIdPathsMain.slice().map((ch) => {
      const cloneCh = ch.cloneNode();
      return cloneCh;
    });
    const newIdPathsBG = filteredOnlyIdPathsBG.map((ch) => {
      const newIdCh = ch;
      newIdCh.setAttribute('id', 'bg-' + ch.id);
      return newIdCh;
    });
    const newIdHtmlStringPathsBG = newIdPathsBG.map((ch) => ch.outerHTML);
    let stringCopySvgPath = '';
    newIdHtmlStringPathsCopy.forEach((itemStr, index) => {
      stringCopySvgPath += newIdHtmlStringPathsBG[index];
      stringCopySvgPath += itemStr;
    });

    let stringSvgMainPath = '';
    newHtmlStringPathsMain.forEach((itemStr, index) => {
      stringSvgMainPath += itemStr;
    });
    const appendCopyPathsInSVG = resetSvgTextResult.toString().replace('</svg>', stringSvgMainPath + '<g>' + stringCopySvgPath + '</g></svg>');

    currentDesignSvgString.current = appendCopyPathsInSVG;
    loadInitSvgDesign(currentDesignSvgString.current);
  }

  function loadInitSvgDesign(svgDesignString: string) {
    if (!fabricCanvas.current || !fabricInsideCanvas.current) {
      return;
    }

    fabric.loadSVGFromString(
      svgDesignString,
      function (objects) {
        if (!fabricCanvas.current || !fabricInsideCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
          return;
        }

        // Init the main UV SVG Pattern into 3D model
        const loadedSvgPattern = fabric.util.groupSVGElements(objects, {
          width: SVG_SIZE,
          height: SVG_SIZE,
          left: 0,
          top: 0,
          selectable: false,
          id: 'mainUv'
        });

        const mainLogoObject = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front');
        if (mainLogoObject) {
          loadedSvgPattern.objectCaching = false;
          loadedSvgPattern.set({
            selectable: false
          });
          designSvgPatternObject.current = loadedSvgPattern;
          initDesignBoundingAndCenterMap(designSvgPatternObject.current._objects);
          fabricCanvas.current.add(loadedSvgPattern);
          fabricCanvas.current.sendToBack(loadedSvgPattern);

          loadedSvgPattern.clone((obj: any) => {
            designSvgPatternInsideObject.current = obj;
            designSvgPatternInsideObject.current._objects.forEach((insideObj: any) => {
              if (insideObj.id) {
                insideObj.fill = '#f0f5f0';
              }
            });
            fabricInsideCanvas.current?.add(designSvgPatternInsideObject.current);
            fabricInsideCanvas.current?.sendToBack(designSvgPatternInsideObject.current);
          }, ['id', 'objects']);
          const possibleColorAreaIds = designSvgPatternObject.current._objects.map((v: any) => v.id) as string[];
          props.onSetPossibleDesignAreaId(possibleColorAreaIds);

          setTimeout(() => {
            changePattern(props.selectedJerseySectionPattern);
          }, 200);
        } else {
          getJakoLogoColorSvg().then((logoStringRes) => {
            fabric.loadSVGFromString(
              logoStringRes[0],
              function (logoObjects) {
                const loadedLogoSvg = fabric.util.groupSVGElements(logoObjects, {
                  width: SVG_SIZE,
                  height: SVG_SIZE,
                  left: 0,
                  top: 0,
                  selectable: false,
                });
                fabric.loadSVGFromString(
                  logoStringRes[1],
                  function (dotsLeftObjects) {
                    const loadedDotsLeftSvg = fabric.util.groupSVGElements(dotsLeftObjects, {
                      width: SVG_SIZE,
                      height: SVG_SIZE,
                      left: 0,
                      top: 0,
                      selectable: false,
                    });
                    fabric.loadSVGFromString(
                      logoStringRes[2],
                      function (dotsRightObjects) {
                        const loadedDotsRightSvg = fabric.util.groupSVGElements(dotsRightObjects, {
                          width: SVG_SIZE,
                          height: SVG_SIZE,
                          left: 0,
                          top: 0,
                          selectable: false,
                        });
                        if (!fabricCanvas.current || !fabricInsideCanvas.current || !canvasRef.current) {
                          return;
                        }
                        loadedSvgPattern.objectCaching = false;
                        loadedLogoSvg.objectCaching = false;
                        loadedDotsLeftSvg.objectCaching = false;
                        loadedDotsRightSvg.objectCaching = false;

                        const loadedLogoSvgAny = loadedLogoSvg as any;
                        loadedLogoSvgAny.set({
                          id: 'logo_front',
                          selectable: false,
                        });

                        designSvgPatternObject.current = loadedSvgPattern;
                        initDesignBoundingAndCenterMap(designSvgPatternObject.current._objects);
                        fabricCanvas.current.add(loadedSvgPattern);
                        loadedSvgPattern.clone((obj: any) => {
                          designSvgPatternInsideObject.current = obj;
                          designSvgPatternInsideObject.current._objects.forEach((insideObj: any) => {
                            if (insideObj.id) {
                              insideObj.fill = '#f0f5f0';
                            }
                          });
                          fabricInsideCanvas.current?.add(designSvgPatternInsideObject.current);
                          fabricInsideCanvas.current?.sendToBack(designSvgPatternInsideObject.current);
                        }, ['id', 'objects']);
                        fabricCanvas.current.add(loadedLogoSvgAny);
                        fabricCanvas.current.sendToBack(loadedLogoSvgAny);

                        if (logoStringRes[1]) {
                          const loadedDotsLeftSvgAny = loadedDotsLeftSvg as any;
                          loadedDotsLeftSvgAny.set({
                            id: 'dots_frontleft',
                            selectable: false,
                          });
                          fabricCanvas.current.add(loadedDotsLeftSvgAny);
                          fabricCanvas.current.sendToBack(loadedDotsLeftSvgAny);
                        }

                        if (logoStringRes[2]) {
                          const loadedDotsRightSvgAny = loadedDotsRightSvg as any;
                          loadedDotsRightSvgAny.set({
                            id: 'dots_frontright',
                            selectable: false,
                          });
                          fabricCanvas.current.add(loadedDotsRightSvgAny);
                          fabricCanvas.current.sendToBack(loadedDotsRightSvgAny);
                        }
                        fabricCanvas.current.sendToBack(loadedSvgPattern);
                        const possibleColorAreaIds = designSvgPatternObject.current._objects.map((v: any) => v.id) as string[];
                        props.onSetPossibleDesignAreaId(possibleColorAreaIds);
                        setTimeout(() => {
                          initAddTexts(props.addedTextItems)
                            .then(() => {
                              initAddLogos(props.addedLogoItems)
                                .then((res) => {
                                  const objects = res as any[];
                                  props.onAddImageLogoInit(objects);
                                  changePattern(props.selectedJerseySectionPattern);
                                  setTimeout(() => {
                                    if (fabricCanvas.current) {
                                      const anyFabricObjects = fabricCanvas.current._objects.slice() as any;
                                      fabricCanvas.current._objects = anyFabricObjects.sort((a: any, b: any) => {
                                        if (!a.indexLayer || !b.indexLayer) {
                                          return 0;
                                        } else {
                                          return a.indexLayer - b.indexLayer;
                                        }
                                      });
                                      setTimeout(() => {
                                        fabricCanvas.current?.requestRenderAll();
                                        updateCanvasTextureMap();
                                        setTimeout(() => {
                                          props.onSetLoadingInitial(false);
                                        }, 200);
                                      }, 200);
                                    }
                                  }, 100);
                                });
                            });
                        }, 200);
                      }
                    );
                  }
                );
              }
            );
          });
        }

        if (props.designSvg) {
          setSelectedDesignSvgId(props.designSvg);
        }

        const canvasTexture = new CanvasTexture(canvasRef.current, EquirectangularReflectionMapping);
        canvasTexture.anisotropy = gl.capabilities.getMaxAnisotropy();
        canvasTexture.flipY = false;
        canvasTexture.needsUpdate = true;
        canvasTexture.needsPMREMUpdate = true;
        canvasTexture.minFilter = LinearFilter;
        // canvasTexture.magFilter = NearestFilter;
        // canvasTexture.generateMipmaps = false;

        const insideCanvasTexture = new CanvasTexture(canvasInsideRef.current, EquirectangularReflectionMapping);
        insideCanvasTexture.anisotropy = gl.capabilities.getMaxAnisotropy();
        insideCanvasTexture.flipY = false;
        insideCanvasTexture.needsUpdate = true;
        insideCanvasTexture.needsPMREMUpdate = true;
        insideCanvasTexture.minFilter = LinearFilter;

        jerseyNormalMap.mapping = EquirectangularReflectionMapping;
        jerseyNormalMap.flipY = false;
        jerseyNormalMap.anisotropy = gl.capabilities.getMaxAnisotropy();
        jerseyNormalMap.minFilter = LinearFilter;
        // jerseyNormalMap.generateMipmaps = false;
        jerseyNormalMap.needsUpdate = true;

        nodes['Scene'].position.set(0, -0.1, 0);
        nodes['Scene'].scale.set(0.055, 0.055, 0.055);

        const material = new MeshStandardMaterial({
          map: canvasTexture,
          normalMap: jerseyNormalMap,
          side: FrontSide,
          // metalness: 0.1,
          roughness: 1,
          envMapIntensity: 0.85,
          transparent: false
        });
        const materialInside = new MeshStandardMaterial({
          map: insideCanvasTexture,
          normalMap: jerseyNormalMap,
          side: FrontSide,
          roughness: 1,
          envMapIntensity: 0.5,
          transparent: false
        });

        const materialInsideCollar = new MeshStandardMaterial({
          map: insideCanvasTexture,
          normalMap: jerseyNormalMap,
          side: FrontSide,
          roughness: 1,
          envMapIntensity: 0.5,
          transparent: false,
        });
        if (nodes['inside_collar']) {
          nodes['inside_collar'].material = materialInsideCollar;
        }

        nodes['outside'].material = material;
        nodes['inside'].material = materialInside;

        // --------------- Fabric Event Listner ---------------------
        fabricCanvas.current.on('mouse:move',  function(e) {
          document.body.style.cursor = 'grabbing';
          const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
          if (hasActiveObject) {
            const mainDotsLeftObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontleft');
            if (mainDotsLeftObject) {
              fabricCanvas.current?.bringToFront(mainDotsLeftObject);
            }
            const mainDotsRightObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontright');
            if (mainDotsRightObject) {
              fabricCanvas.current?.bringToFront(mainDotsRightObject);
            }
            const mainLogoObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'logo_front');
            if (mainLogoObject) {
              fabricCanvas.current?.bringToFront(mainLogoObject);
            }
            props.onDisableControl();
          } else {
            props.onEnableControl();
          }
        });

        fabricCanvas.current.on('mouse:down', function () {
          if (!fabricCanvas.current) {
            return;
          }
          const hasActiveObject = fabricCanvas.current && fabricCanvas.current.getActiveObjects().length > 0;
          if (hasActiveObject) {
            const getObj = fabricCanvas.current.getActiveObject();
            if (getObj) {
              props.onSelectObjectItem(getObj.name || '');
            }
            const mainDotsLeftObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontleft');
            if (mainDotsLeftObject) {
              fabricCanvas.current?.bringToFront(mainDotsLeftObject);
            }
            const mainDotsRightObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontright');
            if (mainDotsRightObject) {
              fabricCanvas.current?.bringToFront(mainDotsRightObject);
            }
            const mainLogoObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'logo_front');
            if (mainLogoObject) {
              fabricCanvas.current?.bringToFront(mainLogoObject);
            }
            props.onDisableControl();
          } else {
            props.onEnableControl();
            props.onTextTabBack();
          }
          viewportTransform.current = fabricCanvas.current.viewportTransform;
          zoom.current = fabricCanvas.current.getZoom();
        });

        fabricCanvas.current.on('mouse:up', function(e) {
          document.body.style.cursor = 'auto';
          props.onEnableControl();
          const mainDotsLeftObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontleft');
          if (mainDotsLeftObject) {
            fabricCanvas.current?.bringToFront(mainDotsLeftObject);
          }
          const mainDotsRightObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'dots_frontright');
          if (mainDotsRightObject) {
            fabricCanvas.current?.bringToFront(mainDotsRightObject);
          }
          const mainLogoObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'logo_front');
          if (mainLogoObject) {
              fabricCanvas.current?.bringToFront(mainLogoObject);
          }

          isInVerticalCenter.current = false;
          isInHorizontalCenter.current = false;

          if (horizontalLines.current) {
            horizontalLines.current.length = 0;
          }

          if (verticalLines.current) {
            verticalLines.current.length = 0;
          }
          fabricCanvas.current?.renderAll();
        });

        fabricCanvas.current.on('object:scaling', function(event: any) {
          if (event.target && event.target.fontFamily) {
            const foundedObj = event.target as any;
            foundedObj.clone((obj: any) => {
              const getTabName = foundedObj.name.split('_')[0];
              const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
              let maxHeight = 7.5;
              if (FunctionTab.Number ===  getFunctionTab) {
                maxHeight = 30;
              } else {
                maxHeight = 7.5;
              }
              if (maxHeight !== 0) {
                obj.scaleToHeight(maxHeight * cmFactorAdjusted);
                let maxScaleX = obj.scaleX;
                let maxScaleY = obj.scaleY;
                if (maxScaleX && (event.target.scaleX > maxScaleX)) {
                  event.target.scaleX = maxScaleX;
                  event.target.scaleY = maxScaleY;
                }
              }
              props.onUpdateTextFontSize(event.target.name, {
                width: event.target.getScaledWidth() / cmFactorAdjusted,
                height: event.target.getScaledHeight() / cmFactorAdjusted
              });
            });
          } else {
            props.onUpdateLogoSize(event.target.name, {
              width: event.target.getScaledWidth() / cmFactorAdjusted,
              height: event.target.getScaledHeight() / cmFactorAdjusted,
            });
          }
        });

        fabricCanvas.current.on('object:modified', function(event: any) {
          if (event.target && event.target.fontFamily) {
            if (event.target.name) {
              props.onObjectModified('text', event.target.name);
            }
          } else if (event.target.name) {
            props.onObjectModified('logo', event.target.name);
          }
        });

        fabricCanvas.current.on('selection:created', function(event: any) {
          if (event.selected && event.selected[0] && event.selected[0].name) {
            props.onSelectObjectItem(event.selected[0].name);
          }
          const mouseCursorContainer = document.getElementById('configurator-container');
          if (mouseCursorContainer) {
            mouseCursorContainer.addEventListener("touchmove", onDocumentTouchMove, false);
          }

        });
        fabricCanvas.current.on('selection:updated', function(event: any) {
          if (event.selected && event.selected[0] && event.selected[0].name) {
            props.onSelectObjectItem(event.selected[0].name);
          }
          const mouseCursorContainer = document.getElementById('configurator-container');
          if (mouseCursorContainer) {
            mouseCursorContainer.addEventListener("touchmove", onDocumentTouchMove, false);
          }
        });
        fabricCanvas.current.on('before:selection:cleared', function(event: any) {
          const mouseCursorContainer = document.getElementById('configurator-container');
          if (mouseCursorContainer) {
            mouseCursorContainer.removeEventListener("touchmove", onDocumentTouchMove, false);
          }
        });
        fabricCanvas.current.on('selection:cleared', function(event: any) {
          const mouseCursorContainer = document.getElementById('configurator-container');
          if (mouseCursorContainer) {
            mouseCursorContainer.removeEventListener("touchmove", onDocumentTouchMove, false);
          }
        });

        fabricCanvas.current.on('object:moving', function(e) {
          if (!fabricCanvas.current) {
            return;
          }

          const activeObject = e.target;
          if (!activeObject) {
            return;
          }

          if (!viewportTransform.current) {
            return;
          }

          if (viewportTransform.current.length === 0) {
            return;
          }

          const canvas = fabricCanvas.current as any;
          let canvasObjects = fabricCanvas.current.getObjects(),
            activeObjectCenter = activeObject.getCenterPoint(),
            activeObjectLeft = activeObjectCenter.x,
            activeObjectTop = activeObjectCenter.y,
            activeObjectBoundingRect = activeObject.getBoundingRect(),
            activeObjectHeight = activeObjectBoundingRect.height / viewportTransform.current[3],
            activeObjectWidth = activeObjectBoundingRect.width / viewportTransform.current[0],
            transform = canvas._currentTransform;
          if (!transform) return;

          if (designSvgPatternObject.current._objects.find((item: any) => item.id && item.id.includes('placket'))
            && designSvgPatternObject.current._objects.find((item: any) => item.id && item.id.includes('collar_under') && (activeObjectTop >= 40 && activeObjectTop <= 120))
          ) {
            activeObject.flipX = true;
          } else {
            activeObject.flipX = false;
          }

          const mousePosition = getMouseUvPosition();
          if (!mousePosition) {
            return;
          }

          let activeMousePosition = '';
          const onlyMainArea = designSvgPatternObject.current._objects.slice().filter((obj: any) => obj.id.includes('main'));
          const activeObjTop = activeObject.aCoords ? activeObject.aCoords.tl.y : 0;
          const activeObjWidth = activeObject.aCoords ? activeObject.aCoords.tr.x - activeObject.aCoords.tl.x : 0;
          const activeObjLeft = activeObject.aCoords ? activeObject.aCoords.tl.x : 0;
          const activeObjHeight = activeObject.aCoords ? activeObject.aCoords.bl.y - activeObject.aCoords.tl.y : 0;
          const foundedIntersect = onlyMainArea.find((sectionObj: any) => {
            const isOverflowX = ((activeObjWidth + activeObjLeft) > (sectionObj.pathOffset.x + (props.isMobile ? sectionObj.width / 4 : sectionObj.width) / 2)) || ((activeObjWidth + activeObjLeft) < (sectionObj.pathOffset.x - (props.isMobile ? sectionObj.width / 4 : sectionObj.width) / 2));
            const isOverflowY = ((activeObjHeight + activeObjTop) > (sectionObj.pathOffset.y + (props.isMobile ? sectionObj.height / 4 : sectionObj.height) / 2)) || ((activeObjHeight + activeObjTop) < (sectionObj.pathOffset.y - (props.isMobile ? sectionObj.height / 4 : sectionObj.height) / 2));
            return !isOverflowX && !isOverflowY;
          });
          if (foundedIntersect) {
            activeMousePosition = foundedIntersect.id;
          }

          let fabricFrontObjCenterX = 0;
          let fabricBackObjCenterX = 0;
          let fabricLeftObjCenterX = 0;
          let fabricRightObjCenterX = 0;
          let fabricFrontObjCenterY = 0;
          let fabricBackObjCenterY = 0;
          let fabricLeftObjCenterY = 0;
          let fabricRightObjCenterY = 0;

          if (props.modelStyle === ModelStyle.Short) {
            fabricLeftObjCenterX = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
            fabricRightObjCenterX = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

            fabricLeftObjCenterY = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
            fabricRightObjCenterY = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
          } else {
            if (designSvgPatternObjectsBounding.current.front_main && designSvgPatternObjectsBounding.current.left_main) {
              fabricFrontObjCenterX = Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
              fabricBackObjCenterX = Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
              fabricLeftObjCenterX = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
              fabricRightObjCenterX = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

              fabricFrontObjCenterY = Math.round(designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
              fabricBackObjCenterY = Math.round(designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
              fabricLeftObjCenterY = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
              fabricRightObjCenterY = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
            } else if (designSvgPatternObjectsBounding.current.front_main) {
              fabricLeftObjCenterX = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
              fabricRightObjCenterX = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

              fabricLeftObjCenterY = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
              fabricRightObjCenterY = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
            } else {
              fabricLeftObjCenterX = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
              fabricRightObjCenterX = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.x);

              fabricLeftObjCenterY = Math.round(designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
              fabricRightObjCenterY = Math.round(designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
            }
          }

          // ---------- Center Alignment Guideline Handler ---------------
          let widthCenter = 0;
          let heightCenter = 0;

          if (ModelStyle.Shirt === props.modelStyle) {
            if (activeMousePosition === 'front_main') {
              isInVerticalCenter.current = Math.round(activeObjectCenter.x) in frontWidthCenterMap.current;
              isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in frontHeightCenterMap.current;
              widthCenter = fabricFrontObjCenterX;
              heightCenter = fabricFrontObjCenterY;

              const boundingTop = fabricFrontObjCenterY - ((props.isMobile ? designSvgPatternObjectsBounding.current.front_main.height / 4 : designSvgPatternObjectsBounding.current.front_main.height) / 2);
              centerVerticalLine.current = {
                startY: boundingTop,
                widthCenter,
                height: (props.isMobile ? designSvgPatternObjectsBounding.current.front_main.height / 4 : designSvgPatternObjectsBounding.current.front_main.height),
                viewportTransform: viewportTransform.current,
              };
              const boundingLeft = fabricFrontObjCenterX - ((props.isMobile ? designSvgPatternObjectsBounding.current.front_main.width / 4 : designSvgPatternObjectsBounding.current.front_main.width) / 2);
              centerHorizontalLine.current = {
                startX: boundingLeft,
                heightCenter,
                width: (props.isMobile ? designSvgPatternObjectsBounding.current.front_main.width / 4 : designSvgPatternObjectsBounding.current.front_main.width),
                viewportTransform: viewportTransform.current,
              };
            } else if (activeMousePosition === 'back_main') {
              isInVerticalCenter.current = Math.round(activeObjectCenter.x) in backWidthCenterMap.current;
              isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in backHeightCenterMap.current;
              widthCenter = fabricBackObjCenterX;
              heightCenter = fabricBackObjCenterY;

              const boundingTop = fabricBackObjCenterY - ((props.isMobile ? designSvgPatternObjectsBounding.current.back_main.height / 4 : designSvgPatternObjectsBounding.current.back_main.height) / 2);
              centerVerticalLine.current = {
                startY: boundingTop,
                widthCenter,
                height: (props.isMobile ? designSvgPatternObjectsBounding.current.back_main.height / 4 : designSvgPatternObjectsBounding.current.back_main.height),
                viewportTransform: viewportTransform.current,
              };
              const boundingLeft = fabricBackObjCenterX - ((props.isMobile ? designSvgPatternObjectsBounding.current.back_main.width / 4 : designSvgPatternObjectsBounding.current.back_main.width) / 2);
              centerHorizontalLine.current = {
                startX: boundingLeft,
                heightCenter,
                width: (props.isMobile ? designSvgPatternObjectsBounding.current.back_main.width / 4 : designSvgPatternObjectsBounding.current.back_main.width),
                viewportTransform: viewportTransform.current,
              };
            } else if (activeMousePosition === 'left_main') {
              isInVerticalCenter.current = Math.round(activeObjectCenter.x) in leftWidthCenterMap.current;
              isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in leftHeightCenterMap.current;
              widthCenter = fabricLeftObjCenterX;
              heightCenter = fabricLeftObjCenterY;

              const boundingTop = fabricLeftObjCenterY - ((props.isMobile ? designSvgPatternObjectsBounding.current.left_main.height / 4 : designSvgPatternObjectsBounding.current.left_main.height) / 2);
              centerVerticalLine.current = {
                startY: boundingTop,
                widthCenter,
                height: (props.isMobile ? designSvgPatternObjectsBounding.current.left_main.height / 4 : designSvgPatternObjectsBounding.current.left_main.height),
                viewportTransform: viewportTransform.current,
              };
              const boundingLeft = fabricLeftObjCenterX - ((props.isMobile ? designSvgPatternObjectsBounding.current.left_main.width / 4 : designSvgPatternObjectsBounding.current.left_main.width) / 2);
              centerHorizontalLine.current = {
                startX: boundingLeft,
                heightCenter,
                width: (props.isMobile ? designSvgPatternObjectsBounding.current.left_main.width / 4 : designSvgPatternObjectsBounding.current.left_main.width),
                viewportTransform: viewportTransform.current,
              };
            } else if (activeMousePosition === 'right_main') {
              isInVerticalCenter.current = Math.round(activeObjectCenter.x) in rightWidthCenterMap.current;
              isInHorizontalCenter.current = Math.round(activeObjectCenter.y) in rightHeightCenterMap.current;
              widthCenter = fabricRightObjCenterX;
              heightCenter = fabricRightObjCenterY;

                const boundingTop = fabricRightObjCenterY - ((props.isMobile ? designSvgPatternObjectsBounding.current.right_main.height / 4 : designSvgPatternObjectsBounding.current.right_main.height) / 2);
                centerVerticalLine.current = {
                  startY: boundingTop,
                  widthCenter,
                  height: (props.isMobile ? designSvgPatternObjectsBounding.current.right_main.height / 4 : designSvgPatternObjectsBounding.current.right_main.height),
                  viewportTransform: viewportTransform.current,
                };
                const boundingLeft = fabricRightObjCenterX - ((props.isMobile ? designSvgPatternObjectsBounding.current.right_main.width / 4 : designSvgPatternObjectsBounding.current.right_main.width) / 2);
                centerHorizontalLine.current = {
                  startX: boundingLeft,
                  heightCenter,
                  width: (props.isMobile ? designSvgPatternObjectsBounding.current.right_main.width / 4 : designSvgPatternObjectsBounding.current.right_main.width),
                  viewportTransform: viewportTransform.current,
                };
              }
          }

          if (isInVerticalCenter.current || isInHorizontalCenter.current) {
            activeObject.setPositionByOrigin(
              new fabric.Point(
                isInVerticalCenter.current ? widthCenter : activeObjectLeft,
                isInHorizontalCenter.current ? heightCenter : activeObjectTop
              ),
              'center',
              'center'
            );
          }
          // ---------- End of Center Alignment Guideline Handler ---------------


          // ---------- Objects Alignment Guideline Handler ---------------
          /**
           * It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
           * but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move
           * */
          // const onlyAddedObjectsForObjectAlignments = canvasObjects.slice().filter((item: any) => item.id ? item.id.toString() === 'logo_front' : false);
          const onlyAddedObjectsForObjectAlignments = canvasObjects.slice().filter((item: any) => !item.id || item.id.toString() === 'logo_front');

          for (let i = onlyAddedObjectsForObjectAlignments.length; i--; ) {
            if (onlyAddedObjectsForObjectAlignments[i] === activeObject) continue;

            if (activeMousePosition === 'back_main') {
              const fabricFrontObj = designSvgPatternObjectsBounding.current.front_main;
              const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
              const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
              if (fabricFrontObj) {
                const frontSvgLayerPathOffset = fabricFrontObj.pathOffset;
                const boundingLeft = frontSvgLayerPathOffset.x - ((props.isMobile ? fabricFrontObj.width / 4 : fabricFrontObj.width) / 2);
                const boundingRight = frontSvgLayerPathOffset.x + ((props.isMobile ? fabricFrontObj.width / 4 : fabricFrontObj.width) / 2);
                const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;
                if (otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight) {
                  continue;
                }
              }
              if (fabricLeftObj) {
                const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
                const boundingTop = leftSvgLayerPathOffset.y - ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
                const boundingBottom = leftSvgLayerPathOffset.y + ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
                const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
                if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
                  continue;
                }
              }
              if (fabricRightObj) {
                const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
                const boundingTop = rightSvgLayerPathOffset.y - ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
                const boundingBottom = rightSvgLayerPathOffset.y + ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
                const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
                if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
                  continue;
                }
              }
            }

            if (activeMousePosition === 'front_main') {
              const fabricBackObj = designSvgPatternObjectsBounding.current.back_main;
              const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
              const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
              if (fabricBackObj) {
                const backSvgLayerPathOffset = fabricBackObj.pathOffset;
                const boundingLeft = backSvgLayerPathOffset.x - ((props.isMobile ? fabricBackObj.width / 4 : fabricBackObj.width) / 2);
                const boundingRight = backSvgLayerPathOffset.x + ((props.isMobile ? fabricBackObj.width / 4 : fabricBackObj.width) / 2);
                const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;
                if (otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight) {
                  continue;
                }
              }
              if (fabricLeftObj) {
                const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
                const boundingTop = leftSvgLayerPathOffset.y - ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
                const boundingBottom = leftSvgLayerPathOffset.y + ((props.isMobile ? fabricLeftObj.height / 4 : fabricLeftObj.height) / 2);
                const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
                if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
                  continue;
                }
              }
              if (fabricRightObj) {
                const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
                const boundingTop = rightSvgLayerPathOffset.y - ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
                const boundingBottom = rightSvgLayerPathOffset.y + ((props.isMobile ? fabricRightObj.height / 4 : fabricRightObj.height) / 2);
                const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;
                if (otherObjectCoordsTop > boundingTop && otherObjectCoordsBottom < boundingBottom) {
                  continue;
                }
              }
            }

            if (props.modelStyle === ModelStyle.Short && activeMousePosition === 'right_main') {
              // const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
              // if (fabricLeftObj) {
              //   const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
              //   const boundingLeft = leftSvgLayerPathOffset.x - (fabricLeftObj.width / 2);
              //   const boundingRight = leftSvgLayerPathOffset.x + (fabricLeftObj.width / 2);
              //   const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
              //   const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;
              //   if (otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight) {
              //     continue;
              //   }
              // }
            } else if (activeMousePosition === 'right_main') {
              const fabricLeftObj = designSvgPatternObjectsBounding.current.left_main;
              const fabricFrontObj = designSvgPatternObjectsBounding.current.front_main;
              const fabricBackObj = designSvgPatternObjectsBounding.current.back_main;
              if (fabricLeftObj) {
                const leftSvgLayerPathOffset = fabricLeftObj.pathOffset;
                const boundingLeft = leftSvgLayerPathOffset.x - ((props.isMobile ? fabricLeftObj.width / 4 : fabricLeftObj.width) / 2);
                const boundingRight = leftSvgLayerPathOffset.x + ((props.isMobile ? fabricLeftObj.width / 4 : fabricLeftObj.width) / 2);
                const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;

                const frontSvgLayerPathOffset = fabricFrontObj.pathOffset;
                const frontBoundingTop = frontSvgLayerPathOffset.y - ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);
                const frontBoundingBottom = frontSvgLayerPathOffset.y + ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);

                const backSvgLayerPathOffset = fabricBackObj.pathOffset;
                const backBoundingTop = backSvgLayerPathOffset.y - ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);
                const backBoundingBottom = backSvgLayerPathOffset.y + ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);

                const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;

                if ((otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight)
                  && (otherObjectCoordsTop > frontBoundingTop && otherObjectCoordsBottom < frontBoundingBottom)
                  && (otherObjectCoordsTop > backBoundingTop && otherObjectCoordsBottom < backBoundingBottom)) {
                  continue;
                }
              }
            }

            if (props.modelStyle === ModelStyle.Short && activeMousePosition === 'left_main') {
              // const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
              // if (fabricRightObj) {
              //   const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
              //   const boundingLeft = rightSvgLayerPathOffset.x - (fabricRightObj.width / 2);
              //   const boundingRight = rightSvgLayerPathOffset.x + (fabricRightObj.width / 2);
              //   const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
              //   const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;
              //   if (otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight) {
              //     continue;
              //   }
              // }
            } else if (activeMousePosition === 'left_main') {
              const fabricRightObj = designSvgPatternObjectsBounding.current.right_main;
              const fabricFrontObj = designSvgPatternObjectsBounding.current.front_main;
              const fabricBackObj = designSvgPatternObjectsBounding.current.back_main;
              if (fabricRightObj) {
                const rightSvgLayerPathOffset = fabricRightObj.pathOffset;
                const boundingLeft = rightSvgLayerPathOffset.x - ((props.isMobile ? fabricRightObj.width / 4 : fabricRightObj.width) / 2);
                const boundingRight = rightSvgLayerPathOffset.x + ((props.isMobile ? fabricRightObj.width / 4 : fabricRightObj.width) / 2);
                const otherObjectCoordsLeft = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsRight = onlyAddedObjectsForObjectAlignments[i].aCoords?.tr.x || 0;

                const frontSvgLayerPathOffset = fabricFrontObj.pathOffset;
                const frontBoundingTop = frontSvgLayerPathOffset.y - ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);
                const frontBoundingBottom = frontSvgLayerPathOffset.y + ((props.isMobile ? fabricFrontObj.height / 4 : fabricFrontObj.height) / 2);

                const backSvgLayerPathOffset = fabricBackObj.pathOffset;
                const backBoundingTop = backSvgLayerPathOffset.y - ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);
                const backBoundingBottom = backSvgLayerPathOffset.y + ((props.isMobile ? fabricBackObj.height / 4 : fabricBackObj.height) / 2);

                const otherObjectCoordsTop = onlyAddedObjectsForObjectAlignments[i].aCoords?.tl.x || 0;
                const otherObjectCoordsBottom = onlyAddedObjectsForObjectAlignments[i].aCoords?.bl.y || 0;

                if ((otherObjectCoordsLeft > boundingLeft && otherObjectCoordsRight < boundingRight)
                  && (otherObjectCoordsTop > frontBoundingTop && otherObjectCoordsBottom < frontBoundingBottom)
                  && (otherObjectCoordsTop > backBoundingTop && otherObjectCoordsBottom < backBoundingBottom)) {
                  continue;
                }
              }
            }

            let objectCenter = onlyAddedObjectsForObjectAlignments[i].getCenterPoint(),
              objectLeft = objectCenter.x,
              objectTop = objectCenter.y,
              objectBoundingRect = onlyAddedObjectsForObjectAlignments[i].getBoundingRect(),
              objectHeight = objectBoundingRect.height / viewportTransform.current[3],
              objectWidth = objectBoundingRect.width / viewportTransform.current[0];

            if (verticalLines.current) {
              // snap by the horizontal center line
              if (isInRange(objectLeft, activeObjectLeft)) {
                verticalInTheRange.current = true;
                verticalLines.current.push({
                  x: objectLeft,
                  y1: (objectTop < activeObjectTop)
                    ? (objectTop - objectHeight / 2 - aligningLineOffset)
                    : (objectTop + objectHeight / 2 + aligningLineOffset),
                  y2: (activeObjectTop > objectTop)
                    ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
                    : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
                });
                activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), 'center', 'center');
              }

              // snap by the left edge
              if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
                verticalInTheRange.current = true;
                verticalLines.current.push({
                  x: objectLeft - objectWidth / 2,
                  y1: (objectTop < activeObjectTop)
                    ? (objectTop - objectHeight / 2 - aligningLineOffset)
                    : (objectTop + objectHeight / 2 + aligningLineOffset),
                  y2: (activeObjectTop > objectTop)
                    ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
                    : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
                });
                activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), 'center', 'center');
              }

              // snap by the right edge
              if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
                verticalInTheRange.current = true;
                verticalLines.current.push({
                  x: objectLeft + objectWidth / 2,
                  y1: (objectTop < activeObjectTop)
                    ? (objectTop - objectHeight / 2 - aligningLineOffset)
                    : (objectTop + objectHeight / 2 + aligningLineOffset),
                  y2: (activeObjectTop > objectTop)
                    ? (activeObjectTop + activeObjectHeight / 2 + aligningLineOffset)
                    : (activeObjectTop - activeObjectHeight / 2 - aligningLineOffset)
                });
                activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), 'center', 'center');
              }
            }

            if (horizontalLines.current) {
                // snap by the vertical center line
              if (isInRange(objectTop, activeObjectTop)) {
                horizontalInTheRange.current = true;
                horizontalLines.current.push({
                  y: objectTop,
                  x1: (objectLeft < activeObjectLeft)
                    ? (objectLeft - objectWidth / 2 - aligningLineOffset)
                    : (objectLeft + objectWidth / 2 + aligningLineOffset),
                  x2: (activeObjectLeft > objectLeft)
                    ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
                    : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
                });
                activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), 'center', 'center');
              }

              // snap by the top edge
              if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
                horizontalInTheRange.current = true;
                horizontalLines.current.push({
                  y: objectTop - objectHeight / 2,
                  x1: (objectLeft < activeObjectLeft)
                    ? (objectLeft - objectWidth / 2 - aligningLineOffset)
                    : (objectLeft + objectWidth / 2 + aligningLineOffset),
                  x2: (activeObjectLeft > objectLeft)
                    ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
                    : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
                });
                activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), 'center', 'center');
              }

              // snap by the bottom edge
              if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
                horizontalInTheRange.current = true;
                horizontalLines.current.push({
                  y: objectTop + objectHeight / 2,
                  x1: (objectLeft < activeObjectLeft)
                    ? (objectLeft - objectWidth / 2 - aligningLineOffset)
                    : (objectLeft + objectWidth / 2 + aligningLineOffset),
                  x2: (activeObjectLeft > objectLeft)
                    ? (activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset)
                    : (activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset)
                });
                activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), 'center', 'center');
              }
            }
          }
          if (!horizontalInTheRange.current && horizontalLines.current) {
            horizontalLines.current.length = 0;
          }
          if (!verticalInTheRange.current && verticalLines.current) {
            verticalLines.current.length = 0;
          }
          // ---------- End of Objects Alignment Guideline Handler ---------------
        });

        fabricCanvas.current.on('before:render', function() {
          if (!fabricCanvas.current) {
            return;
          }
          const canvas = fabricCanvas.current as any;
          canvas.clearContext(canvas.contextTop);
          updateCanvasTextureMap();
        });

        fabricCanvas.current.on("after:render", function(fabricObj) {
          if (isInVerticalCenter.current) {
            showVerticalCenterLine(centerVerticalLine.current);
          }
          if (isInHorizontalCenter.current) {
            showHorizontalCenterLine(centerHorizontalLine.current);
          }

          if (verticalLines.current) {
            for (let i = verticalLines.current.length; i--; ) {
              drawVerticalLine(verticalLines.current[i]);
            }
            verticalLines.current.length = 0;
          }
          if (horizontalLines.current) {
            for (let i = horizontalLines.current.length; i--; ) {
              drawHorizontalLine(horizontalLines.current[i]);
            }
            horizontalLines.current.length = 0;
          }
        });
        // --------------- End of Fabric Event Listner ---------------------
      }
    );
  }

  function updateSvgDesign(mainUvObject: fabric.Object) {
    if (!fabricCanvas.current || !fabricInsideCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
      return;
    }
    fabricCanvas.current.remove(mainUvObject);
    fabricCanvas.current.add(designSvgPatternObject.current);
    fabricCanvas.current.sendToBack(designSvgPatternObject.current);

    fabricInsideCanvas.current.remove(designSvgPatternInsideObject.current);
    fabricInsideCanvas.current.add(designSvgPatternInsideObject.current);
    fabricInsideCanvas.current.sendToBack(designSvgPatternInsideObject.current);

    jerseyNormalMap.mapping = EquirectangularReflectionMapping;
    jerseyNormalMap.flipY = false;
    jerseyNormalMap.anisotropy = gl.capabilities.getMaxAnisotropy();
    jerseyNormalMap.minFilter = LinearFilter;
    // jerseyNormalMap.generateMipmaps = false;
    jerseyNormalMap.needsUpdate = true;

    nodes['Scene'].position.set(0, -0.1, 0);
    nodes['Scene'].scale.set(0.055, 0.055, 0.055);

    nodes['outside'].material.normalMap = jerseyNormalMap;
    nodes['inside'].material.normalMap = jerseyNormalMap;
    updateCanvasTextureMap();

    setTimeout(() => {
      // fabricCanvas.current?.renderAll();
      props.onSetLoading(false);
    }, 2000);
  }

  const setNewSvgDesignToFabric = (forceRerender?: boolean) => {
    if (!fabricCanvas.current || !fabricInsideCanvas.current || selectedDesignSvgId === undefined) {
      return;
    }
    if (!props.designSvg) {
      return;
    }

    const mainUvObject = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv');
    if (!mainUvObject) {
      return;
    }

    if (props.designSvg === selectedDesignSvgId && !forceRerender) {
      return;
    }

    props.onSetLoading(true);
    fabricCanvas.current.remove(mainUvObject);
    fabricInsideCanvas.current.remove(mainUvObject);
    const fileReader = new FileReader();
    fetch(props.designSvg).then((res) => {
      res.blob().then((blobRes) => {
        fileReader.readAsText(blobRes);
        fileReader.onload = () => {
          const svgFileTextResult = fileReader.result as string;
          if (!svgFileTextResult) {
            return;
          }
          setLoadSvgDesignFile(svgFileTextResult);
        };
      });
    });
  };

  useEffect(() => {
    const mouseCursorContainer = document.getElementById('configurator-container');
    if (!mouseCursorContainer) {
      return;
    }
    if (props.isMobile) {
      mouseCursorContainer.addEventListener("touchstart", onDocumentTouchDown, false);
      mouseCursorContainer.addEventListener('touchend', onDocumentTouchUp);
      mouseCursorContainer.removeEventListener('mousedown', onDocumentMouseDown);
      mouseCursorContainer.removeEventListener('mouseup', onDocumentMouseUp);
    } else {
      mouseCursorContainer.addEventListener('mousedown', onDocumentMouseDown);
      mouseCursorContainer.addEventListener('mouseup', onDocumentMouseUp);
    }

    return () => {
      mouseCursorContainer.removeEventListener('mousedown', onDocumentMouseDown);
      mouseCursorContainer.removeEventListener('mouseup', onDocumentMouseUp);
      mouseCursorContainer.removeEventListener("touchstart", onDocumentTouchDown, false);
      mouseCursorContainer.removeEventListener('touchend', onDocumentTouchUp);
      mouseCursorContainer.removeEventListener("touchmove", onDocumentTouchMove, false);
    };
    // eslint-disable-next-line
  }, [props.isMobile]);

  useEffect(() => {
    init();
    nodes['outside'].geometry.computeBoundsTree();
    nodes['inside'].geometry.computeBoundsTree();
    // eslint-disable-next-line
  }, [nodes]);


  useEffect(() => {
    const mainUvObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'mainUv');
    if (mainUvObject) {
      setNewSvgDesignToFabric();
    }
    // eslint-disable-next-line
  }, [props.designSvg]);

  useEffect(() => {
    const mainUvObject = fabricCanvas.current?._objects.find((obj: any) => obj.id === 'mainUv');
    if (mainUvObject) {
      setNewSvgDesignToFabric(true);
    }
    // eslint-disable-next-line
  }, [props.activateDesignOnBack]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    } else {
      if (nodes['outside'] && nodes['outside'].material && nodes['outside'].material.map) {
        nodes['outside'].material.map.dispose();
      }
      setSvgDesignToFabric();
    }
    // eslint-disable-next-line
  }, [props.currentCuttingFormCode]);

  useEffect(() => {
    setSvgDesignToFabric();
    changeColor(props.selectedJerseySectionColor);
    // eslint-disable-next-line
  }, [props.selectedJerseySectionColor]);

  useEffect(() => {
    changePattern(props.selectedJerseySectionPattern);
    // eslint-disable-next-line
  }, [props.selectedJerseySectionPattern]);

  useEffect(() => {
    if (props.colorLogoHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewJakoLogoColor(res[0]);
      });
    }
    // eslint-disable-next-line
  }, [props.colorLogoHex, jakoLogoPositionUv])

  useEffect(() => {
    if (props.colorLogoHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewJakoLogoColor(res[0]);
      });
    }
    // eslint-disable-next-line
  }, [props.selectedJerseySectionColor, jakoLogoPositionUv])

  useEffect(() => {
    if (props.colorDotsLeftHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewDotsLogoColor(res[1], 'left');
      });
    }
    // eslint-disable-next-line
  }, [props.colorDotsLeftHex, jakoLogoPositionUv])

  useEffect(() => {
    if (props.colorDotsRightHex) {
      getJakoLogoColorSvg().then((res) => {
        setNewDotsLogoColor(res[2], 'right');
      });
    }
    // eslint-disable-next-line
  }, [props.colorDotsRightHex, jakoLogoPositionUv])

  useEffect(() => {
    if (!fabricCanvas.current) {
      return;
    }
    if (props.isAddingState === null) {
      return;
    }
    if (props.isAddingState === FunctionTab.Teamname) {
      addText({
        textString: props.text,
        addingState: props.isAddingState,
      });
    } else if (props.isAddingState === FunctionTab.Number) {
      addText({
        textString: props.text,
        addingState: props.isAddingState,
      });
    } else if (props.isAddingState === FunctionTab.Logo && props.logo) {
      addLogo({
        imageObj: props.logo,
      });
    } else if (props.isAddingState !== FunctionTab.ResetStart) {
      addText({
        textString: props.text,
        addingState: props.isAddingState,
      });
    }
    // eslint-disable-next-line
  }, [props.isAddingState]);

  function updateCanvasTextureMap() {
    if (!fabricCanvas.current) {
      return;
    }
    if (!canvasRef.current || !canvasInsideRef.current) {
      return;
    }

    if (nodes['outside'] && nodes['outside'].material && nodes['outside'].material.map) {
      nodes['outside'].material.map.needsUpdate = true;
      nodes['inside'].material.map.needsUpdate = true;
    }
  }

  function init() {
    if (fabricCanvas.current) {
      fabricCanvas.current.clear();
      fabricCanvas.current.loadFromJSON({}, () => {});
    }
    props.onSetLoadingInitial(true);
    const fabricSetting = {
      cornerSize: props.isMobile ? 11 : 35,
      borderColor: "#2A2A2D",
      borderScaleFactor: props.isMobile ? 1.5 / 4 : 1.5,
      borderShadowColor: "#00000055",
      borderShadowBlur: 4,
      borderDashArray: [props.isMobile ? 10 / 4 : 10, props.isMobile ? 10 / 4 : 10],
      lockSkewingX: true,
      lockSkewingY: true,
      lockScalingFlip: true,
      padding: 0,
    } as any;
    fabric.Object.prototype.set(fabricSetting);
    const resizeIcon = "data:image/svg+xml,%3Csvg width='32px' height='32px' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fill='%23000000' transform='rotate(90)'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Ctitle%3Eionicons-v5-c%3C/title%3E%3Cpolyline points='304 96 416 96 416 208' style='fill:none;stroke:%23000000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:32px'%3E%3C/polyline%3E%3Cline x1='405.77' y1='106.2' x2='111.98' y2='400.02' style='fill:none;stroke:%23000000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:32px'%3E%3C/line%3E%3Cpolyline points='208 416 96 416 96 304' style='fill:none;stroke:%23000000;stroke-linecap:square;stroke-miterlimit:10;stroke-width:32px'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E";
    const resizeImg = document.createElement('img');
    resizeImg.src = resizeIcon;

    const rotateIcon = "data:image/svg+xml,%3Csvg height='32px' width='32px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 33.317 28' enableBackground='new 0 0 33.317 28' xml:space='preserve'%3E%3Cpath d='M16.659,24c-5.078,0-9.213-3.987-9.475-9h2.975l-4.5-5l-4.5,5h3.025c0.264,6.671,5.74,12,12.475,12c3.197,0,6.104-1.21,8.315-3.185l-2.122-2.122C21.188,23.127,19.027,24,16.659,24z'%3E%3C/path%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.133,14c-0.265-6.669-5.74-12-12.475-12c-3.197,0-6.104,1.21-8.315,3.185l2.122,2.122C12.129,5.873,14.29,5,16.659,5c5.077,0,9.213,3.987,9.475,9h-2.975l4.5,5l4.5-5H29.133z'%3E%3C/path%3E%3C/svg%3E";
    const rotateImg = document.createElement('img');
    rotateImg.src = rotateIcon;

    const deleteIcon = "data:image/svg+xml,%3Csvg height='32px' width='32px' fill='%23000000' xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 100' x='0px' y='0px'%3E%3Ctitle%3E06%3C/title%3E%3Cg data-name='Group'%3E%3Cpath data-name='Compound Path' d='M81.5,24.6H62.7V20.8a7,7,0,0,0-7-7H44.3a7,7,0,0,0-7,7v3.8H18.5a3,3,0,0,0,0,6h6.1V75.2a11,11,0,0,0,11,11H64.4a11,11,0,0,0,11-11V30.6h6.1a3,3,0,0,0,0-6ZM43.3,20.8a1,1,0,0,1,1-1H55.7a1,1,0,0,1,1,1v3.8H43.3Zm26,54.5a5,5,0,0,1-5,5H35.6a5,5,0,0,1-5-5V30.6H69.4Z'%3E%3C/path%3E%3Cpath data-name='Path' d='M57.5,73a3,3,0,0,0,3-3V40.1a3,3,0,1,0-6,0V70A3,3,0,0,0,57.5,73Z'%3E%3C/path%3E%3Cpath data-name='Path' d='M42.5,73a3,3,0,0,0,3-3V40.1a3,3,0,1,0-6,0V70A3,3,0,0,0,42.5,73Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
    const deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;

    function renderDeleteIconControl(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.strokeStyle = "#000000";
      ctx.stroke();
      ctx.shadowColor = "#00000055";
      ctx.shadowBlur = 4;
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(deleteImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function renderRotateIconControl(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.strokeStyle = "#000000";
      ctx.stroke();
      ctx.shadowColor = "#00000055";
      ctx.shadowBlur = 2;
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(rotateImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function renderResizeIconControl(ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) {
      const size = fabricSetting.cornerSize; // this.cornerSize
      ctx.save();
      ctx.beginPath();
      ctx.arc(left, top, (size / 2) + 3, 0, 2 * Math.PI, false);
      ctx.strokeStyle = "#000000";
      ctx.stroke();
      ctx.shadowColor = "#00000055";
      ctx.shadowBlur = 2;
      ctx.fillStyle = "#ffffff";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.restore();

      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
      ctx.drawImage(resizeImg, -size/2, -size/2, size, size);
      ctx.restore();
    }

    function onClickDeleteIconControl(eventData: MouseEvent, transform: Transform) {
      const target = transform.target as any;
      props.onDeleteFromControl(target.name);

      fabricCanvas.current?.remove(target);
      fabricCanvas.current?.requestRenderAll();

      if (target.name) {
        if (target.fontSize) {
          props.onObjectDeleted('text', target.name);
        } else {
          props.onObjectDeleted('logo', target.name);
        }
      }
      return true;
    }

    // fabric.
    const fb = fabric as any;

    // fabric.Object.prototype.controls.tl.offsetX = -1;
    // fabric.Object.prototype.controls.tl.offsetY = -1;
    // fabric.Object.prototype.controls.tr.offsetX = 1;
    // fabric.Object.prototype.controls.tr.offsetY = -1;
    // fabric.Object.prototype.controls.bl.offsetX = -1;
    // fabric.Object.prototype.controls.bl.offsetY = 1;
    // fabric.Object.prototype.controls.br.offsetX = 1;
    // fabric.Object.prototype.controls.br.offsetY = 1;

    fabric.Object.prototype.setControlsVisibility({
      tl: false, //top-left
      mt: false, // middle-top
      tr: false, //top-right
      ml: false, //middle-left
      mr: false, //middle-right
      bl: false, // bottom-left
      mb: false, //middle-bottom
      br: true, //bottom-right
      mtr: true // rotate icon
    });

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: props.isMobile ? 0.5 : 0.55,
      y: props.isMobile ? -0.5 : -0.55,
      offsetX: 7,
      offsetY: -7,
      cursorStyle: 'pointer',
      mouseUpHandler: onClickDeleteIconControl,
      render: renderDeleteIconControl,
    });

    const brControlAction = fabric.Object.prototype.controls.br.actionHandler;

    fabric.Object.prototype.controls.br = new fabric.Control({
      x: props.isMobile ? 0.5 : 0.55,
      y: props.isMobile ? 0.5 : 0.55,
      offsetX: 7,
      offsetY: 7,
      actionName: 'scale',
      cursorStyle: 'scale',
      render: renderResizeIconControl,
      withConnection: true,
      actionHandler: brControlAction,
    });

    fabric.Object.prototype.controls.mtr = new fabric.Control({
      x: props.isMobile ? -0.5 : -0.55,
      y: props.isMobile ? -0.5 : -0.55,
      offsetX: -7,
      offsetY: -7,
      actionName: 'rotate',
      cursorStyle: 'pointer',
      //mouseUpHandler: rotateObject,
      render: renderRotateIconControl,
      // cornerSize: 24,
      withConnection: true,
      actionHandler: fb.controlsUtils.rotationWithSnapping,
    });

    fabric.Object.prototype.drawBorders = function(ctx, styleOverride) {
      const vm = this as any;
      styleOverride = styleOverride || {};
      var wh = vm._calculateCurrentDimensions(),
          strokeWidth = vm.borderScaleFactor,
          width = wh.x + strokeWidth,
          height = wh.y + strokeWidth,
          hasControls = typeof styleOverride.hasControls !== 'undefined' ?
            styleOverride.hasControls : vm.hasControls,
          shouldStroke = false;

      ctx.save();
      ctx.strokeStyle = styleOverride.borderColor || vm.borderColor;
      ctx.shadowColor = styleOverride.shadowColor || vm.borderShadowColor;
      ctx.shadowBlur = styleOverride.shadowBlur || vm.borderShadowBlur;
      vm._setLineDash(ctx, styleOverride.borderDashArray || vm.borderDashArray, null);

      ctx.strokeRect(
        -width / 2,
        -height / 2,
        width,
        height
      );

      if (hasControls) {
        ctx.beginPath();
        vm.forEachControl(function(control: any, key: any, fabricObject: any) {
          // in this moment, the ctx is centered on the object.
          // width and height of the above function are the size of the bbox.
          if (control.withConnection && control.getVisibility(fabricObject, key)) {
            // reset movement for each control
            shouldStroke = true;
            ctx.moveTo(control.x * width, control.y * height);
            ctx.lineTo(
              control.x * width + control.offsetX,
              control.y * height + control.offsetY
            );
          }
        });
        if (shouldStroke) {
          ctx.stroke();
        }
      }
      ctx.restore();
      return vm;
    };
    canvasRef.current = document.createElement('canvas');
    canvasInsideRef.current = document.createElement('canvas');
    fabricCanvas.current = new fabric.Canvas(canvasRef.current, {
      preserveObjectStacking: true,
      allowTouchScrolling: false,
      renderOnAddRemove: false,
    });
    fabricCanvas.current.setWidth(SVG_SIZE);
    fabricCanvas.current.setHeight(SVG_SIZE);
    fabricCanvas.current.selection = false;

    fabricInsideCanvas.current = new fabric.Canvas(canvasInsideRef.current, {
      preserveObjectStacking: true,
      allowTouchScrolling: false,
      renderOnAddRemove: false,
      selection: false
    });
    fabricInsideCanvas.current.setWidth(SVG_SIZE);
    fabricInsideCanvas.current.setHeight(SVG_SIZE);
    fabricInsideCanvas.current.selection = false;

    fabric.Object.prototype.objectCaching = false;
    fabric.Object.prototype.cornerColor = 'blue';
    fabric.Object.prototype.cornerStyle = 'circle';

    fabric.Canvas.prototype.getPointer = function (e: any, ignoreZoom) {
      const vm = this as any;
      if (vm._absolutePointer && !ignoreZoom) {
        return vm._absolutePointer;
      }
      if (vm._pointer && ignoreZoom) {
        return vm._pointer;
      }

    /* BEGIN PATCH CODE */
      var positionOnScene = getMouseUvPosition();
      if (!positionOnScene) {
        return {
          x: 0,
          y: 0
        };
      }
      return {
        x: positionOnScene.x,
        y: positionOnScene.y
      };
    };
  }

  function getJakoLogoColorSvg(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      fetch(jakoLogoPositionUv)
        .then((res) => {
          const fileReader = new FileReader();
          res.blob().then((blobRes) => {
            fileReader.readAsText(blobRes);
            fileReader.onload = () => {
              const svgFileJakoLogoStringResult = fileReader.result as string;
              if (!svgFileJakoLogoStringResult) {
                reject();
                return;
              }
              if (!fabricCanvas.current) {
                reject();
                return;
              }
              const htmlJakoLogo = document.createElement('div');
              htmlJakoLogo.innerHTML = svgFileJakoLogoStringResult.toString();
              const jakoLogoSVG = htmlJakoLogo.children[0];

              jakoLogoSVG.setAttribute('width', SVG_SIZE.toString());
              jakoLogoSVG.setAttribute('height', SVG_SIZE.toString());
              jakoLogoSVG.setAttribute('fill', props.colorLogoHex);
              jakoLogoSVG.setAttribute('stroke', props.selectedJerseySectionColor[JerseySection.Front]);
              jakoLogoSVG.setAttribute('stroke-width', '0.4');

              const jakoLogoSvgNode = jakoLogoSVG.cloneNode(true) as any;
              const adjustedJakoLogoSvgStringHTML = jakoLogoSvgNode.outerHTML;
              const svgLogoColorChanged = adjustedJakoLogoSvgStringHTML;
              if (!jakoDotsUvLeft) {
                resolve([svgLogoColorChanged, '', '']);
                return;
              }
              fetch(jakoDotsUvLeft)
                .then((dotsLeftRes) => {
                  const fileReader = new FileReader();
                  dotsLeftRes.blob().then((blobRes) => {
                    fileReader.readAsText(blobRes);
                    fileReader.onload = () => {
                      const svgFileJakoDotsLeftStringResult = fileReader.result as string;
                      if (!svgFileJakoDotsLeftStringResult) {
                        reject();
                        return;
                      }
                      if (!fabricCanvas.current) {
                        reject();
                        return;
                      }
                      fetch(jakoDotsUvRight)
                        .then((dotsRightRes) => {
                          const fileReader = new FileReader();
                          dotsRightRes.blob().then((blobRes) => {
                            fileReader.readAsText(blobRes);
                            fileReader.onload = () => {
                              const svgFileJakoDotsRightStringResult = fileReader.result as string;
                              if (!svgFileJakoDotsRightStringResult) {
                                reject();
                                return;
                              }
                              if (!fabricCanvas.current) {
                                reject();
                                return;
                              }
                              const htmlDotsLeft = document.createElement('div');
                              htmlDotsLeft.innerHTML = svgFileJakoDotsLeftStringResult.toString();
                              const dotsLeftSVG = htmlDotsLeft.children[0];
                              dotsLeftSVG.setAttribute('width', SVG_SIZE.toString());
                              dotsLeftSVG.setAttribute('height', SVG_SIZE.toString());
                              const dotsLeftSvgNode = dotsLeftSVG.cloneNode(true) as any;
                              const adjustedDotsLeftSvgStringHTML = dotsLeftSvgNode.outerHTML;

                              const htmlDotsRight = document.createElement('div');
                              htmlDotsRight.innerHTML = svgFileJakoDotsRightStringResult.toString();
                              const dotsRightSVG = htmlDotsRight.children[0];
                              dotsRightSVG.setAttribute('width', SVG_SIZE.toString());
                              dotsRightSVG.setAttribute('height', SVG_SIZE.toString());
                              const dotsRightSvgNode = dotsRightSVG.cloneNode(true) as any;
                              const adjustedDotsRightSvgStringHTML = dotsRightSvgNode.outerHTML;

                              const svgDotsColorLeftChanged = adjustedDotsLeftSvgStringHTML.replace('0 0 2048 2048"', `0 0 2048 2048" fill="${props.colorDotsLeftHex}" `);
                              const svgDotsColorRightChanged = adjustedDotsRightSvgStringHTML.replace('0 0 2048 2048"', `0 0 2048 2048" fill="${props.colorDotsRightHex}" `);
                              resolve([svgLogoColorChanged, svgDotsColorLeftChanged, svgDotsColorRightChanged]);
                            };
                          });
                        });
                    };
                  });
                });
            };
          });
        });
    });
  }

  function initAddTexts(textItems: AddedTextItems) {
    if (!Object.keys(textItems).length) {
      return Promise.resolve();
    }
    const addTextInitPromises = Object.keys(textItems).map((itemKey) => addTextInit(textItems[itemKey]));
    return Promise.all(addTextInitPromises);
  }

  function initAddLogos(logoItems: AddedLogoItems) {
    if (!Object.keys(logoItems).length) {
      return Promise.resolve([]);
    }
    const addLogoInitPromises = Object.keys(logoItems).map((itemKey) => addLogoInit(logoItems[itemKey]));
    return Promise.all(addLogoInitPromises);
  }

  function addLogo({ imageObj }: AddedImagePosition) {
    const objId = 'tab' + FunctionTab.Logo + '_' + uuidv4();
    fabric.loadSVGFromString(
      imageObj.src.substring(imageObj.src.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
      function (objects) {
        const svgImage = fabric.util.groupSVGElements(objects, {
          left: 0,
          top: 0,
          selectable: true,
          centeredScaling: true,
          cornerStyle: 'circle',
          transparentCorners: false,
          snapAngle: 90,
          snapThreshold: 5,
          editable: false,
          name: objId,
          noScaleCache: true,
          lockUniScaling: true,
          objectCaching: false,
          padding: 0,
          caching: false,
          originX: 'center',
          originY: 'center',
        }) as any;

        svgImage.name = objId;
        svgImage.left = 0;
        svgImage.top = 0;
        svgImage.originX = 'center';
        svgImage.originY = 'center';
        svgImage.centeredScaling = true;
        svgImage.lockUniScaling = true;
        svgImage.snapAngle = 90;
        svgImage.snapThreshold = 5;
        svgImage.noScaleCache = true;
        svgImage.objectCaching = false;


        let initHeight = MAX_LOGO_SIZE_HEIGHT_CM;
        if ((svgImage.width && svgImage.height) && (svgImage.width < svgImage.height)) {
          initHeight = MAX_LOGO_SIZE_HEIGHT_CM;
        } else {
          initHeight = MAX_LOGO_SIZE_HEIGHT_CM / 2;
        }

        svgImage.scaleToHeight(initHeight * cmFactorAdjusted);
        svgImage.maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
        svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;

        const logoSize = {
          maxHeight: MAX_LOGO_SIZE_HEIGHT_CM,
          scaledWidth: svgImage.getScaledWidth() / cmFactorAdjusted,
          scaledHeight: svgImage.getScaledHeight() / cmFactorAdjusted
        };

        fabricCanvas.current?.add(svgImage);
        if (props.isAddingState !== null && props.isAddingState !== undefined) {
          onAddImageItemHandler(props.isAddingState, svgImage, imageObj, logoSize);
        }
      }
    );
  }

  function addLogoInit(logoItem: LogoEditorObject) {
    return new Promise((resolve, reject) => logoItem.reference
      ? JfnetServices.getLogoByReference(logoItem.reference)
        .then((logoImageByRefRes) => {
          let svgTextString = logoImageByRefRes.data;
          if (svgTextString.slice(0, 10).includes('<?xml')) {
            svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
            svgTextString = '<' + svgTextString;
          }
          fabric.loadSVGFromString(
            svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
            function (objects) {
              const svgImage = fabric.util.groupSVGElements(objects, {
                left: logoItem.x,
                top: logoItem.y,
                selectable: true,
                centeredScaling: true,
                cornerStyle: 'circle',
                transparentCorners: false,
                angle: 0,
                snapAngle: 90,
                snapThreshold: 5,
                editable: false,
                name: logoItem.name,
                noScaleCache: true,
                lockUniScaling: true,
                objectCaching: false,
                padding: 0,
                caching: false,
                originX: 'center',
                originY: 'center',
              }) as any;

              svgImage.name = logoItem.name;
              svgImage.left = props.isMobile ? logoItem.x / 4 : logoItem.x;
              svgImage.top = props.isMobile ? logoItem.y / 4 : logoItem.y;
              svgImage.originX = 'center';
              svgImage.originY = 'center';
              svgImage.centeredScaling = true;
              svgImage.lockUniScaling = true;
              svgImage.snapAngle = 90;
              svgImage.snapThreshold = 5;
              svgImage.noScaleCache = true;
              svgImage.objectCaching = false;
              svgImage.src = svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, '');

              if (logoItem.indexLayer) {
                svgImage.indexLayer = logoItem.indexLayer;
              }

              if (logoItem.scaledHeight) {
                svgImage.scaleToHeight(logoItem.scaledHeight * cmFactorAdjusted);
                svgImage.maxHeight = logoItem.maxHeight;
                svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;
              } else {
                svgImage.scaleToHeight(logoItem.maxHeight * cmFactorAdjusted);
                svgImage.maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
                svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;
              }

              fabricCanvas.current?.add(svgImage);
              resolve(svgImage);
              setTimeout(() => {
                if (logoItem.rotation) {
                  svgImage.angle = logoItem.rotation;
                  setTimeout(() => {
                    fabricCanvas.current?.requestRenderAll();
                    updateCanvasTextureMap();
                  }, 100);
                } else {
                  fabricCanvas.current?.requestRenderAll();
                  updateCanvasTextureMap();
                }
              }, 100);
            }
          );
        })
      : resolve('')
    );
  }

  function addText({
    textString,
    addingState,
  }: AddedTextPosition) {
    const idName = 'tab' + addingState + '__' + uuidv4();

    JfnetServices.fontToSvg({
      text: textString,
      font: props.selectedFontFamily
    }).then((res) => {
      let svgTextString = res.data;
      if (svgTextString.slice(0, 10).includes('<?xml')) {
        svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
        svgTextString = '<' + svgTextString;
      }

      fabric.loadSVGFromString(
        svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
        function (objects) {
          const svgImage = fabric.util.groupSVGElements(objects, {
            left: 0,
            top: 0,
            selectable: true,
            centeredScaling: true,
            cornerStyle: 'circle',
            transparentCorners: false,
            snapAngle: 90,
            snapThreshold: 5,
            editable: false,
            name: idName,
            noScaleCache: true,
            lockUniScaling: true,
            objectCaching: false,
            padding: 0,
            caching: false,
            originX: 'center',
            originY: 'center',
          }) as any;

          svgImage.name = idName;
          svgImage.left = 0;
          svgImage.top = 0;
          svgImage.originX = 'center';
          svgImage.originY = 'center';
          svgImage.centeredScaling = true;
          svgImage.lockUniScaling = true;
          svgImage.snapAngle = 90;
          svgImage.snapThreshold = 5;
          svgImage.noScaleCache = true;
          svgImage.objectCaching = false;
          svgImage.fontFamily = props.selectedFontFamily;
          svgImage.fill = props.selectedTextFill
            ? props.selectedTextFill
            : props.colorInvertedBg ? props.colorInvertedBg : '#000000';
          svgImage.text = textString;

          svgImage.scaleToHeight(MAX_TEXT_SIZE_HEIGHT_CM * cmFactorAdjusted);
          svgImage.maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
          svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;

          const textSize = {
            maxHeight: MAX_TEXT_SIZE_HEIGHT_CM,
            scaledWidth: svgImage.getScaledWidth() / cmFactorAdjusted,
            scaledHeight: svgImage.getScaledHeight() / cmFactorAdjusted,
          };

          fabricCanvas.current?.add(svgImage);
          if (props.isAddingState) {
            const file = new File([svgTextString], 'test.svg', {
              type: 'image/svg+xml'
            });
            JfnetServices.checkLogoUpload(file)
              .then((uploadRes) => {
                if (props.isAddingState) {
                  onAddTextItemHandler(
                    props.isAddingState,
                    svgImage,
                    uploadRes.data.reference,
                    textSize
                  );
                }
              })
              .catch((err) => {
                confirmAlert({
                  customUI: ({ onClose }) => {
                    if (err.response && err.response.status === 413) {
                      return (
                        <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                          <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                            { t('configurator_3d_error_file_too_large') }
                          </h5>
                          <button className="button_primary !bg-theme-primary" onClick={onClose}>
                            { t('configurator_3d_ok') }
                          </button>
                        </div>
                      );
                    }
                    return (
                      <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                        <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                          { t('configurator_3d_error_something_wrong') }
                        </h5>
                        <button className="button_primary !bg-theme-primary" onClick={onClose}>
                          { t('configurator_3d_ok') }
                        </button>
                      </div>
                    );
                  }
                });
              });
          }
        }
      );
    });
  }

  function addTextInit(textItem: TextEditorObject) {
    return new Promise((resolve, reject) => JfnetServices.getLogoByReference(textItem.reference)
      .then((textImageByRefRes) => {
        let svgTextString = textImageByRefRes.data;
        if (svgTextString.slice(0, 10).includes('<?xml')) {
          svgTextString = svgTextString.substring(svgTextString.indexOf('<svg')+1);
          svgTextString = '<' + svgTextString;
        }

        fabric.loadSVGFromString(
          svgTextString.substring(svgTextString.indexOf('<svg')).replaceAll(/[\r\n]/g, ''),
          function (objects) {
            const svgImage = fabric.util.groupSVGElements(objects, {
              left: 0,
              top: 0,
              selectable: true,
              centeredScaling: true,
              cornerStyle: 'circle',
              transparentCorners: false,
              angle: 0,
              snapAngle: 90,
              snapThreshold: 5,
              editable: false,
              name: textItem.name,
              noScaleCache: true,
              lockUniScaling: true,
              objectCaching: false,
              padding: 0,
              caching: false,
              originX: 'center',
              originY: 'center',
            }) as any;

            svgImage.name = textItem.name;
            svgImage.originX = 'center';
            svgImage.originY = 'center';
            svgImage.centeredScaling = true;
            svgImage.lockUniScaling = true;
            svgImage.snapAngle = 90;
            svgImage.snapThreshold = 5;
            svgImage.noScaleCache = true;
            svgImage.objectCaching = false;
            svgImage.fontFamily = textItem.fontFamily || props.selectedFontFamily;
            svgImage.fill = textItem.fill;
            svgImage.text = textItem.text;
            svgImage.reference = textItem.reference;

            if (textItem.indexLayer) {
              svgImage.indexLayer = textItem.indexLayer;
            }

            if (textItem.scaledHeight) {
              svgImage.scaleToHeight(textItem.scaledHeight * cmFactorAdjusted);
              svgImage.maxHeight = textItem.maxHeight;
              svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;
            } else {
              svgImage.scaleToHeight(textItem.maxHeight * cmFactorAdjusted);
              const getTabName = textItem.name.split('_')[0];
              const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
              if (getFunctionTab === FunctionTab.Number) {
                svgImage.maxHeight = 30;
              } else {
                svgImage.maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
              }
              svgImage.scaledWidth = svgImage.getScaledWidth() / cmFactorAdjusted;
            }

            svgImage.left = props.isMobile ? textItem.x / 4 : textItem.x;
            svgImage.top = props.isMobile ? textItem.y / 4 : textItem.y;

            delete svgImage.controls.ml;
            delete svgImage.controls.mr;
            delete svgImage.controls.mb;
            delete svgImage.controls.mt;
            fabricCanvas.current?.add(svgImage);
            resolve(svgImage);
            setTimeout(() => {
              if (textItem.rotation) {
                svgImage.angle = textItem.rotation;
                setTimeout(() => {
                  fabricCanvas.current?.requestRenderAll();
                  updateCanvasTextureMap();
                }, 100);
              } else {
                fabricCanvas.current?.requestRenderAll();
                updateCanvasTextureMap();
              }
            }, 100);
          }
        );
      })
      .catch(() => {
        reject('');
      })
    );
  }

  function changeColor(selectedColor: any) {
    if (!Object.keys(selectedColor).length) {
      return;
    }

    if (!fabricCanvas.current || !fabricInsideCanvas.current) {
      return;
    }
    if (!designSvgPatternObject.current) {
      return;
    }
    if (!nodes['outside'].material.map) {
      return;
    }

    const defaultAreaKeys = Object.keys(selectedColor).filter((colorKey) =>
      !colorKey.includes('copy-')
      && !colorKey.includes('bg-')
    );

    defaultAreaKeys.forEach((partKey) => {
      let adjustedPartKey = partKey;
      const foundedPartItem = designSvgPatternObject.current._objects.find((obj: any) => obj.id === adjustedPartKey);
      const foundedCopyPartItem = designSvgPatternObject.current._objects.find((obj: any) => obj.id === 'copy-' + adjustedPartKey);
      const foundedCopyBgPartItem = designSvgPatternObject.current._objects.find((obj: any) => obj.id === 'bg-' + adjustedPartKey);

      const foundedPartInsideItem = designSvgPatternInsideObject.current._objects.find((obj: any) => obj.id === adjustedPartKey);
      const foundedCopyPartInsideItem = designSvgPatternInsideObject.current._objects.find((obj: any) => obj.id === 'copy-' + adjustedPartKey);
      const foundedCopyBgPartInsideItem = designSvgPatternInsideObject.current._objects.find((obj: any) => obj.id === 'bg-' + adjustedPartKey);

      if (props.selectedJerseySectionPattern[adjustedPartKey] && props.selectedJerseySectionPattern[adjustedPartKey].svg) {
        foundedPartItem.fill = selectedColor[adjustedPartKey];
        if (adjustedPartKey.includes('collar') && foundedPartInsideItem) {
          foundedPartInsideItem.fill = selectedColor[adjustedPartKey];
        }
      } else if (foundedPartItem) {
        foundedPartItem.fill = selectedColor[adjustedPartKey];
        if (adjustedPartKey.includes('collar') && foundedPartInsideItem) {
          foundedPartInsideItem.fill = selectedColor[adjustedPartKey];
        }
        if (foundedCopyPartItem) {
          foundedCopyPartItem.fill = selectedColor[adjustedPartKey];
          if (adjustedPartKey.includes('collar') && foundedCopyPartInsideItem) {
            foundedCopyPartInsideItem.fill = selectedColor[adjustedPartKey];
          }
        }
      }
      if (foundedCopyBgPartItem) {
        foundedCopyBgPartItem.fill = selectedColor[adjustedPartKey];
        if (adjustedPartKey.includes('collar') && foundedCopyBgPartInsideItem) {
          foundedCopyBgPartInsideItem.fill = selectedColor[adjustedPartKey];
        }
      }
    });

    setTimeout(() => {
      props.onChangeColor();
      fabricCanvas.current?.requestRenderAll();
      fabricInsideCanvas.current?.requestRenderAll();
      updateCanvasTextureMap();
    }, 200);
  }

  function changePattern(selectedPatternObj: AddedPatternJerseySectionItems) {
    if (!fabricCanvas.current) {
      return;
    }
    if (!designSvgPatternObject.current) {
      return;
    }
    if (!nodes['outside'].material.map) {
      return;
    }
    const filteredCopyObjects = designSvgPatternObject.current._objects.slice()
      .filter((jerseyPartObj: any) => jerseyPartObj.id.includes('copy-'));
    const getPossibleJerseySection = Object.keys(selectedPatternObj);
    if (getPossibleJerseySection.length === 0) {
      return;
    }

    filteredCopyObjects.forEach((jerseyPartObj: any) => {
      const jerseySectionId = jerseyPartObj.id.split('copy-')[1];
      if (selectedPatternObj[jerseySectionId]) {
        // --------- Adjust Pattern SVG ---------------
        const patternFill = selectedPatternObj[jerseySectionId].fill || '#2A2A2D';
        // const patternRotate = !selectedPatternObj[jerseySectionId].tileable ? 0 : (selectedPatternObj[jerseySectionId].rotate || 0);
        const patternScale = !selectedPatternObj[jerseySectionId].tileable ? 1 : (selectedPatternObj[jerseySectionId].scale || 1);
        const patternOffsetX = (selectedPatternObj[jerseySectionId].left || 0);
        const patternOffsetY = (selectedPatternObj[jerseySectionId].top || 0);
        const patternUrl = selectedPatternObj[jerseySectionId].svg;
        if (!patternUrl) {
          jerseyPartObj.set('fill', props.selectedJerseySectionColor[jerseySectionId]);
          setTimeout(() => {
            fabricCanvas.current?.requestRenderAll();
            updateCanvasTextureMap();
          }, 100);
          return;
        }

        const svgString = Base64.decode(patternUrl);
        const patternSvgFileTextResult = svgString;
        if (!patternSvgFileTextResult) {
          return;
        }
        const htmlObject = document.createElement('div');
        htmlObject.innerHTML = patternSvgFileTextResult.toString();
        const svgChildrenArray = Array.prototype.slice.call(htmlObject.children[0].children);
        svgChildrenArray.slice().forEach((ch) => {
          ch.setAttribute('fill', patternFill);
        });

        const adjustedPatternString = htmlObject.children[0].outerHTML;
        fabric.loadSVGFromString(adjustedPatternString, function(objs) {
          const svgImage = fabric.util.groupSVGElements(objs, {});
          svgImage.set({
            strokeWidth: -1.4,
            objectCaching: false,
            centeredScaling: true,
            selectable: false,
            name: uuidv4(),
          });

          const getFrontMainObj = filteredCopyObjects.find((item: any) => item.id === 'copy-front_main');
          const partHeight = getFrontMainObj ? getFrontMainObj.height : jerseyPartObj.height;

          // On Scale
          svgImage.scaleToHeight(partHeight * patternScale);

          const patternSourceCanvas = new fabric.StaticCanvas(null);
          patternSourceCanvas.add(svgImage);
          patternSourceCanvas.skipOffscreen = false;
          patternSourceCanvas.svgViewportTransformation = true;
          patternSourceCanvas.enableRetinaScaling = false;
          patternSourceCanvas.imageSmoothingEnabled = false;
          patternSourceCanvas.setDimensions({
            width: svgImage.getScaledWidth(),
            height: svgImage.getScaledHeight(),
          });
          patternSourceCanvas.renderAll();

          const patternSrc = patternSourceCanvas.getElement() as any;
          const pattern = new fabric.Pattern({
            source: patternSrc,
            repeat: 'repeat',
          });

          // Offset
          pattern.offsetX = parseFloat(patternOffsetX.toString());
          pattern.offsetY = parseFloat(patternOffsetY.toString());
          patternSourceCanvas.calcOffset();

          jerseyPartObj.set('fill', pattern);
          setTimeout(() => {
            props.onAddPattern();
            patternSourceCanvas.requestRenderAll();
            fabricCanvas.current?.requestRenderAll();
            updateCanvasTextureMap();
          }, 100);
        });
        // --------- End of Adjust Pattern SVG ---------------
      } else if (props.selectedJerseySectionColor[jerseySectionId]) {
        jerseyPartObj.set("fill", props.selectedJerseySectionColor[jerseySectionId]);
        setTimeout(() => {
          fabricCanvas.current?.requestRenderAll();
          updateCanvasTextureMap();
        }, 100);
      }
    });
  }

  function setNewJakoLogoColor(svgString: string) {
    if (!fabricCanvas.current) {
      return;
    }
    const logoObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as any;
    if (!logoObj) {
      return;
    }

    const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;
    logoObj.set({
      id: 'removeLogo'
    });
    fabricCanvas.current.remove(logoObj);

    setTimeout(() => {
      fabric.loadSVGFromString(
        svgString,
        function (logoObjects) {
          const newLogoObj = fabric.util.groupSVGElements(logoObjects, {
            width: SVG_SIZE,
            height: SVG_SIZE,
            left: 0,
            top: 0,
            selectable: false,
            objectCaching: false,
          });
          if (!fabricCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
            return;
          }
          const newLogoObjAny = newLogoObj as any;
          newLogoObjAny.set({
            id: 'logo_front',
            selectable: false,
          });
          fabricCanvas.current.add(newLogoObj);
          fabricCanvas.current.sendToBack(newLogoObj);
          fabricCanvas.current.sendToBack(uvObj);
          fabricCanvas.current.requestRenderAll();
          updateCanvasTextureMap();
        }
      );
    }, 100);
  }
  function setNewDotsLogoColor(svgString: string, side: string) {
    if (!fabricCanvas.current) {
      return;
    }
    const dotsObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'dots_front' + side) as any;
    if (!dotsObj) {
      return;
    }

    const uvObj = fabricCanvas.current._objects.find((obj: any) => obj.id === 'mainUv') as fabric.Object;
    dotsObj.set({
      id: 'removeDots' + side
    });
    fabricCanvas.current.remove(dotsObj);

    setTimeout(() => {
      fabric.loadSVGFromString(
        svgString,
        function (dotsObjects) {
          const newDotsObj = fabric.util.groupSVGElements(dotsObjects, {
            width: SVG_SIZE,
            height: SVG_SIZE,
            left: 0,
            top: 0,
            selectable: false,
            objectCaching: false,
          });
          if (!fabricCanvas.current || !canvasRef.current || !canvasInsideRef.current) {
            return;
          }
          const newDotsObjAny = newDotsObj as any;
          newDotsObjAny.set({
            id: 'dots_front' + side,
            selectable: false,
          });
          fabricCanvas.current.add(newDotsObjAny);
          fabricCanvas.current.sendToBack(newDotsObjAny);
          fabricCanvas.current.sendToBack(uvObj);
          fabricCanvas.current.requestRenderAll();
          updateCanvasTextureMap();
        }
      );
    }, 100);
  }

  if (!props.currentCuttingFormCode) {
    return <mesh />;
  }

  return (
    <group>
      <primitive object={scene} />
    </group>
  );
});