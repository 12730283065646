import React, { useEffect, useCallback, useRef, Suspense, useState, useMemo, useContext } from 'react';
import {
  Environment,
  CameraControls,
  PerspectiveCamera
} from '@react-three/drei';
import { Canvas,} from '@react-three/fiber';
import { debounce } from 'lodash';
import { MathUtils } from 'three';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import type { WebGLRenderer } from 'three';
import type { Vector3 } from 'three';
import { NoToneMapping } from 'three/src/constants';
// import { SVG } from '@svgdotjs/svg.js';

import JakoCuttingTab from './JakoCuttingTab';
import JakoDesignTab from './JakoDesignTab';
import JakoPatternTab from './JakoPatternTab';
import JakoColorTab from './JakoColorTab';
import type { JakoTeamnameTabHandler } from './JakoTeamnameTab';
import JakoTeamnameTab from './JakoTeamnameTab';
import type { JakoTextTabHandler } from './JakoTextTab';
import JakoTextTab from './JakoTextTab';
import type { JakoNumberTabHandler } from './JakoNumberTab';
import JakoNumberTab from './JakoNumberTab';
import type { JakoNameTabHandler } from './JakoNameTab';
import JakoNameTab from './JakoNameTab';
import JakoLogoTab from './JakoLogoTab';

import IconSave from './icons/IconSave';
import IconAngle from './icons/IconAngle';
import IconCircleArea from './icons/IconCircleArea';

import CustomScrollbar from './CustomScrollbar';

// This is the data to change something on the shirt. There is no API. Only passing the data via the props.
// In the future, these data should come from the API.
import {
  TAB_ORDER,
  RESERVED_JERSEY_AREAS_ID,
  LANGUAGE_OPTIONS,
  FONT_OPTIONS,
  LOGO_ADD_POSITION,
  TEAMNAME_ADD_POSITION,
  NUMBER_ADD_POSITION,
  INITIAL_ADD_POSITION,
  NAME_ADD_POSITION,
  TEXT_ADD_POSITION,
  CM_FACTOR,
  MAX_TEXT_SIZE_HEIGHT_CM,
  MAX_LOGO_SIZE_HEIGHT_CM,
} from './constants';

import hdri from '../assets/hdri/studio_s_02_1k.hdr';
import '../styles/pages/customizer-page.css';

import { ConfiguratorModelFabric } from '../three-components/ConfiguratorModelFabric';
import type { JakoLogoTabHandler } from './JakoLogoTab';

import type {
  LogoUploadObject,
  AddedTextItems,
  AddedLogoItems,
  AddTextInterface,
  AddImageInterface,
  AddedPatternJerseySectionItems,
  DesignOption,
  DownloadSvgFontToBase64,
  BasicAddPosition,
  SaveConfigState,
  CuttingOptionData,
} from './types';

import {
  CutMaterial,
  FunctionTab,
  JerseySection,
  ControlArea,
  JakoLogoPosition,
  LogoAddPosition,
  NameInitialAddPosition,
  NumberAddPosition,
  TeamnameAddPosition,
  IndividualisationViewCode,
  TextAddPosition,
  ModelStyle
} from './types';

import type { ConfiguratorFabricHandler } from '../three-components/ConfiguratorModelFabric';
import type { RootState} from '@react-three/fiber';
import type { fabric } from 'fabric';

import type {
  TranslationResponseItem,
  PatternResponseItem,
  ColorResponseItem,
  CuttingResponseItem,
  CuttingFileTemplateResponse,
  CuttingOptionResponseItem,
  CustomDesignResponseItem
} from './responses';
import { JakoServices, JfnetServices } from '../helpers/services';
import { BaseContext } from '../contexts/BaseContext';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

// 3D lightings config
function StudioSoLights() {
  return (
    <>
      <Environment
        background={false}
        files={hdri}
      />
      <ambientLight intensity={0.5} castShadow={false} />
      <spotLight
        position={[0, 0, 5]}
        intensity={0.2}
        decay={1}
        castShadow={false}
      />
      <spotLight
        position={[0, 0, -5]}
        intensity={0.2}
        decay={1}
        castShadow={false}
      />
    </>
  );
}

function SidebarContentWrapper({
  showScrollDown,
  sidebarScrollTopPos,
  sidebarScrollBoundingRect,
  children,
  isMobile
}: {
  showScrollDown: boolean,
  sidebarScrollTopPos: number,
  sidebarScrollBoundingRect: DOMRect | undefined,
  children: string | JSX.Element | JSX.Element[],
  isMobile?: boolean
}) {
  const isScrollToBottom = useMemo(() => {
    if (sidebarScrollTopPos > 0) {
      const sidebarEl = document.querySelector('.configurator__sidebarContent');
      if (sidebarEl && sidebarEl.clientHeight) {
        return (sidebarEl.scrollHeight - sidebarEl.scrollTop) - 10 > sidebarEl.clientHeight;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [sidebarScrollTopPos]);
  return isMobile
    ? <div className="mobile__sidebarContent absolute w-full left-0 bottom-[130px] bg-white rounded-t-2xl z-20 shadow-[0_-12px_15px_-4px_rgba(0,0,0,0.1)]">
      { children }
    </div>
    : <CustomScrollbar className="configurator__sidebarContent p-[20px]">
    { children }
    <div className="scroll-down-icon-area absolute bottom-2 left-1/2 transform -translate-x-1/2 text-[5rem] w-[50px] h-[50px] opacity-0 invisible">
      <i className="absolute top-0 left-0 icon icon-3d-angle-down" />
    </div>
    <div
      className={
        "scroll-down-icon fixed transition-all duration-200 text-[5rem] w-[50px] h-[50px] z-20"
        + (showScrollDown && isScrollToBottom ? " opacity-100 visible" : " opacity-0 invisible")
      }
      style={{
        top: sidebarScrollBoundingRect?.top,
        left: sidebarScrollBoundingRect?.left
      }}
    >
      <i className="absolute top-0 left-0 icon icon-3d-angle-down text-[#c3c3c3]" />
    </div>
  </CustomScrollbar>;
}

function JakoReactComponent(props: {
  correlationId?: string,
  visitorId?: string,
  designheaderid?: string,
  company?: string,
  shopCode?: string,
  basketurl?: string,
  languagecode?: string,
  baseapiurl?: string,
  uiapiurl?: string,
  itemno?: string,
}) {
  const {
    correlationId: contextCorrelationId,
    visitorId: contextVisitorId,
    company: contextCompany,
    shopCode: contextShopCode,
    cuttingFormCode: contextCuttingFormCode,
    basketUrl: contextBasketUrl,
    languagecode: contextLanguageCode,
    designheaderid: contextDesignheaderid,
    itemno: contextItemno,
    onclicksubmit,
    isLoadingCustomFields,
    customFields
  } = useContext(BaseContext);
  const jfnetCuttingFormCode = useMemo(() => contextCuttingFormCode.slice().replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', ''), [contextCuttingFormCode]);
  const initLangSettingFromWc = useMemo(() => LANGUAGE_OPTIONS.find((item) => item.name.toUpperCase() === contextLanguageCode.toUpperCase()), [contextLanguageCode]);

  const menuTabFiltered = useMemo(() => TAB_ORDER.slice().filter((tabItem) => {
    if (tabItem.fieldCode) {
      return customFields.find((field) => tabItem.fieldCode && field.code.includes(tabItem.fieldCode));
    } else {
      return true;
    }
  }), [customFields]);

  const currentCanvasGLRef = useRef<WebGLRenderer>();
  const cameraControlsRef = useRef<CameraControls | null>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const configuratorRef = useRef<ConfiguratorFabricHandler>(null);
  const jakoLogoTabRef = useRef<JakoLogoTabHandler>(null);
  const jakoTeamnameTabRef = useRef<JakoTeamnameTabHandler>(null);
  const jakoNumberTabRef = useRef<JakoNumberTabHandler>(null);
  const jakoNameTabRef = useRef<JakoNameTabHandler>(null);
  const jakoTextTabRef = useRef<JakoTextTabHandler>(null);
  const [yPosition, setYPosition] = useState(0);

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const { t, i18n} = useTranslation('translations', { useSuspense: false });

  const [showScrollDownSidebar, setShowScrollDownSidebar] = useState(false);
  const [sidebarScrollTopPos, setSidebarScrollTopPos] = useState(0);
  const [sidebarScrollBoundingRect, setSidebarScrollBoundingRect] = useState<DOMRect | undefined>(undefined);


  const [isAddingState, setIsAddingState] = useState<FunctionTab | null>(null);

  const [selectTab, setSelectTab] = useState<FunctionTab | null>(menuTabFiltered[0].functionTab);
  const [selectTabIndex, setSelectTabIndex] = useState(0);

  const [selectedDesign, setSelectedDesign] = useState<DesignOption | null>(null);
  const [activateDesignOnBack, setActivateDesignOnBack] = useState(false);
  const [hasBackDesign, setHasBackDesign] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [possibleDesignAreaId, setPossibleDesignAreaId] = useState<string[]>([]);

  const [jakoLogoPosition, setJakoLogoPosition] = useState<JakoLogoPosition>(JakoLogoPosition.Right);
  const jakoLogoPositionRef = useRef<JakoLogoPosition>(JakoLogoPosition.Right);

  // ---------- Text State --------------
  const showAddTextInputRef = useRef<HTMLInputElement>(null);
  const showEditTextInputRef = useRef<HTMLInputElement>(null);
  const [addedText, setAddText] = useState('');
  const [addedFabricTextObjects, setAddedFabricTextObjects] = useState({} as AddedTextItems);
  const addedFabricTextObjectsRef = useRef({} as AddedTextItems);
  const [selectedAddedTextKey, setSelectedAddedTextKey] = useState<string | null>(null);
  // ---------- End of Text State -----------

  // ---------- Image State --------------
  const [selectedAddedLogoKey, setSelectedAddedLogoKey] = useState<string | null>(null);
  const [addedLogo, setAddLogo] = useState<LogoUploadObject | null>(null);
  const [addedFabricLogoObjects, setAddedFabricLogoObjects] = useState({} as AddedLogoItems);
  const addedFabricLogoObjectsRef = useRef({} as AddedLogoItems);
  // ---------- End of Image State -----------

  const [selectedFontFamily, setSelectedFontFamily] = useState('Komikazoom');
  const [selectedTextFill, setSelectedTextFill] = useState('');

  // ---------- Color State -------------
  const [selectedJerseySectionColor, setSelectedJerseySectionColor] = useState<any>({});
  const [colorList, setColorList] = useState<ColorResponseItem[]>([]);
  const sortedColorList = useMemo(() => colorList.slice().sort((a, b) => {
    if (a.isPrimary && b.isPrimary) {
      return a.sorting - b.sorting;
    } else if (a.isPrimary) {
      return -1;
    } else if (b.isPrimary) {
      return 1;
    } else {
      return a.sorting - b.sorting;
    }
  }), [colorList]);

  const onlyPossibleDesignAreas = useMemo(() => {
    let results: { [key: string]: string } = {};
    if (possibleDesignAreaId.length) {
      const onlyNonCopyItems = possibleDesignAreaId.slice().filter((area) => !area.includes('collar_under') && !area.includes('placket') && !area.includes('neck') && !area.includes('bg-') && !area.includes('copy'));
      onlyNonCopyItems.forEach((item) => {
        const getWordDesignPart = item.split('_')[0]; // design-1, design-2, design-3
        const getWordDesignJerseySectionPart = item.split('_')[1] ? item.split('_')[1] : ''; // front, back, left, right
        const getDesignWord = getWordDesignPart.split('-')[0] + (getWordDesignPart.split('-')[1] ? ' ' + getWordDesignPart.split('-')[1] : '');
        const getAreaName = getDesignWord + ' ' + getWordDesignJerseySectionPart;
        results = {
          ...results,
          [item]: getAreaName
        };
      });
      return results;
    } else {
      return null;
    }
  }, [possibleDesignAreaId]);

  const sortedColorListRef = useRef<ColorResponseItem[]>([]);
  const colorLogoHexRef = useRef('');
  const colorDotsLeftHexRef = useRef('');
  const colorDotsRightHexRef = useRef('');
  const colorInvertedBgRef = useRef('');

  // const mainWhite =  useMemo(() => sortedColorListRef.current.find((item) => item.code === '3D_WEISS'), [sortedColorListRef]);
  // const mainJakoBlue = useMemo(() => sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU'), [sortedColorListRef]);
  const mainMarine = useMemo(() => sortedColorListRef.current.find((item) => item.code === '3D_MARINE'), [sortedColorListRef]);
  const mainSchwarz = useMemo(() => sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ'), [sortedColorListRef]);

  const selectedLang = useRef(initLangSettingFromWc || LANGUAGE_OPTIONS[0]);

  // const [colorJerseySection, setColorJerseySection] = useState<string>('');
  // const [colorStep, setColorStep] = useState(0);
  // ---------- End of Color State -------------

  // ---------- Pattern State -------------
  // const [selectedJerseySectionPattern, setSelectedJerseySectionPattern] = useState({
  //   front: {
  //     svg: "/static/media/bander.4e09f7d16fba0a8455775a19a8ed4917.svg",
  //     fill: '#fff'
  //   },
  // } as AddedPatternJerseySectionItems);
  const [selectedJerseySectionPattern, setSelectedJerseySectionPattern] = useState({} as AddedPatternJerseySectionItems);
  const [patternJerseySection, setPatternJerseySection] = useState<string>('');
  const [patternList, setPatternList] = useState<PatternResponseItem[]>([]);
  // ---------- End of Pattern State -------------

  const addSectionPartRef = useRef<JerseySection>(JerseySection.Front);
  const addSectionPositionRef = useRef<BasicAddPosition | NameInitialAddPosition | NumberAddPosition | TeamnameAddPosition | TextAddPosition | LogoAddPosition | null>(null);
  const initialSaveConfigDataRef = useRef<SaveConfigState | null>(null);
  const updateSaveConfigDataRef = useRef<SaveConfigState | null>(null);

  const [initialized, setInitialized] = useState(false);

  const cuttingFormResponseDataRef = useRef<CuttingResponseItem[]>([]);
  const jfnetCurrentCuttingFormCodeRef = useRef(jfnetCuttingFormCode);
  const currentCuttingFormCodeRef = useRef(contextCuttingFormCode);
  const selectModelStyle = useMemo<ModelStyle>(() => currentCuttingFormCodeRef.current.length < 9 ? ModelStyle.Short : ModelStyle.Shirt, [currentCuttingFormCodeRef]);

  const currentCuttingFormItemNoRef = useRef('');
  const cuttingFileTemplateResponseRef = useRef<CuttingFileTemplateResponse | null>(null);

  const cuttingFormLinksResponseRef = useRef<CuttingOptionResponseItem[]>([]);
  const cuttingOptions = useRef<CuttingOptionData[]>([]);

  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [isMobileStartState, setIsMobileStartState] = useState<boolean | null>(null);
  const [isSavingScreenshot, setIsSavingScreenshot] = useState(false);
  const closeFunctionMobileChange = useRef(null as any);

  const isEditedMemo = useMemo(
    () => (Object.keys(addedFabricTextObjects).length > 0
      || Object.keys(addedFabricLogoObjects).length > 0
      || Object.keys(selectedJerseySectionPattern).length > 0)
      || (selectedDesign ? selectedDesign.name !== 'Blanko' : false),
    [selectedDesign, addedFabricTextObjects, addedFabricLogoObjects, selectedJerseySectionPattern]
  );

  const hasUnprocessableImage = useMemo(
    () => Object.keys(addedFabricLogoObjects).length > 0 && Object.keys(addedFabricLogoObjects).some((key) => addedFabricLogoObjects[key].isUnprocessable),
    [addedFabricLogoObjects]
  );
  // const cmFactorAdjusted = useMemo(() =>isMobile ? CM_FACTOR / 2 : CM_FACTOR ,[isMobile]);

  useEffect(() => {
    function handleWindowResize() {
      setShowScrollDownSidebarHandler();
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      if (window.innerWidth < 1024) {
        setIsMobile(true);
      } else if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
          .test(navigator.userAgent)
          || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i
            .test(navigator.userAgent.substr(0, 4))
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    setSidebarOnScrollHandler();
    document.addEventListener('keydown', onKeyDown);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (selectModelStyle === ModelStyle.Short) {
      setYPosition(-0.2);
      setJakoLogoPosition(JakoLogoPosition.Left);
      jakoLogoPositionRef.current = JakoLogoPosition.Left;
    } else {
      setYPosition(0);
      setJakoLogoPosition(JakoLogoPosition.Right);
      jakoLogoPositionRef.current = JakoLogoPosition.Right;
    }
  }, [selectModelStyle]);

  useEffect(() => {
    setTimeout(() => {
      moveCameraToFrontSide(true);
    }, 500);
    if (isMobile) {
      setSelectTab(null);
    } else {
      setSelectTab(menuTabFiltered[0].functionTab);
    }
    setSelectTabIndex(0);
    // eslint-disable-next-line
  }, [isMobile]);

  useEffect(() => {
    if (isMobile === null) {
      return;
    }
    if (isMobileStartState === null) {
      setIsMobileStartState(isMobile);
      return;
    }
    if (isLoading || isLoadingInitial) {
      return;
    }
    if (closeFunctionMobileChange.current && isMobileStartState === isMobile) {
      closeFunctionMobileChange.current();
      return;
    }
    if (isMobileStartState !== isMobile) {
      confirmAlert({
        customUI: ({ onClose }) => {
          closeFunctionMobileChange.current = onClose;
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_different_screen_change_need_refresh') }
              </h5>
              <div className="w-full flex flex-col gap-4 mt-10">
                <button className="button_primary" onClick={() => handleSaveConfiguration(false, true)}>
                  { t('configurator_3d_save_before_refresh') }
                </button>
                <button className="outlineButton" onClick={handleOnScreenChange}>
                  { t('configurator_3d_refresh') }
                </button>
              </div>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
      return;
    }
    closeFunctionMobileChange.current = null;
    // eslint-disable-next-line
  }, [isMobileStartState, isMobile]);

  function handleOnScreenChange() {
    window.location.reload();
  }

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      configuratorRef.current?.updateObjectPosition('up');
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      configuratorRef.current?.updateObjectPosition('left');
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      configuratorRef.current?.updateObjectPosition('right');
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      configuratorRef.current?.updateObjectPosition('down');
    }
  };

  function setSidebarOnScrollHandler() {
    setSidebarScrollTopPos(0);
    const sidebarContentEl = document.querySelector('.configurator__sidebarContent');
    if (!sidebarContentEl) {
      return;
    }

    sidebarContentEl.addEventListener('scroll', function() {
      setSidebarScrollTopPos(sidebarContentEl.scrollTop);
    });
  }

  function setShowScrollDownSidebarHandler() {
    const sidebarContentEl = document.querySelector('.configurator__sidebarContent');
    if (sidebarContentEl) {
      const hasVerticalScrollbar = sidebarContentEl.scrollHeight > sidebarContentEl.clientHeight;
      setShowScrollDownSidebar(hasVerticalScrollbar);

      setTimeout(() => {
        const scrollDownButtonAreaEl = document.querySelector('.scroll-down-icon-area');
        if (scrollDownButtonAreaEl) {
          setSidebarScrollBoundingRect(scrollDownButtonAreaEl.getBoundingClientRect());
        }
      }, 100);
    } else {
      setShowScrollDownSidebar(false);
    }
  }

  const onChangeLanguageHandler = useCallback((lang?: string, isInit?: boolean) => {
    if (!isInit) {
      setIsLoading(true);
    }
    const selectLang = lang?.toUpperCase() || 'DE';
    const correlationId = contextCorrelationId || '111';
    const company = contextCompany || 'JAKO AG';
    const forParam = 1;
    JakoServices.getTranslations({
      correlationId,
      company,
      selectLang,
      forParam
    }).then((response) => {
      const translationData = response.data as TranslationResponseItem[];
      let mapIntoTranslationData = {};
      translationData.forEach((item) => {
        mapIntoTranslationData = {
          ...mapIntoTranslationData,
          [item.tag]: item.translation
        };
      });
      i18n.addResourceBundle(selectLang.toLowerCase(), 'translations', mapIntoTranslationData);
      i18n.changeLanguage(selectLang.toLowerCase())
        .finally(() => {
          if (!isInit) {
            setIsLoading(false);
          }
        });
    });
    // eslint-disable-next-line
  }, [i18n]);

  useEffect(() => {
    if (!i18n.language) {
      return;
    }
    if (i18n.language.toUpperCase() === contextLanguageCode.toUpperCase()) {
      return;
    }
    initColorList();
    // eslint-disable-next-line
  }, [contextLanguageCode, i18n.language]);


  useEffect(() => {
    if (!initialized) {
      fetchCuttingFormData()
        .then((jakoCuttingResponse) => {
          const correlationId = contextCorrelationId  || '111';
          const company = contextCompany || 'JAKO AG';
          const shopCode = contextShopCode || 'B2C';
          const languageCode = selectedLang.current.name || contextLanguageCode;
          JakoServices.getCuttingsOptions({
            correlationId,
            company,
            shopCode,
            languageCode,
          }).then((resCuttingOptions) => {
            cuttingFormLinksResponseRef.current = resCuttingOptions.data;
            setCuttingFormOptions(contextCuttingFormCode);
            onChangeLanguageHandler(contextLanguageCode.toUpperCase(), true);
            initialSaveConfigState(jakoCuttingResponse)
              .then(() => {
                setInitialized(true);
              });
          });
        });
    }
    // eslint-disable-next-line
  }, [initialized, selectedLang]);

  function setCuttingFormOptions(selectCuttingFormCode: string) {
    const getOptionsOfSelectedCutting = cuttingFormLinksResponseRef.current.slice().filter((item) => item.code !== '3D_MATERIAL' && item.cuttingFormCode === selectCuttingFormCode);
    const foundedSportType = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_SPORTART');
    if (!foundedSportType) {
      return;
    }

    const foundedCollarCutting = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_KRAGENFORM');
    const onlySelectedCollarCuttingItems = foundedCollarCutting
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedCollarCutting.value)
      : [];
    const possibleCuttingFormCodesOfSelectedCollarCutting = onlySelectedCollarCuttingItems.map((item: any) => item.cuttingFormCode);

    const foundedSleeveCutting = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_SCHNITTFORM');
    const onlySelectedSleeveCuttingItems = foundedSleeveCutting
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedSleeveCutting.value)
      : [];
    const possibleCuttingFormCodesOfSelectedSleeveCutting = onlySelectedSleeveCuttingItems.map((item: any) => item.cuttingFormCode);

    const foundedSleeveShape = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_SCHNITTFORM_AERMEL');
    const onlySelectedSleeveShapeItems = foundedSleeveShape
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedSleeveShape.value)
      : [];
    const possibleCuttingFormCodesOfSelectedSleeveShape = onlySelectedSleeveShapeItems.map((item: any) => item.cuttingFormCode);

    const foundedCollarMaterial = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_KRAGENABSCHLUSS');
    const onlySelectedCollarMaterialItems = foundedCollarMaterial
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedCollarMaterial.value)
      : [];
    const possibleCuttingFormCodesOfSelectedCollarMaterial = onlySelectedCollarMaterialItems.map((item: any) => item.cuttingFormCode);

    const foundedCuffMaterial = getOptionsOfSelectedCutting.find((item: any) => item.code === '3D_AERMELABSCHLUSS');
    const onlySelectedCuffMaterialItems = foundedCuffMaterial
      ? cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedCuffMaterial.value)
      : [];
    const possibleCuttingFormCodesOfSelectedCuffMaterial = onlySelectedCuffMaterialItems.map((item: any) => item.cuttingFormCode);

    const onlySelectedSportItems = cuttingFormLinksResponseRef.current.slice().filter((item: any) => item.value === foundedSportType.value);
    const possibleCuttingFormCodesOfSelectedSport = onlySelectedSportItems.map((item: any) => item.cuttingFormCode);
    // Loop each possible option title
    // - Kragenform
    // - Schnittform
    // - Schnittform der Ärmel
    // - Ärmelabschluss
    // - Kragenmaterial
    const mappedPossibleOptionsTest = getOptionsOfSelectedCutting.map((optItem) => {
      // get each option title value
      const getPossibleOptionsForEachTitle = cuttingFormLinksResponseRef.current.slice().filter((item) =>
        optItem.code === item.code
        && possibleCuttingFormCodesOfSelectedSport.includes(item.cuttingFormCode)
      );

      const filteredPossibleOptions = getPossibleOptionsForEachTitle.slice().filter((item) => {
        if (item.code === '3D_KRAGENFORM') {
          return (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || (possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode)));
        } else if (item.code === '3D_SCHNITTFORM') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode));
        } else if (item.code === '3D_SCHNITTFORM_AERMEL') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode));
        } else if (item.code === '3D_KRAGENABSCHLUSS') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCuffMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCuffMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode));
        } else if (item.code === '3D_AERMELABSCHLUSS') {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedCollarMaterial.length === 0 || possibleCuttingFormCodesOfSelectedCollarMaterial.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveShape.length === 0 || possibleCuttingFormCodesOfSelectedSleeveShape.includes(item.cuttingFormCode))
            && (possibleCuttingFormCodesOfSelectedSleeveCutting.length === 0 || possibleCuttingFormCodesOfSelectedSleeveCutting.includes(item.cuttingFormCode));
        } else {
          return (possibleCuttingFormCodesOfSelectedCollarCutting.length === 0 || possibleCuttingFormCodesOfSelectedCollarCutting.includes(item.cuttingFormCode));
        }
      });

      const mappedItems = [
        ...filteredPossibleOptions
          .reduce((reducer: any, obj: any) => {
            const key = obj.code + '_' + obj.value; // group by groupId
            let item;
            item = reducer.get(key) ? // If not found same key then create a new one
              {
                ...reducer.get(key),
                groupId: key,
                items: reducer.get(key).items ?
                  [
                    ...reducer.get(key).items,
                    obj,
                  ] :
                  [
                    reducer.get(key), // first item of the set
                    obj,
                  ],
              } :
              {
                ...obj,
              };
            return reducer.set(key, item);
          }, new Map()).values(),
      ];

      const allPossibleItems = [
        ...getPossibleOptionsForEachTitle
          .reduce((reducer: any, obj: any) => {
            const key = obj.code + '_' + obj.value; // group by groupId
            let item;
            item = reducer.get(key) ? // If not found same key then create a new one
              {
                ...reducer.get(key),
                groupId: key,
                items: reducer.get(key).items ?
                  [
                    ...reducer.get(key).items,
                    obj,
                  ] :
                  [
                    reducer.get(key), // first item of the set
                    obj,
                  ],
              } :
              {
                ...obj,
              };
            return reducer.set(key, item);
          }, new Map()).values(),
      ];

      return {
        ...optItem,
        mappedItems,
        allPossibleItems
      };
    });
    cuttingOptions.current = mappedPossibleOptionsTest;
  }
  function initialSaveConfigState(jakoCuttingResponse: any) {
    if (!jakoCuttingResponse.data) {
      setIsLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_undefined_cutting_data_response') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return Promise.resolve();
    }
    if (jakoCuttingResponse.data.length === 0) {
      setIsLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_empty_cutting_data_response') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      return Promise.resolve();
    }

    // --------- local testing purpose -------------------
    const urlParams = new URLSearchParams(window.location.search);
    const testGetSaveDesignHeaderId = urlParams.get('testGetSaveId');

    if (testGetSaveDesignHeaderId) {
      return JfnetServices.getConfigs('111222333')
      .then((res) => {
        if (res.data.data.length < 25) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }
        const saveConfigStateData = JSON.parse(atob(res.data.data)) as SaveConfigState;
        if (!Object.keys(saveConfigStateData).length) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }

        return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
          .then((cuttingRes) => {
            cuttingFileTemplateResponseRef.current = cuttingRes.data;
            cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
            const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
            currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;

            initialSaveConfigDataRef.current = saveConfigStateData;
            updateSaveConfigDataRef.current = saveConfigStateData;
            colorLogoHexRef.current = saveConfigStateData.colorLogoHexRef;
            colorDotsLeftHexRef.current = saveConfigStateData.colorDotsLeftHexRef;
            colorDotsRightHexRef.current  = saveConfigStateData.colorDotsRightHexRef;
            colorInvertedBgRef.current = saveConfigStateData.colorInvertedBgRef;
            if (saveConfigStateData.selectedDesign) {
              // check design and set jersey color and pattern on start
              onSelectDesignStart(saveConfigStateData.selectedDesign, saveConfigStateData.selectedJerseySectionColor, saveConfigStateData.selectedJerseySectionPattern);
            }
            setAddedFabricLogoObjects(saveConfigStateData.addedFabricLogoObjects);
            addedFabricLogoObjectsRef.current = saveConfigStateData.addedFabricLogoObjects;
            setAddedFabricTextObjects(saveConfigStateData.addedFabricTextObjects);
            addedFabricTextObjectsRef.current = saveConfigStateData.addedFabricTextObjects;
            jakoLogoPositionRef.current = saveConfigStateData.jakoLogoPosition;
            setJakoLogoPosition(saveConfigStateData.jakoLogoPosition);
          });
      });
    }

    if (!contextDesignheaderid) {
      return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
        .then((res) => {
          cuttingFileTemplateResponseRef.current = res.data;
          cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
          setSelectedDesign({
            file: res.data.blankoDesignFile,
            previewFile: res.data.blankoDesignPreviewFile,
            name: 'Blanko'
          });
          const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
          currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
        });
    }
    if (contextDesignheaderid.toString() === '0') {
      return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
        .then((res) => {
          cuttingFileTemplateResponseRef.current = res.data;
          cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
          setSelectedDesign({
            file: res.data.blankoDesignFile,
            previewFile: res.data.blankoDesignPreviewFile,
            name: 'Blanko'
          });
          const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
          currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
        });
    }
    return JfnetServices.getConfigs(contextDesignheaderid.toString())
      .then((res) => {
        if (res.data.data.length < 25) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }
        const saveConfigStateData = JSON.parse(atob(res.data.data)) as SaveConfigState;
        if (!Object.keys(saveConfigStateData).length) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }
        if (!contextDesignheaderid) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }
        if (!saveConfigStateData.designHeaderId) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }
        if (parseInt(contextDesignheaderid.toString()) !== parseInt(saveConfigStateData.designHeaderId.toString())) {
          return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
            .then((cuttingRes) => {
              cuttingFileTemplateResponseRef.current = cuttingRes.data;
              cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
              setSelectedDesign({
                file: res.data.blankoDesignFile,
                previewFile: res.data.blankoDesignPreviewFile,
                name: 'Blanko'
              });
              const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
              currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;
            });
        }

        return JfnetServices.getCuttingFiles(jfnetCurrentCuttingFormCodeRef.current)
          .then((cuttingRes) => {
            cuttingFileTemplateResponseRef.current = cuttingRes.data;
            cuttingFormResponseDataRef.current = jakoCuttingResponse.data;
            const foundedCuttingFormData: CuttingResponseItem = jakoCuttingResponse.data.find((item: CuttingResponseItem) => item.code === contextCuttingFormCode);
            currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;

            initialSaveConfigDataRef.current = saveConfigStateData;
            updateSaveConfigDataRef.current = saveConfigStateData;
            colorLogoHexRef.current = saveConfigStateData.colorLogoHexRef;
            colorDotsLeftHexRef.current = saveConfigStateData.colorDotsLeftHexRef;
            colorDotsRightHexRef.current  = saveConfigStateData.colorDotsRightHexRef;
            colorInvertedBgRef.current = saveConfigStateData.colorInvertedBgRef;
            if (saveConfigStateData.selectedDesign) {
              // check design and set jersey color and pattern on start
              onSelectDesignStart(saveConfigStateData.selectedDesign, saveConfigStateData.selectedJerseySectionColor, saveConfigStateData.selectedJerseySectionPattern);
            }
            setAddedFabricLogoObjects(saveConfigStateData.addedFabricLogoObjects);
            addedFabricLogoObjectsRef.current = saveConfigStateData.addedFabricLogoObjects;
            setAddedFabricTextObjects(saveConfigStateData.addedFabricTextObjects);
            addedFabricTextObjectsRef.current = saveConfigStateData.addedFabricTextObjects;
            jakoLogoPositionRef.current = saveConfigStateData.jakoLogoPosition;
            setJakoLogoPosition(saveConfigStateData.jakoLogoPosition);
          });
      });
  }

  function getUpdateSaveConfigForCustomDesignState(
    designHeaderId: string,
  ) {
    if (designHeaderId === '0') {
      return Promise.resolve();
    }
    return JfnetServices.getConfigs(designHeaderId)
      .then((res) => {
        const data = JSON.parse(atob(res.data.data)) as SaveConfigState;
        if (!Object.keys(data).length) {
          return;
        }
        if (!res.data.designHeaderId) {
          return;
        }
        return data;
      });
  }

  function onCanvasCreated(state: RootState) {
    state.gl.localClippingEnabled = true;
    currentCanvasGLRef.current = state.gl;
  }

  function onCameraUpdate(e: any) {
    if (isSavingScreenshot) {
      return;
    }
    if (cameraControlsRef.current) {
      if (distanceCount.current > 4) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 1;
      }
      handleOnZoom(e.target.distance);
    }
  }

  function fetchCuttingFormData() {
    const correlationId = contextCorrelationId  || '111';
    const company = contextCompany || 'JAKO AG';
    const shopCode = contextShopCode || 'B2C';
    const languageCode = selectedLang.current.name || contextLanguageCode;
    return JakoServices.getCuttings({
      correlationId,
      company,
      shopCode,
      languageCode,
    });
  }

  function onSelectCutMaterial(material: CutMaterial) {
    if (currentCuttingFormCodeRef.current.includes('BE') && material === CutMaterial.Int) {
      currentCuttingFormCodeRef.current = currentCuttingFormCodeRef.current.replace('BE', 'INT');
    } else {
      currentCuttingFormCodeRef.current = currentCuttingFormCodeRef.current.replace('INT', 'BE');
    }
  }

  function onSelectCut(cuttingFormCode: string, material: CutMaterial) {
    const removeMaterialCuttingFormCode = cuttingFormCode.replace('INT', '').replace('BE', '').replace('RUJ', '').replace('RUS', '');
    if (removeMaterialCuttingFormCode === jfnetCurrentCuttingFormCodeRef.current) {
      return;
    }
    changeCuttingForm(cuttingFormCode, removeMaterialCuttingFormCode, material);
  }

  function changeCuttingForm(foundedCuttingFormCode: string, jfnetFoundedCuttingFormCode: string, material: CutMaterial) {
    setIsLoading(true);
    setCuttingFormOptions(foundedCuttingFormCode);
    JfnetServices.getCuttingFiles(jfnetFoundedCuttingFormCode)
      .then((res) => {
        cuttingFileTemplateResponseRef.current = res.data;
        const foundedCuttingFormData = cuttingFormResponseDataRef.current.find((item) => item.model === jfnetFoundedCuttingFormCode);
        setSelectedDesign({
          file: res.data.blankoDesignFile,
          previewFile: res.data.blankoDesignPreviewFile,
          name: 'Blanko'
        });

        // Handle Selected Material
        if (foundedCuttingFormData) {
          if (foundedCuttingFormData.code.includes('RUJ') || foundedCuttingFormData.code.includes('RUS')) {
            currentCuttingFormCodeRef.current = foundedCuttingFormData.code;
          } else if (foundedCuttingFormData.code.includes('BE') && material === CutMaterial.Int) {
            currentCuttingFormCodeRef.current = foundedCuttingFormData.code.replace('BE', 'INT');
          } else {
            currentCuttingFormCodeRef.current = foundedCuttingFormData.code.replace('INT', 'BE');
          }
          currentCuttingFormItemNoRef.current = foundedCuttingFormData.itemNo;
        } else {
          if (foundedCuttingFormCode.includes('RUJ') || foundedCuttingFormCode.includes('RUS')) {
            currentCuttingFormCodeRef.current = foundedCuttingFormCode;
          } else if (foundedCuttingFormCode.includes('BE') && material === CutMaterial.Int) {
            currentCuttingFormCodeRef.current = foundedCuttingFormCode.replace('BE', 'INT');
          } else {
            currentCuttingFormCodeRef.current = foundedCuttingFormCode.replace('INT', 'BE');
          }
          currentCuttingFormItemNoRef.current = contextItemno;
        }

        jfnetCurrentCuttingFormCodeRef.current = jfnetFoundedCuttingFormCode;
        resetAllData();
      });
  }

  function resetAllData() {
    setIsAddingState(null);
    setAddText('');
    setAddedFabricTextObjects({});
    addedFabricTextObjectsRef.current = {};
    setSelectedAddedTextKey(null);
    setSelectedAddedLogoKey(null);
    setAddLogo(null);
    setAddedFabricLogoObjects({});
    addedFabricLogoObjectsRef.current = {};
    setSelectedJerseySectionPattern({});
    setPatternJerseySection('');
    addSectionPartRef.current = JerseySection.Front;
    addSectionPositionRef.current = null;

    // Reset Cam to Front
    moveCameraToFrontSide(true);
  }

  const zoomOut = debounce(function() {
    if (!cameraControlsRef.current) {
      return;
    }
    endDistance.current = 0;
    cameraControlsRef.current.setTarget(0, 0, 0, true);
    cameraControlsRef.current.dolly(-10, true);
  }, 100);

  const handleOnZoom = function(distance: any) {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      zoomOut();
    }
  };

  function onFocusCamera(vec: Vector3) {
    cameraControlsRef.current?.setPosition(vec.x, vec.y, vec.z, true);
  }

  function onSelectObjectItemFromFabric(name: string) {
    const getTabName = name.split('_')[0];
    const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
    if (getFunctionTab === FunctionTab.Logo) {
      onSelectLogoFromFabric(name);
    } else {
      onSelectTextFromFabric(name, getFunctionTab);
    }
    setTimeout(() => {
      setSelectTab(getFunctionTab);
    }, 100);
  }

  function onDeleteFromFabric(name: string) {
    const getTabName = name.split('_')[0];
    const getFunctionTab = parseInt(getTabName.split('tab')[1]) as FunctionTab;
    if (getFunctionTab === FunctionTab.Logo) {
      onLogoTabBack();
      setTimeout(() => {
        const updatedAddedFabricLogoObjects = {...addedFabricLogoObjectsRef.current};
        delete updatedAddedFabricLogoObjects[name];
        setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
        addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
        setSelectedAddedLogoKey(null);
      }, 100);
      return;
    }

    onTextTabBack();
    setTimeout(() => {
      setSelectedAddedTextKey(null);
      const updatedAddedFabricTextObjects = {...addedFabricTextObjectsRef.current};
      delete updatedAddedFabricTextObjects[name];
      setAddedFabricTextObjects(updatedAddedFabricTextObjects);
      addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    }, 100);
  }

  // function toBase64(file: File | Blob): Promise<string | ArrayBuffer | null> {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });
  // }

  function onControlAreaClick(controlArea: ControlArea) {
    if (controlArea === ControlArea.ZoomIn) {
      cameraControlsRef.current?.dolly(  1, true );
    }
    if (controlArea === ControlArea.ZoomOut) {
      cameraControlsRef.current?.dolly(  -1, true );
    }
    if (controlArea === ControlArea.RotateLeft) {
      cameraControlsRef.current?.rotate(  45 * MathUtils.DEG2RAD, 0, true );
    }
    if (controlArea === ControlArea.RotateRight) {
      cameraControlsRef.current?.rotate(  -45 * MathUtils.DEG2RAD, 0, true );
    }
  }

  // ---- Left side UI handler ----------
  function onSelectAddNamePosition(part: JerseySection, position: NameInitialAddPosition) {
    addSectionPartRef.current = part;
    addSectionPositionRef.current = position;
    // back
    if (position === NameInitialAddPosition.BackSuperBottomFull || position === NameInitialAddPosition.BackTopFull) {
      moveCameraToBackSide();
    }
    // front
    else if (position === NameInitialAddPosition.FrontMiddleFull || position === NameInitialAddPosition.FrontTopCenter || position === NameInitialAddPosition.FrontTopLeft) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === NameInitialAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === NameInitialAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === NameInitialAddPosition.ShortBottomLeft || position === NameInitialAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === NameInitialAddPosition.ShortBottomRight || position === NameInitialAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddLogoPosition(part: JerseySection, position: LogoAddPosition, onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }
    // back
    if (position === LogoAddPosition.BackBottomFull
      || position === LogoAddPosition.BackTopFull
      || position === LogoAddPosition.BackMiddleFull
      || position === LogoAddPosition.Neck
    ) {
      moveCameraToBackSide();
    }
    // front
    else if (position === LogoAddPosition.FrontMiddleFull
      || position === LogoAddPosition.FrontTopCenter
      || position === LogoAddPosition.FrontTopLeft
      || position === LogoAddPosition.FrontUnderLogoFull) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === LogoAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === LogoAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === LogoAddPosition.ShortBottomRight || position === LogoAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddNumberPosition(part: JerseySection, position: NumberAddPosition,  onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }
    // back
    if (position === NumberAddPosition.BackMiddleFull) {
      moveCameraToBackSide();
    }
    // front
    else if (position === NumberAddPosition.FrontTopCenter || position === NumberAddPosition.FrontTopLeft) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === NumberAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === NumberAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === NumberAddPosition.ShortBottomLeft || position === NumberAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === NumberAddPosition.ShortBottomRight || position === NumberAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddTeamnamePosition(part: JerseySection, position: TeamnameAddPosition, onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }

    // back
    if (position === TeamnameAddPosition.BackBottomFull || position === TeamnameAddPosition.BackTopFull) {
      moveCameraToBackSide();
    }

    else if (position === TeamnameAddPosition.ShortBottomLeft || position === TeamnameAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === TeamnameAddPosition.ShortBottomRight || position === TeamnameAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onSelectAddTextPosition(part: JerseySection, position: TextAddPosition, onlyCamera?: boolean) {
    if (!onlyCamera) {
      addSectionPartRef.current = part;
      addSectionPositionRef.current = position;
    }
    // back
    if (position === TextAddPosition.BackBottomFull
      || position === TextAddPosition.BackTopFull
      || position === TextAddPosition.Neck
    ) {
      moveCameraToBackSide();
    }
    // front
    else if (position === TextAddPosition.FrontBottomFull || position === TextAddPosition.FrontUnderLogoFull) {
      moveCameraToFrontSide();
    }
    // right
    else if (position === TextAddPosition.RightArm) {
      moveCameraToRightSide();
    }
    // left
    else if (position === TextAddPosition.LeftArm) {
      moveCameraToLeftSide();
    }

    else if (position === TextAddPosition.ShortBottomLeft || position === TextAddPosition.ShortUpLeft) {
      moveCameraToLeftSideShort();
    }

    else if (position === TextAddPosition.ShortBottomRight || position === TextAddPosition.ShortUpRight) {
      moveCameraToRightSideShort();
    }
  }

  function onTextTabBack() {
    setIsAddingState(null);
    onSelectText(null);
  }

  function onLogoTabBack() {
    setIsAddingState(null);
    onSelectLogo(null);
  }

  function onSelectJakoLogoPosition(position: JakoLogoPosition) {
    jakoLogoPositionRef.current = position;
    setJakoLogoPosition(position);
  }

  function onAddLogoInput(files: {
    fileName: string,
    src: string,
    isUnprocessable: boolean,
    reference: string
  }) {
    setIsAddingState(FunctionTab.Logo);
    setAddLogo(files);
  }

  function onSubmitAddTextInputFromTab(functionTab: FunctionTab) {
    if (!showAddTextInputRef.current) {
      return;
    }
    const textValue = showAddTextInputRef.current.value;
    setAddText(textValue);
    setIsAddingState(functionTab);
  }

  function onSubmitEditTextInput(name: string, e: any, functionTab: FunctionTab, isNotEditReplaceAll?: boolean) {
    e.preventDefault();
    if (!showEditTextInputRef.current) {
      return;
    }
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    setIsAddingState(FunctionTab.ResetStart);
    const textValue = showEditTextInputRef.current.value;
    JfnetServices.fontToSvg({
      text: textValue,
      font: addedFabricTextObjectsRef.current[name].fontFamily || selectedFontFamily
    }).then((res) => {
      setTimeout(() => {
        configuratorRef.current?.editTextHandler({
          textName: name,
          text: textValue,
          svgData: res.data
        }).then((activeEditTextData) => {
          if (isNotEditReplaceAll) {
            configuratorRef.current?.updateTextNewTextValue(name, [name], textValue, activeEditTextData.reference)
              .then((res) => {
                if (!res.length) {
                  return;
                }
                const updatedAddedFabricTextObjects: AddedTextItems = {
                  ...addedFabricTextObjectsRef.current,
                  [name]: {
                    ...addedFabricTextObjectsRef.current[name],
                    text: textValue,
                    reference: activeEditTextData.reference,
                    scaledHeight: activeEditTextData.scaledHeight,
                    scaledWidth: activeEditTextData.scaledWidth,
                  }
                };
                setAddedFabricTextObjects(updatedAddedFabricTextObjects);
                addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
              });
            return;
          }

          const addedItemsOfSelectedTab = Object.keys(addedFabricTextObjectsRef.current).slice()
            .filter((itemKey) => addedFabricTextObjectsRef.current[itemKey].functionTab === functionTab);
          let updatedAddedFabricTextObjects: AddedTextItems = {};

          configuratorRef.current?.updateTextNewTextValue(name, addedItemsOfSelectedTab, textValue, activeEditTextData.reference)
            .then((res) => {
              if (!res.length) {
                return;
              }
              res.forEach((resItem) => {
                updatedAddedFabricTextObjects = {
                  ...updatedAddedFabricTextObjects,
                  [resItem.itemName]: {
                    ...addedFabricTextObjectsRef.current[resItem.itemName],
                    text: textValue,
                    reference: resItem.reference,
                    scaledHeight: resItem.scaledHeight,
                    scaledWidth: resItem.scaledWidth,
                  }
                };
              });
              updatedAddedFabricTextObjects = {
                ...updatedAddedFabricTextObjects,
                [name]: {
                  ...addedFabricTextObjectsRef.current[name],
                  text: textValue,
                  reference: activeEditTextData.reference,
                  scaledHeight: activeEditTextData.scaledHeight,
                  scaledWidth: activeEditTextData.scaledWidth,
                }
              };
              setAddedFabricTextObjects({
                ...addedFabricTextObjectsRef.current,
                ...updatedAddedFabricTextObjects
              });
              addedFabricTextObjectsRef.current = {
                ...addedFabricTextObjectsRef.current,
                ...updatedAddedFabricTextObjects
              };
            });
        });
      }, 100);
    });
  }

  function onAddTextPositionStart({ adding, object, reference, textSize }: AddTextInterface) {
    const textEditorObj = object as any;
    if (textEditorObj.name) {
      let getFieldCode = '3D_TEXT';
      if (adding === FunctionTab.Number) {
        getFieldCode = '3D_NUMMER';
      } else if (adding === FunctionTab.Teamname) {
        getFieldCode = '3D_VEREINSNAME';
      } else if (adding === FunctionTab.Initial) {
        getFieldCode = '3D_INITIALEN';
      } else if (adding === FunctionTab.Name) {
        getFieldCode = '3D_NAME';
      }

      if (!configuratorRef.current) {
        return;
      }

      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;

      const onlyMainAreaKeys = Object.keys(designSvgPatternObjectsBounding);
      const activeObjTop = textEditorObj.aCoords ? textEditorObj.aCoords.tl.y : 0;
      const activeObjWidth = textEditorObj.aCoords ? textEditorObj.aCoords.tr.x - textEditorObj.aCoords.tl.x : 0;
      const activeObjLeft = textEditorObj.aCoords ? textEditorObj.aCoords.tl.x : 0;
      const activeObjHeight = textEditorObj.aCoords ? textEditorObj.aCoords.bl.y - textEditorObj.aCoords.tl.y : 0;
      const foundedIntersect = onlyMainAreaKeys.find((key) => {
        const sectionObject = designSvgPatternObjectsBounding[key];
        const isOverflowX = ((activeObjWidth + activeObjLeft) > (sectionObject.pathOffset.x + (isMobile ? sectionObject.width / 4 : sectionObject.width) / 2)) || ((activeObjWidth + activeObjLeft) < (sectionObject.pathOffset.x - (isMobile ? sectionObject.width / 4 : sectionObject.width) / 2));
        const isOverflowY = ((activeObjHeight + activeObjTop) > (sectionObject.pathOffset.y + (isMobile ? sectionObject.height / 4 : sectionObject.height) / 2)) || ((activeObjHeight + activeObjTop) < (sectionObject.pathOffset.y - (isMobile ? sectionObject.height / 4 : sectionObject.height) / 2));
        return !isOverflowX && !isOverflowY;
      });
      let foundedPositionPart = 'front';
      if (foundedIntersect) {
        foundedPositionPart = foundedIntersect;
      }

      addedFabricTextObjectsRef.current = {
        ...addedFabricTextObjectsRef.current,
        [textEditorObj.name]: {
          name: textEditorObj.name,
          text: textEditorObj.text,
          width: textEditorObj.width,
          height: textEditorObj.height,
          x: textEditorObj.left,
          y: textEditorObj.top,
          fontFamily: textEditorObj.fontFamily,
          fill: textEditorObj.fill,
          fontSize: textEditorObj.fontSize,
          rotation: textEditorObj.angle,
          fieldCode: getFieldCode,
          functionTab: adding,
          foundedPositionPart: foundedPositionPart || 'front',
          initialX: textEditorObj.left,
          initialY: textEditorObj.top,
          reference: reference,
          maxHeight: textSize.maxHeight,
          scaledHeight: textSize.scaledHeight,
          scaledWidth: textSize.scaledWidth
        }
      };
      setAddedFabricTextObjects((prevState) => ({
        ...prevState,
        [textEditorObj.name]: {
          name: textEditorObj.name,
          text: textEditorObj.text,
          width: textEditorObj.width,
          height: textEditorObj.height,
          x: textEditorObj.left,
          y: textEditorObj.top,
          fontFamily: textEditorObj.fontFamily,
          fill: textEditorObj.fill,
          fontSize: textEditorObj.fontSize,
          rotation: textEditorObj.angle,
          fieldCode: getFieldCode,
          functionTab: adding,
          foundedPositionPart: foundedPositionPart || 'front',
          initialX: textEditorObj.left,
          initialY: textEditorObj.top,
          reference: reference,
          textSize: textSize,
          maxHeight: textSize.maxHeight,
          scaledHeight: textSize.scaledHeight,
          scaledWidth: textSize.scaledWidth
        }
      }));
    }
    setIsAddingState(null);
    onSelectText(textEditorObj.name, true);
  }

  function onUpdateTextFontSize(name: string, data: {
    width: number,
    height: number,
  }) {
    addedFabricTextObjectsRef.current = {
      ...addedFabricTextObjectsRef.current,
      [name]: {
        ...addedFabricTextObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    };
    setAddedFabricTextObjects({
      ...addedFabricTextObjectsRef.current,
      [name]: {
        ...addedFabricTextObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    });
  }

  function onUpdateLogoSize(name: string, data: {
    width: number,
    height: number
  }) {
    addedFabricLogoObjectsRef.current = {
      ...addedFabricLogoObjectsRef.current,
      [name]: {
        ...addedFabricLogoObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    };
    setAddedFabricLogoObjects({
      ...addedFabricLogoObjectsRef.current,
      [name]: {
        ...addedFabricLogoObjectsRef.current[name],
        ...data,
        scaledWidth: data.width,
        scaledHeight: data.height,
      }
    });
  }

  function rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  function onAddImagePositionStart({ object, imageObj, logoSize }: AddImageInterface) {
    const logoEditorObj = object as any;
    if (logoEditorObj.name && configuratorRef.current) {
      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;
      const foundedPositionPart = Object.keys(designSvgPatternObjectsBounding).find((itemKey) => {
        const partWidth = designSvgPatternObjectsBounding[itemKey].width;
        const partHeight = designSvgPatternObjectsBounding[itemKey].height;
        const centerX = designSvgPatternObjectsBounding[itemKey].pathOffset.x;
        const centerY = designSvgPatternObjectsBounding[itemKey].pathOffset.y;

        const leftEdgeX = Math.abs(centerX - (partWidth / 2));
        const rightEdgeX = centerX + (partWidth / 2);
        const topEdgeY = Math.abs(centerY - (partHeight / 2));
        const bottomEdgeY = centerY + (partHeight / 2);
        return logoEditorObj.left >= leftEdgeX
          && logoEditorObj.left <= rightEdgeX
          && logoEditorObj.top >= topEdgeY
          && logoEditorObj.top <= bottomEdgeY;
      });

      const getRgbAndHex: {
        colorHexCode: string,
        colorInfoText: string
      }[] = logoEditorObj._objects
        ? logoEditorObj._objects.slice().map((item: any) => {
          if (item.fill) {
            if (item.fill.toString().charAt(0) === '#') {
              return {
                colorHexCode: item.fill,
                colorInfoText: item.id,
              };
            } else if (item.fill && item.fill.toString().includes('rgb')) {
              const rgbArray = item.fill.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
              return {
                colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])),
                colorInfoText: item.id,
              };
            } else {
              return {
                colorHexCode: item.fill,
                colorInfoText: item.id,
              };
            }
          } else if (item.stroke) {
            if (item.stroke.toString().charAt(0) === '#') {
              return {
                colorHexCode: item.stroke,
                colorInfoText: item.id,
              };
            } else if (item.stroke.toString().includes('rgb')) {
              const rgbArray = item.stroke.replace(/\s/g, '').split('(')[1].split(')')[0].split(',');
              return {
                colorHexCode: rgbToHex(parseInt(rgbArray[0]), parseInt(rgbArray[1]), parseInt(rgbArray[2])),
                colorInfoText: item.id,
              };
            } else {
              return {
                colorHexCode: item.stroke,
                colorInfoText: item.id,
              };
            }
          } else {
            return {
              colorHexCode: '',
              colorInfoText: '',
            };
          }
        })
        : [{
          colorHexCode: '',
          colorInfoText: ''
        }];

      addedFabricLogoObjectsRef.current = {
        ...addedFabricLogoObjectsRef.current,
        [logoEditorObj.name]: {
          // position,
          name: object.name,
          fileName: imageObj.fileName,
          src: imageObj.src,
          x: object.left,
          y: object.top,
          width: object.getScaledWidth(),
          height: object.getScaledHeight(),
          rotation: object.angle,
          fieldCode: '3D_LOGO',
          foundedPositionPart: foundedPositionPart || 'front',
          isUnprocessable: imageObj.isUnprocessable,
          initialX: object.left,
          initialY: object.top,
          fill: object.fill,
          possibleImageColors: getRgbAndHex,
          reference: imageObj.reference,
          maxHeight: logoSize.maxHeight,
          scaledHeight: logoSize.scaledHeight,
          scaledWidth: logoSize.scaledWidth
        }
      };
      setAddedFabricLogoObjects((prevState) => ({
        ...prevState,
        [logoEditorObj.name]: {
          // position,
          name: object.name,
          fileName: imageObj.fileName,
          src: imageObj.src,
          x: object.left,
          y: object.top,
          width: object.getScaledWidth(),
          height: object.getScaledHeight(),
          rotation: object.angle,
          fieldCode: '3D_LOGO',
          foundedPositionPart: foundedPositionPart || 'front',
          isUnprocessable: imageObj.isUnprocessable,
          initialX: object.left,
          initialY: object.top,
          fill: object.fill,
          possibleImageColors: getRgbAndHex,
          reference: imageObj.reference,
          maxHeight: logoSize.maxHeight,
          scaledHeight: logoSize.scaledHeight,
          scaledWidth: logoSize.scaledWidth
        }
      }));
    }
    setIsAddingState(null);
    onSelectLogo(logoEditorObj.name);
  }

  function onAddImageLogoInit(objects: any[]) {
    let updatedAddedFabricLogoObjects: AddedLogoItems = {
      ...addedFabricLogoObjectsRef.current,
    };
    objects.forEach((obj) => {
      updatedAddedFabricLogoObjects = {
        ...updatedAddedFabricLogoObjects,
        [obj.name]: {
          ...updatedAddedFabricLogoObjects[obj.name],
          src: obj.src
        }
      };
    });
    setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
  }

  function onClickTextLock(eKey: string) {
    const updatedAddedFabricTextObjects: AddedTextItems = {
      ...addedFabricTextObjects,
      [eKey]: {
        ...addedFabricTextObjects[eKey],
        isLock: !addedFabricTextObjects[eKey].isLock
      }
    };

    setAddedFabricTextObjects(updatedAddedFabricTextObjects);
    addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    setSelectedAddedTextKey(eKey);

    if (addedFabricTextObjectsRef.current[eKey].isLock) {
      configuratorRef.current?.lockObject(eKey);
    } else {
      configuratorRef.current?.unlockObject(eKey);
    }
  }
  function onClickDeleteText(eKey: string) {
    setSelectedAddedTextKey(null);
    configuratorRef.current?.deleteObject(eKey);
    onTextTabBack();

    setTimeout(() => {
      const updatedAddedFabricTextObjects = {...addedFabricTextObjects};
      delete updatedAddedFabricTextObjects[eKey];
      setAddedFabricTextObjects(updatedAddedFabricTextObjects);
      addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    }, 100);

  }
  function onSelectText(eKey: string | null, init?: boolean) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    if (eKey === null) {
      setSelectedAddedTextKey(eKey);
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }

    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }
      if (!addedFabricTextObjectsRef.current[eKey]) {
        return;
      }

      const fabricObjects = configuratorRef.current.fabricCanvas.current._objects;
      const foundedSelectedText = fabricObjects.find((item) => item.name === eKey);
      if (!foundedSelectedText) {
        return;
      }

      if (ModelStyle.Shirt === selectModelStyle) {
        if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('back')) {
          moveCameraToBackSide();
        }
        // front
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('front')) {
          moveCameraToFrontSide();
        }
        // right
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSide();
        }
        // left
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSide();
        }
      } else {
        if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSideShort();
        }
        else if (addedFabricTextObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSideShort();
        }
      }
      setSelectedAddedTextKey(eKey);
      configuratorRef.current?.setActiveObjectHandler(foundedSelectedText);
      // if (init) {
      //   onChangeFontFamily(eKey, selectedFontFamily);
      // }
    }, 100);
  }
  function onSelectTextFromFabric(eKey: string | null, functionTab: FunctionTab) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    if (eKey === null) {
      setSelectedAddedTextKey(eKey);
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }
    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }
      setSelectedAddedTextKey(eKey);
      setTimeout(() => {
        if (functionTab === FunctionTab.Teamname) {
          jakoTeamnameTabRef.current?.openOptionByName(eKey);
        } else if (functionTab === FunctionTab.Number) {
          jakoNumberTabRef.current?.openOptionByName(eKey);
        } else if (functionTab === FunctionTab.Name || functionTab === FunctionTab.Initial) {
          jakoNameTabRef.current?.openOptionByName(eKey);
        } else if (functionTab === FunctionTab.Text) {
          jakoTextTabRef.current?.openOptionByName(eKey);
        }
      }, 100);
    }, 100);
  }
  function onChangeTextColor(color: string) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const updatedAddedFabricTextObjects: AddedTextItems = {
      ...addedFabricTextObjects,
      [selectedAddedTextKey]: {
        ...addedFabricTextObjects[selectedAddedTextKey],
        fill: color
      }
    };

    setAddedFabricTextObjects(updatedAddedFabricTextObjects);
    addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
    setSelectedTextFill(color);

    configuratorRef.current?.updateTextColor(selectedAddedTextKey, color);
  }
  function onChangeFontFamily(name: string, font: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const textValue = addedFabricTextObjectsRef.current[name].text;
    JfnetServices.fontToSvg({
      text: textValue,
      font: font
    }).then((res) => {
      setTimeout(() => {
        configuratorRef.current?.editTextHandler({
          textName: name,
          text: textValue,
          svgData: res.data,
          fontFamily: font,
        }).then((data) => {
          const updatedAddedFabricTextObjects: AddedTextItems = {
            ...addedFabricTextObjectsRef.current,
            [name]: {
              ...addedFabricTextObjectsRef.current[name],
              fontFamily: font,
              reference: data.reference,
              scaledHeight: data.scaledHeight,
              scaledWidth: data.scaledWidth
            }
          };
          addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
          setAddedFabricTextObjects(updatedAddedFabricTextObjects);
          setSelectedFontFamily(font);
          configuratorRef.current?.updateTextFontFamily(name, font);
        });
      }, 100);
    });
  }

  function onChangeFontSizeWidth(name: string, width: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.updateTextFontSizeWidth(name, width);
  }

  function onChangeFontSizeHeight(name: string, height: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    configuratorRef.current?.updateTextFontSizeHeight(name, height);
  }

  function onSendToFront(name: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.bringToFront(name);
  }

  function onSendToBack(name: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.sendToBack(name);
  }

  function onChangeLogoPosition(name: string, position: string, isInit?: boolean) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    let fabricFrontObjCenterX = 0,
    fabricFrontObjCenterY = 0,
    fabricBackObjCenterX = 0,
    fabricBackObjCenterY = 0,
    fabricLeftObjCenterX = 0,
    fabricLeftObjCenterY = 0,
    fabricRightObjCenterX = 0,
    fabricRightObjCenterY = 0;

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.front_main) {
      fabricFrontObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
      fabricFrontObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.back_main) {
      fabricBackObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
      fabricBackObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.left_main) {
      fabricLeftObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
      fabricLeftObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.right_main) {
      fabricRightObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.x);
      fabricRightObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
    }

    const logoObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as any;

    const logoPosition = position as LogoAddPosition;

    let axis: {
      left: number,
      top: number
    } = {
      left: 0,
      top: 0
    };

    let maxHeight = MAX_LOGO_SIZE_HEIGHT_CM;
    let startHeight = 4.5;
    const ratioValue = 1360;
    const ratioBackObj = (ratioValue - (ratioValue * (ratioValue / fabricBackObjCenterY)));

    if (position === LogoAddPosition.Neck) {
      axis = {
        left: fabricBackObjCenterX,
        top: fabricBackObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.Neck].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.BackBottomFull) {
      axis = {
        left: fabricBackObjCenterX,
        top: fabricBackObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.BackBottomFull].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.BackMiddleFull) {
      axis = {
        left: fabricBackObjCenterX,
        top: fabricBackObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.BackMiddleFull].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.BackTopFull) {
      axis = {
        left: fabricBackObjCenterX,
        top: fabricBackObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.BackTopFull].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.FrontBottomFull) {
      axis = {
        left: fabricFrontObjCenterX,
        top: fabricFrontObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.FrontBottomFull].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.FrontTopCenter) {
      axis = {
        left: fabricFrontObjCenterX,
        top: logoObj.pathOffset.y,
      };
    } else if (position === LogoAddPosition.FrontTopLeft) {
      axis = {
        left: fabricFrontObjCenterX + LOGO_ADD_POSITION[LogoAddPosition.FrontTopLeft].x,
        top: logoObj.pathOffset.y,
      };
    } else if (position === LogoAddPosition.FrontUnderLogoFull) {
      axis = {
        left: fabricFrontObjCenterX + LOGO_ADD_POSITION[LogoAddPosition.FrontUnderLogoFull].x,
        top: fabricFrontObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.FrontUnderLogoFull].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.FrontMiddleFull) {
      axis = {
        left: fabricFrontObjCenterX,
        top: fabricFrontObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.FrontMiddleFull].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.RightArm) {
      axis = {
        left: fabricRightObjCenterX,
        top: fabricRightObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.RightArm].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.LeftArm) {
      axis = {
        left: fabricLeftObjCenterX,
        top: fabricLeftObjCenterY + LOGO_ADD_POSITION[LogoAddPosition.LeftArm].y + ratioBackObj,
      };
    } else if (position === LogoAddPosition.ShortBottomLeft) {
      axis.left = logoObj.pathOffset.x;
      axis.top = logoObj.pathOffset.y - 95;
    } else if (position === LogoAddPosition.ShortBottomRight) {
      axis.left = LOGO_ADD_POSITION[LogoAddPosition.ShortBottomRight].x;
      axis.top = logoObj.pathOffset.y - 95;
    } else if (position === LogoAddPosition.ShortUpLeft) {
      axis.left = logoObj.pathOffset.x;
      axis.top = logoObj.pathOffset.y - 195;
    } else if (position === LogoAddPosition.ShortUpRight) {
      axis.left = LOGO_ADD_POSITION[LogoAddPosition.ShortUpRight].x;
      axis.top = logoObj.pathOffset.y - 195;
    }
    addedFabricLogoObjectsRef.current = {
      ...addedFabricLogoObjectsRef.current,
      [name]: {
        ...addedFabricLogoObjectsRef.current[name],
        position: logoPosition,
        initialX: axis.left,
        initialY: axis.top,
        maxHeight,
        scaledHeight: startHeight
      }
    };
    setAddedFabricLogoObjects({
      ...addedFabricLogoObjectsRef.current,
      [name]: {
        ...addedFabricLogoObjectsRef.current[name],
        position: logoPosition,
        initialX: axis.left,
        initialY: axis.top,
        maxHeight,
        scaledHeight: startHeight
      }
    });

    const selectedObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.name === name) as any;

    if (isInit) {
      configuratorRef.current?.updateLogoSizeHeight(name, startHeight * CM_FACTOR);

      setTimeout(() => {
        if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
          if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
            axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
          } else {
            axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
          }
        }
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 200);
    } else {
      if (position === LogoAddPosition.ShortBottomLeft || position === LogoAddPosition.ShortUpLeft) {
        if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
          axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
        } else {
          axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
        }
      }
      const addedPosition = position as LogoAddPosition;
      let updatedAddedFabricLogoObjects: AddedLogoItems = {
        ...addedFabricLogoObjectsRef.current,
        [name]: {
          ...addedFabricLogoObjectsRef.current[name],
          position: addedPosition,
          initialX: axis.left,
          initialY: axis.top,
          maxHeight: maxHeight,
        }
      };
      updatedAddedFabricLogoObjects = {
        ...updatedAddedFabricLogoObjects,
        [name]: {
          ...updatedAddedFabricLogoObjects[name],
          fill: colorInvertedBgRef.current,
        }
      };
      setTimeout(() => {
        addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
        setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 200);
    }
  }

  function onChangeTextPosition(name: string, position: string, section?: JerseySection, isInit?: boolean) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    let fabricFrontObjCenterX = 0,
    fabricFrontObjCenterY = 0,
    fabricBackObjCenterX = 0,
    fabricBackObjCenterY = 0,
    fabricLeftObjCenterX = 0,
    fabricLeftObjCenterY = 0,
    fabricRightObjCenterX = 0,
    fabricRightObjCenterY = 0;

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.front_main) {
      fabricFrontObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.x);
      fabricFrontObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.front_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.back_main) {
      fabricBackObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.x);
      fabricBackObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.back_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.left_main) {
      fabricLeftObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.x);
      fabricLeftObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.left_main.pathOffset.y);
    }

    if (configuratorRef.current.designSvgPatternObjectsBounding.current.right_main) {
      fabricRightObjCenterX = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.x);
      fabricRightObjCenterY = Math.round(configuratorRef.current.designSvgPatternObjectsBounding.current.right_main.pathOffset.y);
    }

    const logoObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.id === 'logo_front') as any;
    const selectedObj = configuratorRef.current.fabricCanvas.current._objects.find((obj: any) => obj.name === name) as any;
    const selectedTab = parseInt(name.charAt(3));

    let axis: {
      left: number,
      top: number
    } = {
      left: 0,
      top: 0
    };

    let maxHeight = MAX_TEXT_SIZE_HEIGHT_CM;
    let startHeight = 4.5;
    const ratioValue = 1360;
    const ratioBackObj = (ratioValue - (ratioValue * (ratioValue / fabricBackObjCenterY)));

    if (selectedTab === FunctionTab.Teamname) {
      startHeight = 2.5;
      if (position === TeamnameAddPosition.BackBottomFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + TEAMNAME_ADD_POSITION[TeamnameAddPosition.BackBottomFull].y + ratioBackObj;
      } else if (position === TeamnameAddPosition.BackTopFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + TEAMNAME_ADD_POSITION[TeamnameAddPosition.BackTopFull].y + ratioBackObj;
      } else if (position === TeamnameAddPosition.ShortBottomLeft) {
        axis.left = logoObj.pathOffset.x - (selectedObj ? selectedObj.width / 2 : 0);
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === TeamnameAddPosition.ShortBottomRight) {
        axis.left = TEAMNAME_ADD_POSITION[TeamnameAddPosition.ShortBottomRight].x;
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === TeamnameAddPosition.ShortUpLeft) {
        axis.left = logoObj.pathOffset.x - (selectedObj ? selectedObj.width / 2 : 0);
        axis.top = logoObj.pathOffset.y - 195;
      } else if (position === TeamnameAddPosition.ShortUpRight) {
        axis.left = TEAMNAME_ADD_POSITION[TeamnameAddPosition.ShortUpRight].x;
        axis.top = logoObj.pathOffset.y - 195;
      }
    }

    if (selectedTab === FunctionTab.Number) {
      startHeight = 5;
      if (position === NumberAddPosition.FrontTopCenter) {
        axis.left = fabricFrontObjCenterX;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NumberAddPosition.FrontTopLeft) {
        axis.left = fabricFrontObjCenterX + NUMBER_ADD_POSITION[NumberAddPosition.FrontTopLeft].x;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NumberAddPosition.BackMiddleFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + NUMBER_ADD_POSITION[NumberAddPosition.BackMiddleFull].y;
        startHeight = 16.5;
      } else if (position === NumberAddPosition.LeftArm) {
        axis.left = fabricLeftObjCenterX;
        axis.top = fabricLeftObjCenterY + NUMBER_ADD_POSITION[NumberAddPosition.LeftArm].y + ratioBackObj;
      } else if (position === NumberAddPosition.RightArm) {
        axis.left = fabricRightObjCenterX;
        axis.top = fabricRightObjCenterY + NUMBER_ADD_POSITION[NumberAddPosition.RightArm].y + ratioBackObj;
      } else if (position === NumberAddPosition.ShortBottomLeft) {
        axis.left = logoObj.pathOffset.x - (selectedObj ? selectedObj.width / 2 : 0);
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === NumberAddPosition.ShortBottomRight) {
        axis.left = NUMBER_ADD_POSITION[NumberAddPosition.ShortBottomRight].x;
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === NumberAddPosition.ShortUpLeft) {
        axis.left = logoObj.pathOffset.x - (selectedObj ? selectedObj.width / 2 : 0);
        axis.top = logoObj.pathOffset.y - 195;
      } else if (position === NumberAddPosition.ShortUpRight) {
        axis.left = NUMBER_ADD_POSITION[NumberAddPosition.ShortUpRight].x;
        axis.top = logoObj.pathOffset.y - 195;
      }
    }

    if (selectedTab === FunctionTab.Name || selectedTab === FunctionTab.Initial) {
      if (position === NameInitialAddPosition.FrontTopCenter) {
        axis.left = fabricFrontObjCenterX;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NameInitialAddPosition.FrontTopLeft) {
        axis.left = fabricFrontObjCenterX + INITIAL_ADD_POSITION[NameInitialAddPosition.FrontTopLeft].x;
        axis.top = logoObj.pathOffset.y;
      } else if (position === NameInitialAddPosition.RightArm) {
        axis.left = fabricRightObjCenterX;
        axis.top = fabricRightObjCenterY + INITIAL_ADD_POSITION[NameInitialAddPosition.RightArm].y + ratioBackObj;
      } else if (position === NameInitialAddPosition.LeftArm) {
        axis.left = fabricLeftObjCenterX;
        axis.top = fabricLeftObjCenterY + INITIAL_ADD_POSITION[NameInitialAddPosition.LeftArm].y + ratioBackObj;
      } else if (position === NameInitialAddPosition.FrontMiddleFull) {
        axis.left = fabricFrontObjCenterX;
        axis.top = fabricFrontObjCenterY;
      } else if (position === NameInitialAddPosition.BackSuperBottomFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + NAME_ADD_POSITION[NameInitialAddPosition.BackSuperBottomFull].y + ratioBackObj;
      } else if (position === NameInitialAddPosition.BackTopFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + NAME_ADD_POSITION[NameInitialAddPosition.BackTopFull].y + ratioBackObj;
      } else if (position === NameInitialAddPosition.ShortBottomLeft) {
        axis.left = logoObj.pathOffset.x + (selectedObj ? selectedObj.getScaledWidth() / 2 : 0);
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === NameInitialAddPosition.ShortBottomRight) {
        axis.left = NAME_ADD_POSITION[NameInitialAddPosition.ShortBottomRight].x;
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === NameInitialAddPosition.ShortUpLeft) {
        axis.left = logoObj.pathOffset.x + (selectedObj ? selectedObj.getScaledWidth() / 2 : 0);
        axis.top = logoObj.pathOffset.y - 195;
      } else if (position === NameInitialAddPosition.ShortUpRight) {
        axis.left = NAME_ADD_POSITION[NameInitialAddPosition.ShortUpRight].x;
        axis.top = logoObj.pathOffset.y - 195;
      }
    }

    if (selectedTab === FunctionTab.Text) {
      if (position === TextAddPosition.FrontBottomFull) {
        axis.left = fabricFrontObjCenterX;
        axis.top = fabricFrontObjCenterY + TEXT_ADD_POSITION[TextAddPosition.FrontBottomFull].y + ratioBackObj;
      } else if (position === TextAddPosition.FrontUnderLogoFull) {
        axis.left = fabricFrontObjCenterX;
        axis.top = fabricFrontObjCenterY + TEXT_ADD_POSITION[TextAddPosition.FrontUnderLogoFull].y + ratioBackObj;
      } else if (position === TextAddPosition.BackTopFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + TEXT_ADD_POSITION[TextAddPosition.BackTopFull].y + ratioBackObj;
      } else if (position === TextAddPosition.BackBottomFull) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + TEXT_ADD_POSITION[TextAddPosition.BackBottomFull].y + ratioBackObj;
      } else if (position === TextAddPosition.LeftArm) {
        axis.left = fabricLeftObjCenterX;
        axis.top = fabricLeftObjCenterY + TEXT_ADD_POSITION[TextAddPosition.LeftArm].y + ratioBackObj;
      } else if (position === TextAddPosition.RightArm) {
        axis.left = fabricRightObjCenterX;
        axis.top = fabricRightObjCenterY + TEXT_ADD_POSITION[TextAddPosition.RightArm].y + ratioBackObj;
      } else if (position === TextAddPosition.Neck) {
        axis.left = fabricBackObjCenterX;
        axis.top = fabricBackObjCenterY + TEXT_ADD_POSITION[TextAddPosition.Neck].y + ratioBackObj;
      } else if (position === TextAddPosition.ShortBottomLeft) {
        axis.left = logoObj.pathOffset.x;
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === TextAddPosition.ShortBottomRight) {
        axis.left = TEXT_ADD_POSITION[TextAddPosition.ShortBottomRight].x;
        axis.top = logoObj.pathOffset.y - 95;
      } else if (position === TextAddPosition.ShortUpLeft) {
        axis.left = logoObj.pathOffset.x;
        axis.top = logoObj.pathOffset.y - 195;
      } else if (position === TextAddPosition.ShortUpRight) {
        axis.left = LOGO_ADD_POSITION[TextAddPosition.ShortUpRight].x;
        axis.top = logoObj.pathOffset.y - 195;
      }
    }

    if (section) {
      const positionColor = selectedJerseySectionColor[section];
      const foundedColorFromList = colorList.find((colorItem) => positionColor.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorInvertedBgRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
    }

    const addedPosition = position as TeamnameAddPosition | NumberAddPosition | NameInitialAddPosition | TextAddPosition;

    let updatedAddedFabricTextObjects: AddedTextItems = {
      ...addedFabricTextObjectsRef.current,
      [name]: {
        ...addedFabricTextObjectsRef.current[name],
        position: addedPosition,
        initialX: axis.left,
        initialY: axis.top,
        maxHeight: maxHeight,
        scaledHeight: startHeight
      }
    };

    if (isInit) {
      updatedAddedFabricTextObjects = {
        ...updatedAddedFabricTextObjects,
        [name]: {
          ...updatedAddedFabricTextObjects[name],
          fill: selectedTextFill ? selectedTextFill : colorInvertedBgRef.current,
        }
      };

      configuratorRef.current?.updateTextColor(name, selectedTextFill ? selectedTextFill : colorInvertedBgRef.current);
      configuratorRef.current?.updateTextFontSizeHeight(name, startHeight * CM_FACTOR);
      setTimeout(() => {
        if (position === NameInitialAddPosition.ShortBottomLeft || position === NameInitialAddPosition.ShortUpLeft) {
          if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
            axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
          } else {
            axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
          }
        }
        addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
        setAddedFabricTextObjects(updatedAddedFabricTextObjects);
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 200);
    } else {
      addedFabricTextObjectsRef.current = updatedAddedFabricTextObjects;
      setAddedFabricTextObjects(updatedAddedFabricTextObjects);
      if (position === NameInitialAddPosition.ShortBottomLeft || position === NameInitialAddPosition.ShortUpLeft) {
        if (logoObj.getScaledWidth() < selectedObj.getScaledWidth()) {
          axis.left = logoObj.pathOffset.x - (selectedObj.getScaledWidth() / 2 - logoObj.getScaledWidth() / 2);
        } else {
          axis.left = logoObj.aCoords.tr.x - selectedObj.getScaledWidth() / 2;
        }
      }
      setTimeout(() => {
        configuratorRef.current?.updateObjectPosition(position, name, axis);
      }, 200);
    }

  }

  function resetDefaultPositionText() {
    if (!selectedAddedTextKey) {
      return;
    }
    configuratorRef.current?.resetDefaultPosition(selectedAddedTextKey, addedFabricTextObjects[selectedAddedTextKey].initialX, addedFabricTextObjects[selectedAddedTextKey].initialY);
  }

  function resetDefaultPositionLogo() {
    if (!selectedAddedLogoKey) {
      return;
    }
    configuratorRef.current?.resetDefaultPosition(selectedAddedLogoKey, addedFabricLogoObjects[selectedAddedLogoKey].initialX, addedFabricLogoObjects[selectedAddedLogoKey].initialY);
  }

  function onChangeLogoWidthSize(name: string, width: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.updateLogoSizeWidth(name, width);
  }

  function onChangeLogoHeightSize(name: string, height: number) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    configuratorRef.current?.updateLogoSizeHeight(name, height);
  }
  function onChangeLogoColor(color: string) {
    if (!selectedAddedLogoKey) {
      return;
    }
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const updatedAddedFabricLogoObjects: AddedLogoItems = {
      ...addedFabricLogoObjects,
      [selectedAddedLogoKey]: {
        ...addedFabricLogoObjects[selectedAddedLogoKey],
        fill: color
      }
    };

    setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;

    configuratorRef.current?.updateLogoColor(selectedAddedLogoKey, color);
  }
  function onSelectLogo(eKey: string | null) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    if (eKey === null) {
      setSelectedAddedLogoKey(null);
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }

    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }

      if (ModelStyle.Shirt === selectModelStyle) {
        if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('back')) {
          moveCameraToBackSide();
        }
        // front
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('front')) {
          moveCameraToFrontSide();
        }
        // right
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSide();
        }
        // left
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSide();
        }
      } else {
        // right
        if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('left')) {
          moveCameraToLeftSideShort();
        }
        // left
        else if (addedFabricLogoObjectsRef.current[eKey] && addedFabricLogoObjectsRef.current[eKey].foundedPositionPart.toString().toLowerCase().includes('right')) {
          moveCameraToRightSideShort();
        }
      }
      setSelectedAddedLogoKey(eKey);
      const fabricObjects = configuratorRef.current.fabricCanvas.current._objects;
      const foundedSelectedLogo = fabricObjects.find((item) => item.name === eKey) as fabric.Object;
      if (foundedSelectedLogo) {
        configuratorRef.current?.setActiveObjectHandler(foundedSelectedLogo);
      }
    }, 100);
  }

  function onSelectLogoFromFabric(eKey: string | null) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    if (eKey === null) {
      setSelectedAddedLogoKey(eKey);
      configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
      return;
    }

    setTimeout(() => {
      if (!configuratorRef.current) {
        return;
      }
      if (!configuratorRef.current.fabricCanvas.current) {
        return;
      }
      setSelectedAddedLogoKey(eKey);
      setTimeout(() => {
        jakoLogoTabRef.current?.openLogoOptionByName(eKey);
      }, 100);
    }, 100);
  }
  function onClickLogoLock(eKey: string) {
    const updatedAddedFabricLogoObjects: AddedLogoItems = {
      ...addedFabricLogoObjects,
      [eKey]: {
        ...addedFabricLogoObjects[eKey],
        isLock: !addedFabricLogoObjects[eKey].isLock
      }
    };
    setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
    addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
    setSelectedAddedLogoKey(eKey);

    if (addedFabricLogoObjectsRef.current[eKey].isLock) {
      configuratorRef.current?.lockObject(eKey);
    } else {
      configuratorRef.current?.unlockObject(eKey);
    }
  }
  function onClickDeleteLogo(eKey: string) {
    configuratorRef.current?.deleteObject(eKey);
    onLogoTabBack();

    setTimeout(() => {
      const updatedAddedFabricLogoObjects = {...addedFabricLogoObjects};
      delete updatedAddedFabricLogoObjects[eKey];
      setAddedFabricLogoObjects(updatedAddedFabricLogoObjects);
      addedFabricLogoObjectsRef.current = updatedAddedFabricLogoObjects;
    }, 100);
  }

  function onConfiguratorEnableControl() {
    if (cameraControlsRef.current && !cameraControlsRef.current.enabled) {
      cameraControlsRef.current.enabled = true;
    }
  }
  function onConfiguratorDisableControl() {
    if (cameraControlsRef.current && cameraControlsRef.current.enabled) {
      cameraControlsRef.current.enabled = false;
    }
  }

  function onSetTabName(tab: FunctionTab) {
    setSelectTab(tab);
  }

  function onSetSelectTabHandler(tab: FunctionTab, index: number) {
    if (tab === FunctionTab.Pattern && patternList.length === 0) {
      setIsLoading(true);
      const correlationId = contextCorrelationId  || '111';
      const company = contextCompany || 'JAKO AG';
      const languageCode = selectedLang.current.name || contextLanguageCode;
      JakoServices.getPatterns({
        correlationId,
        company,
        languageCode
      }).then((response) => {
          const patternResponse = response.data as PatternResponseItem[];
          setPatternList(patternResponse);
          onSelectText(null);
          setSelectTab(tab);
          setSelectTabIndex(index);
          setIsLoading(false);
          setTimeout(() => {
            setShowScrollDownSidebarHandler();
            setSidebarOnScrollHandler();
          }, 100);
        });
    } else {
      onSelectText(null);
      setSelectedAddedLogoKey(null);
      setSelectTab(tab);
      setSelectTabIndex(index);
      setTimeout(() => {
        setShowScrollDownSidebarHandler();
        setSidebarOnScrollHandler();
      }, 100);
    }
  }

  function setSelectTabBack() {
    let tabIndex = selectTabIndex;
    if (tabIndex === 0) {
      tabIndex = menuTabFiltered.length - 1;
    } else {
      tabIndex = selectTabIndex - 1;
    }
    setSelectTabIndex(tabIndex);
    setSelectTab(menuTabFiltered[tabIndex].functionTab);
  }
  function setSelectTabNext() {
    let tabIndex = selectTabIndex;
    if (tabIndex === menuTabFiltered.length - 1) {
      tabIndex = 0;
    } else {
      tabIndex = selectTabIndex + 1;
    }
    setSelectTabIndex(tabIndex);
    setSelectTab(menuTabFiltered[tabIndex].functionTab);
  }
  function onSelectDesignStart(design: DesignOption, saveColors: any, savePatterns: any) {
    setSelectedDesign(design);
    if (design.name?.toString().toLowerCase().includes('blanko')) {
      let filteredColorResult = {};
      Object.keys(saveColors).forEach((key) => {
        if (!key.toString().includes('design-')) {
          filteredColorResult = {
            ...filteredColorResult,
            [key]: saveColors[key]
          };
        }
      });
      setSelectedJerseySectionColor(filteredColorResult);

      let filteredPatternResult = {};
      Object.keys(savePatterns).forEach((key) => {
        if (!key.toString().includes('design-')) {
          filteredPatternResult = {
            ...filteredPatternResult,
            [key]: savePatterns[key]
          };
        }
      });
      setSelectedJerseySectionPattern(filteredPatternResult);
    }
  }
  function onSelectDesign(design: DesignOption) {
    setTimeout(() => {
      if (design.name?.toString().toLowerCase().includes('blanko')) {
        let filteredColorResult = {};
        Object.keys(selectedJerseySectionColor).forEach((key) => {
          if (!key.toString().includes('design-')) {
            filteredColorResult = {
              ...filteredColorResult,
              [key]: selectedJerseySectionColor[key]
            };
          }
        });
        setSelectedJerseySectionColor(filteredColorResult);

        let filteredPatternResult = {};
        Object.keys(selectedJerseySectionPattern).forEach((key) => {
          if (!key.toString().includes('design-')) {
            filteredPatternResult = {
              ...filteredPatternResult,
              [key]: selectedJerseySectionPattern[key]
            };
          }
        });
        setSelectedJerseySectionPattern(filteredPatternResult);
      }
      setSelectedDesign(design);
      handleOnCloseMobile();
    }, 300);
  }
  function onSelectCustomDesign(design: CustomDesignResponseItem) {
    setIsLoading(true);
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }

    const fabricCanvas = configuratorRef.current.fabricCanvas.current;
    if (!fabricCanvas) {
      return;
    }

    Object.keys(addedFabricTextObjectsRef.current).forEach((key) => {
      const foundedObj = fabricCanvas._objects.find((obj) => obj.name === key) as any;
      if (foundedObj) {
        fabricCanvas.remove(foundedObj);
      }
    });
    Object.keys(addedFabricLogoObjectsRef.current).forEach((key) => {
      const foundedObj = fabricCanvas._objects.find((obj) => obj.name === key) as any;
      if (foundedObj) {
        fabricCanvas.remove(foundedObj);
      }
    });
    fabricCanvas?.requestRenderAll();
    configuratorRef.current?.updateCanvasTextureMap();

    const cuttingFormJfnet = design.model;
    JfnetServices.getConfigs(design.designHeaderId.toString())
      .then((res) => {
        const saveConfigStateData = JSON.parse(atob(res.data.data)) as SaveConfigState;
        JfnetServices.getCuttingFiles(cuttingFormJfnet)
          .then((cuttingRes) => {
            cuttingFileTemplateResponseRef.current = cuttingRes.data;
            const foundedCuttingFormData = cuttingFormResponseDataRef.current.find((item) => item.model === cuttingFormJfnet);
            currentCuttingFormItemNoRef.current = foundedCuttingFormData ? foundedCuttingFormData.itemNo : contextItemno;

            initialSaveConfigDataRef.current = saveConfigStateData;
            updateSaveConfigDataRef.current = saveConfigStateData;
            colorLogoHexRef.current = saveConfigStateData.colorLogoHexRef;
            colorDotsLeftHexRef.current = saveConfigStateData.colorDotsLeftHexRef;
            colorDotsRightHexRef.current  = saveConfigStateData.colorDotsRightHexRef;
            colorInvertedBgRef.current = saveConfigStateData.colorInvertedBgRef;
            if (saveConfigStateData.selectedModel) {
              cuttingFileTemplateResponseRef.current = {
                ...cuttingFileTemplateResponseRef.current,
                ...saveConfigStateData.selectedModel
              };
            }
            if (saveConfigStateData.selectedDesign) {
              // check design and set jersey color and pattern on start
              onSelectDesignStart(saveConfigStateData.selectedDesign, saveConfigStateData.selectedJerseySectionColor, saveConfigStateData.selectedJerseySectionPattern);
            }
            setAddedFabricLogoObjects(saveConfigStateData.addedFabricLogoObjects);
            addedFabricLogoObjectsRef.current = saveConfigStateData.addedFabricLogoObjects;
            setAddedFabricTextObjects(saveConfigStateData.addedFabricTextObjects);
            addedFabricTextObjectsRef.current = saveConfigStateData.addedFabricTextObjects;
            jakoLogoPositionRef.current = saveConfigStateData.jakoLogoPosition;
            setJakoLogoPosition(saveConfigStateData.jakoLogoPosition);
          }).catch(() => {
            setIsLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          });
      }).catch(() => {
        setIsLoading(false);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary !bg-theme-primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
      });
  }
  function onSetLoadingInitial(e: boolean) {
    setIsLoadingInitial(e);
  }
  function onSetLoading(e: boolean) {
    setIsLoading(e);
  }

  function onSelectColor(section: any, color: string) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }

    if (section === 'front_main') {
      if (ModelStyle.Shirt === selectModelStyle) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      if (section.includes('left_shoulder') || section.includes('right_shoulder')) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [JerseySection.Front]: color,
          left_shoulder: color,
          right_shoulder: color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [JerseySection.Front]: color,
        }));
      }
    } else if (section === 'back_main') {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        [JerseySection.Back]: color,
        'inside_rib_neck': color
      }));
    } else if (section.includes('collar')) {
      if (section.includes('collar_main')) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color,
          'collar_under': color,
          'collar_left': color,
          'collar_right': color,
          'bg-collar_left': color,
          'bg-collar_right': color,
          'copy-collar_left': color,
          'copy-collar_right': color,
          'placket_right': color,
          'placket_left': color,
          'bg-collar_under': color,
          'bg-placket_right': color,
          'bg-placket_left': color,
          'copy-collar_under': color,
          'copy-placket_right': color,
          'copy-placket_left': color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          'collar_under': prevState['collar_main'] || color,
          'collar_left': prevState['collar_main'] || color,
          'collar_right': prevState['collar_main'] || color,
          'bg-collar_left': prevState['collar_main'] || color,
          'bg-collar_right': prevState['collar_main'] || color,
          'copy-collar_left': prevState['collar_main'] || color,
          'copy-collar_right': prevState['collar_main'] || color,
          'placket_right': prevState['collar_main'] || color,
          'placket_left': prevState['collar_main'] || color,
          'bg-collar_under': prevState['collar_main'] || color,
          'bg-placket_right': prevState['collar_main'] || color,
          'bg-placket_left': prevState['collar_main'] || color,
          'copy-collar_under': prevState['collar_main'] || color,
          'copy-placket_right': prevState['collar_main'] || color,
          'copy-placket_left': prevState['collar_main'] || color,
        }));
      }
    } else if (section.includes('left_main')) {
      if (ModelStyle.Short === selectModelStyle && JakoLogoPosition.Left === jakoLogoPositionRef.current) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorDotsLeftHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (ModelStyle.Short === selectModelStyle) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color,
          'left_main-2': color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color
        }));
      }
    } else if (section.includes('right_main')) {
      if (ModelStyle.Short === selectModelStyle && JakoLogoPosition.Right === jakoLogoPositionRef.current) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorDotsRightHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (ModelStyle.Short === selectModelStyle) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color,
          'right_main-2': color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color
        }));
      }
    } else if (section.includes('short_elastic')) {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        'elastic_left_main': color,
        'elastic_right_in': color,
        'elastic_left_in': color,
        'elastic_right_main': color,
      }));
    } else if (section.includes('short_stripe')) {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        'stripe_right': color,
        'stripe_left': color,
      }));
    } else {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        [section]: color
      }));
    }
  }

  function onSelectColorFromPattern(section: any, color: string) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }
    if (section === 'front_main') {
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (section.includes('left_shoulder') || section.includes('right_shoulder')) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [JerseySection.Front]: color,
          left_shoulder: color,
          right_shoulder: color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [JerseySection.Front]: color,
        }));
      }
    } else if (section.includes('collar')) {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        [section]: color,
        'collar_under': color,
        'collar_left': color,
        'collar_right': color,
        'bg-collar_left': color,
        'bg-collar_right': color,
        'copy-collar_left': color,
        'copy-collar_right': color,
        'placket_right': color,
        'placket_left': color,
        'bg-collar_under': color,
        'bg-placket_right': color,
        'bg-placket_left': color,
        'copy-collar_under': color,
        'copy-placket_right': color,
        'copy-placket_left': color,
      }));
    } else if (section.includes('left_main')) {
      if (ModelStyle.Short === selectModelStyle && JakoLogoPosition.Left === jakoLogoPositionRef.current) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorDotsLeftHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (ModelStyle.Short === selectModelStyle) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color,
          'left_main-2': color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color
        }));
      }
    } else if (section.includes('right_main')) {
      if (ModelStyle.Short === selectModelStyle && JakoLogoPosition.Right === jakoLogoPositionRef.current) {
        const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
        if (foundedColorFromList) {
          colorLogoHexRef.current = '#' + foundedColorFromList.colorLogoHex;
        }
      }
      const foundedColorFromList = colorList.find((colorItem) => color.includes(colorItem.colorHexCode));
      if (foundedColorFromList) {
        colorDotsRightHexRef.current = '#' + foundedColorFromList.colorLogoHex;
      }
      if (ModelStyle.Short === selectModelStyle) {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color,
          'right_main-2': color,
        }));
      } else {
        setSelectedJerseySectionColor((prevState: any) => ({
          ...prevState,
          [section]: color
        }));
      }
    } else if (section.includes('short_elastic')) {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        'elastic_left_main': color,
        'elastic_right_in': color,
        'elastic_left_in': color,
        'elastic_right_main': color,
      }));
    } else if (section.includes('short_stripe')) {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        'stripe_right': color,
        'stripe_left': color,
      }));
    } else {
      setSelectedJerseySectionColor((prevState: any) => ({
        ...prevState,
        [section]: color
      }));
    }
  }

  function initJerseySectionColor(possibleDesignAreas: string[]) {
    initColorList()
      .finally(() => {
        let result: any = {};
        const mainWhite =  sortedColorListRef.current.find((item) => item.code === '3D_WEISS');
        const mainJakoBlue = sortedColorListRef.current.find((item) => item.code === '3D_JAKO_BLAU');
        const mainSchwarz = sortedColorListRef.current.find((item) => item.code === '3D_SCHWARZ');

        if (possibleDesignAreas.length) {
          let possibleDesignAreasObj = {} as any;
          let initJerseySectionColor = {} as any;
          if (sortedColorListRef.current.length) {
            // set initial color main part
            const foundedFirstPrimary = sortedColorListRef.current.slice().filter((item) => item.isPrimary)[1];
            if (foundedFirstPrimary) {
              initJerseySectionColor = {
                [JerseySection.Front]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Front] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Back]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Back] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Left]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Left] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.Right]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Right] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.CuffLeft]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffLeft] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.CuffRight]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffRight] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
              };
              setSelectedJerseySectionColor({
                [JerseySection.Front]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Front] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Back]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Back] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Left]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Left] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.Right]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Right] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.CuffLeft]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffLeft] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.CuffRight]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffRight] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
              });
              if (initialSaveConfigDataRef.current && initialSaveConfigDataRef.current.colorLogoHexRef) {
                colorLogoHexRef.current = initialSaveConfigDataRef.current.colorLogoHexRef;
              } else if (ModelStyle.Shirt === selectModelStyle) {
                colorLogoHexRef.current = mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D';
              } else if (ModelStyle.Short === selectModelStyle) {
                colorLogoHexRef.current = mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0';
              } else {
                colorLogoHexRef.current = mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D';
              }
              colorDotsLeftHexRef.current = initialSaveConfigDataRef.current
                ? initialSaveConfigDataRef.current.colorDotsLeftHexRef || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0');
              colorDotsRightHexRef.current = initialSaveConfigDataRef.current
                ? initialSaveConfigDataRef.current.colorDotsRightHexRef || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0');
            } else {
              initJerseySectionColor = {
                [JerseySection.Front]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Front] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Back]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Back] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Left]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Left] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.Right]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Right] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.CuffLeft]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffLeft] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.CuffRight]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffRight] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
              };
              setSelectedJerseySectionColor({
                [JerseySection.Front]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Front] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Back]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Back] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.Left]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Left] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.Right]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Right] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                  : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                [JerseySection.CuffLeft]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffLeft] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                [JerseySection.CuffRight]: initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.CuffRight] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                  : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
              });
              if (initialSaveConfigDataRef.current && initialSaveConfigDataRef.current.colorLogoHexRef) {
                colorLogoHexRef.current = initialSaveConfigDataRef.current.colorLogoHexRef;
              } else if (ModelStyle.Shirt === selectModelStyle) {
                colorLogoHexRef.current = mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D';
              } else if (ModelStyle.Short === selectModelStyle) {
                colorLogoHexRef.current = mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0';
              } else {
                colorLogoHexRef.current = mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D';
              }
              colorDotsLeftHexRef.current = initialSaveConfigDataRef.current
                ? initialSaveConfigDataRef.current.colorDotsLeftHexRef || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0');
              colorDotsRightHexRef.current = initialSaveConfigDataRef.current
                ? initialSaveConfigDataRef.current.colorDotsRightHexRef || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0');
            }
          }
          possibleDesignAreas.slice().forEach((area) => {
            let designKeyWithNumber = '';
            let designNumber = 0;
            let designArea = area;
            if (area.includes('bg-')) {
              designArea = area.split('bg-')[1];
              designKeyWithNumber = area.split('bg-')[1].split('_')[0];
            } else if (area.includes('copy-')) {
              designArea = area.split('copy-')[1];
              designKeyWithNumber = area.split('copy-')[1].split('_')[0];
            } else {
              designArea = area;
              designKeyWithNumber = area.split('_')[0];
            }
            const number = parseInt(designKeyWithNumber.split('-')[1]);
            designNumber = number ? number : 0;
            if (area === 'left_shoulder' || area === 'right_shoulder') {
              const color = initialSaveConfigDataRef.current
                ? initialSaveConfigDataRef.current.selectedJerseySectionColor[JerseySection.Front] || (selectedJerseySectionColor[JerseySection.Front] || initJerseySectionColor[JerseySection.Front])
                : selectedJerseySectionColor[JerseySection.Front] || initJerseySectionColor[JerseySection.Front];
              possibleDesignAreasObj = {
                ...possibleDesignAreasObj,
                [area]: color,
              };
            } else if (area === 'collar_main') {
              if (!selectedJerseySectionColor[designArea]) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                    : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]:  initialSaveConfigDataRef.current
                  ? initialSaveConfigDataRef.current.selectedJerseySectionColor[designArea] || selectedJerseySectionColor[designArea]
                  : selectedJerseySectionColor[designArea],
                };
              }
            } else if (area.includes('collar_under')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || selectedJerseySectionColor['collar_main']
                    : selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                    : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                };
              }
            } else if (area.includes('inside_rib_neck')) {
              if (selectedJerseySectionColor[JerseySection.Back]) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || selectedJerseySectionColor[JerseySection.Back]
                    : selectedJerseySectionColor[JerseySection.Back],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                    : (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0'),
                };
              }
            } else if (area.includes('placket_left')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || selectedJerseySectionColor['collar_main']
                    : selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                    : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                };
              }
            } else if (area.includes('placket_right')) {
              if (selectedJerseySectionColor['collar_main']) {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || selectedJerseySectionColor['collar_main']
                    : selectedJerseySectionColor['collar_main'],
                };
              } else {
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB')
                    : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB'),
                };
              }
            } else {
              if (selectModelStyle === ModelStyle.Short && area.includes('stripe_')) {
                const color = selectedJerseySectionColor[designArea] || initJerseySectionColor[designArea];
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || (mainWhite ? '#'+mainWhite.colorHexCode : '#F0F5F0')
                    : color ? color : '#F0F5F0',
                };
              } else {
                const color = selectedJerseySectionColor[designArea] || initJerseySectionColor[designArea];
                const initColorForDesign = designNumber === 1 ? (mainMarine ? '#'+mainMarine.colorHexCode : '#131E43') : (mainJakoBlue ? '#'+mainJakoBlue.colorHexCode : '#0086BB');
                possibleDesignAreasObj = {
                  ...possibleDesignAreasObj,
                  [area]: initialSaveConfigDataRef.current
                    ? initialSaveConfigDataRef.current.selectedJerseySectionColor[area] || initColorForDesign
                    : color ? color : initColorForDesign,
                };
              }
            }
          });
          result = {
            ...result,
            ...possibleDesignAreasObj
          };
          if (ModelStyle.Shirt === selectModelStyle) {
            const foundedColorFrontColor = result['front_main'] && colorList.find((colorItem) => result['front_main'].slice(1).includes(colorItem.colorHexCode));
            if (foundedColorFrontColor) {
              colorLogoHexRef.current = '#' + foundedColorFrontColor.colorLogoHex;
            }
          } else {
            if (JakoLogoPosition.Right === jakoLogoPositionRef.current) {
              const foundedColor = result['left_main'] && colorList.find((colorItem) => result['left_main'].slice(1).includes(colorItem.colorHexCode));
              if (foundedColor) {
                colorLogoHexRef.current = '#' + foundedColor.colorLogoHex;
              }
            } else {
              const foundedColor = result['right_main'] && colorList.find((colorItem) => result['right_main'].slice(1).includes(colorItem.colorHexCode));
              if (foundedColor) {
                colorLogoHexRef.current = '#' + foundedColor.colorLogoHex;
              }
            }
          }
          const foundedColorLeftColor = result['left_main'] && colorList.find((colorItem) => result['left_main'].slice(1).includes(colorItem.colorHexCode));
          if (foundedColorLeftColor) {
            colorDotsLeftHexRef.current = '#' + foundedColorLeftColor.colorLogoHex;
          }
          const foundedColorRightColor = result['right_main'] && colorList.find((colorItem) => result['right_main'].slice(1).includes(colorItem.colorHexCode));
          if (foundedColorRightColor) {
            colorDotsRightHexRef.current = '#' + foundedColorRightColor.colorLogoHex;
          }
          setSelectedJerseySectionColor(result);
        }
      });
  }

  function onSelectPatternSvg(section: string, item: PatternResponseItem | null) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }
    if (item) {
      setSelectedJerseySectionPattern((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          fill: prevState[section] ? prevState[section].fill || (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D') : (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D'),
          svg: item.base64,
          filename: item.filename,
          tileable: item.tileable,
          description: item.description
        }
      }));
    } else {
      setSelectedJerseySectionPattern((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          fill: (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D'),
          svg: '',
          filename: '',
          tileable: false,
          description: ''
        }
      }));
    }
  }
  function onSelectPatternFill(section: string, fill: string) {
    if (section.toString().includes('back')) {
      moveCameraToBackSide();
    } else if (section.toString().includes('front')) {
      moveCameraToFrontSide();
    }

    setSelectedJerseySectionPattern((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        fill: fill,
      }
    }));
  }
  function onPatternScale(section: string , e: number) {
    setSelectedJerseySectionPattern((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        scale: e,
      }
    }));
  }
  function onPatternMoveOffset(section: string, offset: {
    top: number,
    left: number
  }) {
    setSelectedJerseySectionPattern((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        ...offset,
      }
    }));
  }
  function onPatternRotate(section: string ,e: number) {
    setSelectedJerseySectionPattern((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        rotate: e,
      }
    }));
  }
  function onSetPatternJerseySection(e: string) {
    if (ModelStyle.Shirt === selectModelStyle) {
      // back
      if (e.includes('back')) {
        moveCameraToBackSide();
      }
      // front
      else if (e.includes('front')) {
        moveCameraToFrontSide();
      }
      // right
      else if (e.includes('right')) {
        moveCameraToRightSide();
      }
      // left
      else if (e.includes('left')) {
        moveCameraToLeftSide();
      }
    } else {
      // right
      if (e.includes('right')) {
        moveCameraToRightSideShort();
      }
      // left
      else if (e.includes('left')) {
        moveCameraToLeftSideShort();
      }
    }
    setPatternJerseySection(e);
  }

  function onSetPossibleDesignAreaId(e: string[]) {
    const possibleColorArea = e.map((v) => v.toString());
    const getPossibleDesignAreas = possibleColorArea.slice().filter((v) => !RESERVED_JERSEY_AREAS_ID.includes(v));
    setPossibleDesignAreaId(getPossibleDesignAreas);
    initJerseySectionColor(e);
  }

  // Local development testing save and print process
  function onTestDownloadAndPrintSvgResult() {
    getSvgResultFile().then((blobRes) => {
      if (blobRes) {
        JfnetServices.savePrintFileMaster({
          designHeaderId: '111222333', // Mock test designHeaderId
          printMaster: blobRes
        }).then(() => {
          let tmpCustomFieldLogoObjects = {};
          Object.keys(addedFabricLogoObjectsRef.current).forEach((itemKey) => {
            if (addedFabricLogoObjectsRef.current[itemKey].x !== 0 && addedFabricLogoObjectsRef.current[itemKey].y !== 0) {
              if (addedFabricLogoObjectsRef.current[itemKey].reference) {
                tmpCustomFieldLogoObjects = {
                  ...tmpCustomFieldLogoObjects,
                  [addedFabricLogoObjectsRef.current[itemKey].fieldCode]: addedFabricLogoObjectsRef.current[itemKey].reference
                };
              }
            }
          });

          let tmpCustomFieldTextObjects = {};
          Object.keys(addedFabricTextObjectsRef.current).forEach((itemKey) => {
            if (addedFabricTextObjectsRef.current[itemKey].x !== 0 && addedFabricTextObjectsRef.current[itemKey].y !== 0) {
              tmpCustomFieldTextObjects = {
                ...tmpCustomFieldTextObjects,
                [addedFabricTextObjectsRef.current[itemKey].fieldCode]: addedFabricTextObjectsRef.current[itemKey].text
              };
            }
          });

          JfnetServices.printFileMaster({
            designHeaderId: '111222333', // Mock test designHeaderId
            printData: [{
              printId: 'test_tin_size_L',
              size: 'L',
              customFields: {
                ...tmpCustomFieldLogoObjects,
                ...tmpCustomFieldTextObjects
              }
            }]
          }).then(() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                     <label htmlFor="saveEnterName" className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      TEST POST /files/print - WORKS
                    </label>
                    <div className="flex justify-between">
                      <button className="outlineButton" type="button" onClick={onClose}>
                        OK
                      </button>
                    </div>
                  </div>
                );
              }
            });
          }).catch((err) => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                     <label htmlFor="saveEnterName" className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      TEST POST /files/print - ERROR
                    </label>
                    <p>
                      {err.toString()}
                    </p>
                    <div className="flex justify-between">
                      <button className="outlineButton" type="button" onClick={onClose}>
                        OK
                      </button>
                    </div>
                  </div>
                );
              }
            });
          });
        });
      }
    });
  }

  function getSvgResultFile() {
    const getAddedTextFontFamilyList = Object.keys(addedFabricTextObjectsRef.current).slice()
      .map((itemKey) => addedFabricTextObjectsRef.current[itemKey].fontFamily || 'Komikazoom');
    const getBase64FontOfAddedTexts: Promise<DownloadSvgFontToBase64>[] = getAddedTextFontFamilyList.map((font) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      const foundedFont = FONT_OPTIONS.find((fontOption) => fontOption.fontFamily === font);
      const fileRe = foundedFont ? foundedFont.src : 'Komikazoom';
      fetch(fileRe).then((res) => {
        res.blob().then((blobRes) => {
          const fontBlob = blobRes;
          reader.readAsDataURL(fontBlob);
          reader.onload = (e) => {
            const target = e.target as any;
            resolve({
              font: font,
              base64: target.result
            });
          };
          reader.onerror = () => {
            reject({
              font: '',
              base64: ''
            });
          };
        });
      });
    }));

    return Promise.all(getBase64FontOfAddedTexts).then((base64Fonts) => configuratorRef.current?.getRasterizeSVGFile(base64Fonts));
  }

  function onConfirmCompletePopup() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
             <label htmlFor="saveEnterName" className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
              { t('configurator_3d_add_cart_sucess') }
            </label>
            <div className="flex flex-col-reverse lg:flex-row justify-between">
              <button className="outlineButton" type="button" onClick={onClose}>
                { t('configurator_3d_continue_config') }
              </button>
              <button className="button_primary !bg-theme-primary" onClick={() => {window.location.href = contextBasketUrl;}}>
                { t('configurator_3d_go_to_cart') }
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false
    });
  }

  async function completeConfiguration() {
    setTimeout(() => {
      if (updateSaveConfigDataRef.current === null) {
        handleSaveConfiguration(true);
        return;
      }

      setIsLoading(true);
      const response = updateJakoIndividualisation(updateSaveConfigDataRef.current.designHeaderId, true);
      if (response) {
        response
          .then((res) => {
            const correlationId = contextCorrelationId  || '111';
            const visitorId = contextVisitorId || '222';
            const newUpdateDesignHeaderId = res?.data.designHeaderId.toString();
            JakoServices.basketAddItems({
              correlationId,
              visitorId: parseInt(visitorId),
              basketLines: [
                {
                  designHeaderId: parseInt(newUpdateDesignHeaderId),
                  itemNo: currentCuttingFormItemNoRef.current,
                  quantity: 1
                }
              ]
            })
            .then(() => {
              if (updateSaveConfigDataRef.current !== null) {
                saveConfiguration({
                  designHeaderId: newUpdateDesignHeaderId,
                  configurationName: updateSaveConfigDataRef.current.configurationName,
                  isSaveBeforeSubmitAddToCart: true
                });
              }
              onConfirmCompletePopup();
            })
            .catch((err) => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                      <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                        { t('configurator_3d_error_something_wrong') }
                      </h5>
                      <button className="button_primary !bg-theme-primary" onClick={onClose}>
                        { t('configurator_3d_ok') }
                      </button>
                    </div>
                  );
                }
              });
            })
            .finally(() => {
              onSetLoading(false);
            });
          })
          .catch((err) => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          })
          .finally(() => {
            onSetLoading(false);
          });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary !bg-theme-primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }, 1000);
  }

  function localDevelopmentTest_completeConfiguration() {
    setTimeout(() => {
      if (updateSaveConfigDataRef.current === null) {
        localDevelopmentTest_handleSaveConfiguration(true);
        return;
      }

      setIsLoading(true);
      // const response = updateJakoIndividualisation(updateSaveConfigDataRef.current.designHeaderId, true);
      const response = new Promise((resolve) => resolve(''));
      if (response) {
        response
          .then(() => {
            // const correlationId = contextCorrelationId  || '111';
            // const visitorId = contextVisitorId || '222';
            const newUpdateDesignHeaderId = '111222333';
            saveConfiguration({
              designHeaderId: newUpdateDesignHeaderId,
              configurationName: 'testname_111222333',
              isSaveBeforeSubmitAddToCart: true
            }).then(() => {
              onTestDownloadAndPrintSvgResult();
              // onConfirmCompletePopup();
            });

            // JakoServices.basketAddItems({
            //   correlationId,
            //   visitorId: parseInt(visitorId),
            //   basketLines: [
            //     {
            //       designHeaderId: parseInt(newUpdateDesignHeaderId),
            //       itemNo: currentCuttingFormItemNoRef.current,
            //       quantity: 1
            //     }
            //   ]
            // })
            //   .then(() => {
            //     if (updateSaveConfigDataRef.current !== null) {
            //       saveConfiguration({
            //         designHeaderId: newUpdateDesignHeaderId,
            //         configurationName: updateSaveConfigDataRef.current.configurationName,
            //         isSaveBeforeSubmitAddToCart: true
            //       });
            //     }
            //     onConfirmCompletePopup();
            //   })
            //   .catch((err) => {
            //     confirmAlert({
            //       customUI: ({ onClose }) => {
            //         return (
            //           <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
            //             <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
            //               { 'Error: ' + err }
            //             </h5>
            //             <button className="button_primary !bg-theme-primary" onClick={onClose}>
            //               { t('configurator_3d_ok') }
            //             </button>
            //           </div>
            //         );
            //       }
            //     });
            //   })
            //   .finally(() => {
            //     onSetLoading(false);
            //   });
          })
          .catch((err) => {
            confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          })
          .finally(() => {
            onSetLoading(false);
          });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                    { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary !bg-theme-primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }, 1000);
  }

  function onCompleteConfiguration() {
    // onclicksubmit
    if (onclicksubmit) {
      onclicksubmit(completeConfiguration);
    } else {
      completeConfiguration();
    }
  }

  function localDevelopmentTest_onCompleteConfiguration() {
    // onTestDownloadAndPrintSvgResult();
    localDevelopmentTest_completeConfiguration();
  }

  function moveCameraToFrontSide(reset?: boolean) {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(-0.22215543323018355, 1.3760002904351893, 6.859830184793832, true);
    } else {
      cameraControlsRef.current?.setPosition(-0.18716965860240095, 1.1828048040289152, 5.89680653016052, true);
    }
    cameraControlsRef.current?.saveState();
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
    if (reset) {
      setTimeout(() => {
        cameraControlsRef.current?.reset(true);
      }, 200);
    }
  }

  function moveCameraToBackSide() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(-0.22215543323018355, 1.3760002904351893, -6.859830184793832, true);
    } else {
      cameraControlsRef.current?.setPosition(-0.18716965860240095, 1.1828048040289152, -5.89680653016052, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function moveCameraToRightSide() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(-6.842482817302935, 1.3838126537547903, 0.5151324105956326, true);
    } else {
      cameraControlsRef.current?.setPosition(-5.999257226979, 0.44966400094376696, 0.11446375179260178, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function moveCameraToLeftSide() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(6.842482817302935, 1.3838126537547903, 0.5151324105956326, true);
    } else {
      cameraControlsRef.current?.setPosition(5.999257226979, 0.44966400094376696, 0.11446375179260178, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function moveCameraToLeftSideShort() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
    } else {
      cameraControlsRef.current?.setPosition(5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }
  function moveCameraToRightSideShort() {
    if (isMobile) {
      cameraControlsRef.current?.setPosition(-5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
    } else {
      cameraControlsRef.current?.setPosition(-5.239677233436536, 0.9903925829485761, 2.7877037074851128, true);
    }
    cameraControlsRef.current?.setTarget(0, 0, 0, true);
  }

  function initColorList() {
    const correlationId = contextCorrelationId  || '111';
    const company = contextCompany || 'JAKO AG';
    const languageCode = selectedLang.current.name || contextLanguageCode;
    return new Promise((resolve, reject) => {
      JakoServices.getColors({
        correlationId,
        company,
        languageCode,
      }).then((response) => {
          const colorResponse = response.data as ColorResponseItem[];
          sortedColorListRef.current = colorResponse.slice().sort((a, b) => {
            if (a.isPrimary && b.isPrimary) {
              return a.sorting - b.sorting;
            } else if (a.isPrimary) {
              return -1;
            } else if (b.isPrimary) {
              return 1;
            } else {
              return a.sorting - b.sorting;
            }
          });
          setColorList(colorResponse);
          resolve(colorResponse);
        });
    });
  }

  const onConfiguratorModified = debounce(function(type: string, id: string) {
    if (!configuratorRef.current) {
      return;
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return;
    }
    if (type === 'logo') {
      const foundedLogo = configuratorRef.current.fabricCanvas.current._objects.find((item) => item.name === id) as any;
      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;
      const foundedPositionPart = Object.keys(designSvgPatternObjectsBounding).find((itemKey) => {
        const partWidth = isMobile ? designSvgPatternObjectsBounding[itemKey].width / 4 : designSvgPatternObjectsBounding[itemKey].width;
        const partHeight = isMobile ? designSvgPatternObjectsBounding[itemKey].height / 4 : designSvgPatternObjectsBounding[itemKey].height;
        const centerX = designSvgPatternObjectsBounding[itemKey].pathOffset.x;
        const centerY = designSvgPatternObjectsBounding[itemKey].pathOffset.y;

        const leftEdgeX = Math.abs(centerX - (partWidth / 2));
        const rightEdgeX = centerX + (partWidth / 2);
        const topEdgeY = Math.abs(centerY - (partHeight / 2));
        const bottomEdgeY = centerY + (partHeight / 2);
        return foundedLogo.left >= leftEdgeX
          && foundedLogo.left <= rightEdgeX
          && foundedLogo.top >= topEdgeY
          && foundedLogo.top <= bottomEdgeY;
      });
      if (foundedLogo) {
        addedFabricLogoObjectsRef.current = {
          ...addedFabricLogoObjectsRef.current,
          [id]: {
            ...addedFabricLogoObjectsRef.current[id],
            x: isMobile ? foundedLogo.left * 4 : foundedLogo.left,
            y: isMobile ? foundedLogo.top * 4 : foundedLogo.top,
            rotation: foundedLogo.angle,
            foundedPositionPart: foundedPositionPart || 'front',
          }
        };
        setAddedFabricLogoObjects(addedFabricLogoObjectsRef.current);
      }
    } else {
      const foundedText = configuratorRef.current.fabricCanvas.current._objects.find((item) => item.name === id) as any;
      const designSvgPatternObjectsBounding = configuratorRef.current.designSvgPatternObjectsBounding.current;
      const foundedPositionPart = Object.keys(designSvgPatternObjectsBounding).find((itemKey) => {
        const partWidth = isMobile ? designSvgPatternObjectsBounding[itemKey].width / 4 : designSvgPatternObjectsBounding[itemKey].width;
        const partHeight = isMobile ? designSvgPatternObjectsBounding[itemKey].height / 4 : designSvgPatternObjectsBounding[itemKey].height;
        const centerX = designSvgPatternObjectsBounding[itemKey].pathOffset.x;
        const centerY = designSvgPatternObjectsBounding[itemKey].pathOffset.y;

        const leftEdgeX = Math.abs(centerX - (partWidth / 2));
        const rightEdgeX = centerX + (partWidth / 2);
        const topEdgeY = Math.abs(centerY - (partHeight / 2));
        const bottomEdgeY = centerY + (partHeight / 2);
        return foundedText.left >= leftEdgeX
          && foundedText.left <= rightEdgeX
          && foundedText.top >= topEdgeY
          && foundedText.top <= bottomEdgeY;
      });
      if (foundedText) {
        console.log('addedFabricTextObjectsRef.current[id]', addedFabricTextObjectsRef.current[id]);
        addedFabricTextObjectsRef.current = {
          ...addedFabricTextObjectsRef.current,
          [id]: {
            ...addedFabricTextObjectsRef.current[id],
            fontSize: foundedText.fontSize,
            x: isMobile ? foundedText.left * 4 : foundedText.left,
            y: isMobile ? foundedText.top * 4 : foundedText.top,
            fill: foundedText.fill,
            text: foundedText.text,
            fontFamily: foundedText.fontFamily,
            rotation: foundedText.angle,
            foundedPositionPart: foundedPositionPart || 'front'
          }
        };
        setAddedFabricTextObjects(addedFabricTextObjectsRef.current);
      }
    }
  }, 200);

  function onConfiguratorAdded(type: string, id: string) {
  }
  function onConfiguratorDeleted(type: string, id: string) {
  }
  function onConfiguratorChangeTextColor() {
  }
  function onConfiguratorChangeColor() {
  }
  function onConfiguratorAddPattern() {
  }

  function handleOnSetHasBackDesign(e: boolean) {
    setHasBackDesign(e);
  }

  function handleOnCloseMobile() {
    if (!isMobile) {
      return;
    }
    configuratorRef.current?.fabricCanvas.current?.discardActiveObject();
    configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
    configuratorRef.current?.updateCanvasTextureMap();
    setSelectTab(null);
  }

  function delay(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  function getDefaultScreenShot(isSaveBeforeSubmitAddToCart?: boolean): Promise<string[]> {
    setIsLoadingInitial(true);
    setIsSavingScreenshot(true);
    const isLongarm = cuttingOptions.current.length && cuttingOptions.current.find((item) => item.code === '3D_SCHNITTFORM' && item.value === 'LANGARM');

    if (!configuratorRef.current) {
      return Promise.resolve(['']);
    }
    if (!configuratorRef.current.fabricCanvas.current) {
      return Promise.resolve(['']);
    }
    if (!currentCanvasGLRef.current) {
      return Promise.resolve(['']);
    }

    configuratorRef.current.fabricCanvas.current.discardActiveObject().renderAll();
    const canvasEl = currentCanvasGLRef.current.domElement;
    if (isSaveBeforeSubmitAddToCart) {
      configuratorRef.current?.fabricCanvas.current?.requestRenderAll();
      configuratorRef.current?.updateCanvasTextureMap();
    }
    cameraControlsRef.current?.setPosition(-0.06512612834137524, 0.866525151725151, 4.16553710195109, false);
    cameraControlsRef.current?.setTarget(0, 0.35, 0, false);
    if (isLongarm) {
      cameraControlsRef.current?.dolly(-1, false);
    } else {
      cameraControlsRef.current?.dolly(0, false);
    }
    cameraControlsRef.current?.truck(0, 0, false);

    const results: Promise<string[]> = new Promise((resolve, reject) => {
      delay(1500).then(() =>{
        const front = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        cameraControlsRef.current?.rotate(180 * MathUtils.DEG2RAD, 0, false);
        delay(500).then(() =>{
          const back = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
          cameraControlsRef.current?.rotate(90 * MathUtils.DEG2RAD, 0, false);
          delay(500).then(() =>{
            const right = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            cameraControlsRef.current?.rotate(180 * MathUtils.DEG2RAD, 0, false);
            delay(500).then(() =>{
              const left = canvasEl.toDataURL('image/png').replace('image/png', 'image/octet-stream');
              resolve([front, back, left, right]);
            });
          });
        });
      });
    });

    return results.finally(() => {
      setIsSavingScreenshot(false);
      setTimeout(() => {
        cameraControlsRef.current?.reset();
        setTimeout(() => {
          setIsLoadingInitial(false);
        }, 500);
      }, 500);
    });
  }

  async function createJakoIndividualisation(configurationName: string, isSaveBeforeSubmitAddToCart?: boolean) {
    try {
      // Test save API status
      await saveConfiguration({
        configurationName: 'TEST_SAVE_CONFIG',
        designHeaderId: '0',
        isTestSave: true
      });

      // If it's ok then save to JAKO DC
      let payloadTexts: any[] = [];
      let payloadLogos: any[] = [];
      if (Object.keys(addedFabricTextObjectsRef.current).length) {
        Object.keys(addedFabricTextObjectsRef.current).forEach((textKey) => {
          if (!(addedFabricTextObjectsRef.current[textKey].x === 0 || addedFabricTextObjectsRef.current[textKey].y === 0)) {
            if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('front')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('back')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Back,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('left')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideLeft,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('right')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideRight,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            }
          }
        });
      }
      if (Object.keys(addedFabricLogoObjectsRef.current).length) {
        Object.keys(addedFabricLogoObjectsRef.current).forEach((logoKey) => {
          if (
            !(addedFabricLogoObjectsRef.current[logoKey].x === 0 || addedFabricLogoObjectsRef.current[logoKey].y === 0)
              && addedFabricLogoObjectsRef.current[logoKey].reference
          ) {
            if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('front')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: [{
                  colorHexCode: '',
                  colorInfoText: '',
                }]
              });
            } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('back')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.Back,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: [{
                  colorHexCode: '',
                  colorInfoText: '',
                }]
              });
            } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('left')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.SideLeft,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: [{
                  colorHexCode: '',
                  colorInfoText: '',
                }]
              });
            } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('right')) {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.SideRight,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: [{
                  colorHexCode: '',
                  colorInfoText: '',
                }]
              });
            } else {
              payloadLogos.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                value: addedFabricLogoObjectsRef.current[logoKey].src,
                size: [
                  {
                    width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: '',
                fontFamily: '',
                imageColors: [{
                  colorHexCode: '',
                  colorInfoText: '',
                }]
              });
            }
          }
        });
      }

      let payloadCuttingFormElements: {
        viewCode: IndividualisationViewCode | string,
        colorCode: string,
      }[] = [
        {
          viewCode: IndividualisationViewCode.Front,
          colorCode: selectedJerseySectionColor[JerseySection.Front],
        },
        {
          viewCode: IndividualisationViewCode.Back,
          colorCode: selectedJerseySectionColor[JerseySection.Back],
        },
        {
          viewCode: IndividualisationViewCode.SideLeft,
          colorCode: selectedJerseySectionColor[JerseySection.Left],
        },
        {
          viewCode: IndividualisationViewCode.SideRight,
          colorCode: selectedJerseySectionColor[JerseySection.Right],
        }
      ];

      if (selectedJerseySectionColor[JerseySection.Collar]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'Collar',
            colorCode: selectedJerseySectionColor[JerseySection.Collar],
          }
        ];
      }
      if (selectedJerseySectionColor[JerseySection.CuffLeft]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'CuffLeft',
            colorCode: selectedJerseySectionColor[JerseySection.CuffLeft],
          }
        ];
      }
      if (selectedJerseySectionColor[JerseySection.CuffRight]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'CuffRight',
            colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
          }
        ];
      }
      if (selectedJerseySectionColor[JerseySection.CuffRight]) {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'CuffRight',
            colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
          }
        ];
      }

      // shirt design possible color
      if (onlyPossibleDesignAreas) {
        Object.keys(onlyPossibleDesignAreas).forEach((designKey) => {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: designKey,
              colorCode: selectedJerseySectionColor[designKey],
            }
          ];
        });
      }

      Object.keys(selectedJerseySectionPattern).forEach((itemKey) => {
        payloadCuttingFormElements = [
          ...payloadCuttingFormElements,
          {
            viewCode: 'Pattern_' + itemKey,
            colorCode: selectedJerseySectionPattern[itemKey].fill || (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D'),
          }
        ];
      });

      const correlationId = contextCorrelationId  || '111';
      const visitorId = contextVisitorId || '222';
      const company = contextCompany || 'JAKO AG';
      const shopCode = contextShopCode || 'B2C';
      const languageCode = selectedLang.current.name || contextLanguageCode;
      const screenshots = await getDefaultScreenShot(isSaveBeforeSubmitAddToCart);
      return JakoServices.putIndividualisation({
        correlationId,
        visitorId: parseInt(visitorId),
        itemNo: currentCuttingFormItemNoRef.current,
        cuttingFormCode: currentCuttingFormCodeRef.current,
        configurationName,
        company,
        shopCode,
        languageCode,
        isPrintable: !hasUnprocessableImage,
        designLines: [
          ...payloadTexts,
          ...payloadLogos,
          // ...payloadPatterns,
          // ...payloadShirtColors,
        ],
        previewImages: [
          {
            viewCode: IndividualisationViewCode.Front,
            value: screenshots[0]
          },
          {
            viewCode: IndividualisationViewCode.Back,
            value: screenshots[1]
          },
          {
            viewCode: IndividualisationViewCode.SideLeft,
            value: screenshots[2]
          },
          {
            viewCode: IndividualisationViewCode.SideRight,
            value: screenshots[3]
          }
        ],
        cuttingFormElements: payloadCuttingFormElements
      });
    } catch {
      setIsLoading(false);
      return undefined;
    }
  }

  async function updateJakoIndividualisation(designHeaderId: string, isSaveBeforeSubmitAddToCart?: boolean) {
    try {
      // Test save API status
      await saveConfiguration({
        configurationName: 'TEST_SAVE_CONFIG',
        designHeaderId: '0',
        isTestSave: true
      });

      // If it's ok then save to JAKO DC
      if (currentCanvasGLRef.current) {
        let payloadTexts: any[] = [];
        let payloadLogos: any[] = [];
        // let payloadShirtColors: any[] = [];
        // let payloadPatterns: any[] = [];
        if (Object.keys(addedFabricTextObjectsRef.current).length) {
          Object.keys(addedFabricTextObjectsRef.current).forEach((textKey, index) => {
            if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('front')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('back')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Back,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('left')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideLeft,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else if (addedFabricTextObjectsRef.current[textKey].foundedPositionPart && addedFabricTextObjectsRef.current[textKey].foundedPositionPart.toLowerCase().includes('right')) {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.SideRight,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            } else {
              payloadTexts.push({
                viewCode: IndividualisationViewCode.Front,
                fieldCode: addedFabricTextObjectsRef.current[textKey].fieldCode,
                value: addedFabricTextObjectsRef.current[textKey].text,
                size: [
                  {
                    width: Math.round((addedFabricTextObjectsRef.current[textKey].scaledWidth) * 10) / 10,
                    height: Math.round((addedFabricTextObjectsRef.current[textKey].scaledHeight) * 10) / 10,
                  }
                ],
                fontColorCode: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.code || '',
                fontFamily: addedFabricTextObjectsRef.current[textKey].fontFamily,
                imageColors: [{
                  colorHexCode: addedFabricTextObjectsRef.current[textKey].fill,
                  colorInfoText: sortedColorListRef.current.find((item) => '#'+item.colorHexCode === addedFabricTextObjectsRef.current[textKey].fill)?.description || '',
                }]
              });
            }
          });
        }
        if (Object.keys(addedFabricLogoObjectsRef.current).length) {
          Object.keys(addedFabricLogoObjectsRef.current).forEach((logoKey) => {
            if (
              !(addedFabricLogoObjectsRef.current[logoKey].x === 0 || addedFabricLogoObjectsRef.current[logoKey].y === 0)
                && addedFabricLogoObjectsRef.current[logoKey].reference
            ) {
              if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('front')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.Front,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('back')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.Back,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('left')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.SideLeft,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else if (addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart && addedFabricLogoObjectsRef.current[logoKey].foundedPositionPart.toLowerCase().includes('right')) {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.SideRight,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              } else {
                payloadLogos.push({
                  viewCode: IndividualisationViewCode.Front,
                  fieldCode: addedFabricLogoObjectsRef.current[logoKey].fieldCode,
                  value: addedFabricLogoObjectsRef.current[logoKey].src,
                  size: [
                    {
                      width: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledWidth) * 10) / 10,
                      height: Math.round((addedFabricLogoObjectsRef.current[logoKey].scaledHeight) * 10) / 10,
                    }
                  ],
                  fontColorCode: '',
                  fontFamily: '',
                  imageColors: addedFabricLogoObjectsRef.current[logoKey].possibleImageColors
                });
              }
            }
          });
        }
        let payloadCuttingFormElements: {
          viewCode: IndividualisationViewCode | string,
          colorCode: string,
        }[] = [
          {
            viewCode: IndividualisationViewCode.Front,
            colorCode: selectedJerseySectionColor[JerseySection.Front],
          },
          {
            viewCode: IndividualisationViewCode.Back,
            colorCode: selectedJerseySectionColor[JerseySection.Back],
          },
          {
            viewCode: IndividualisationViewCode.SideLeft,
            colorCode: selectedJerseySectionColor[JerseySection.Left],
          },
          {
            viewCode: IndividualisationViewCode.SideRight,
            colorCode: selectedJerseySectionColor[JerseySection.Right],
          }
        ];
        if (selectedJerseySectionColor[JerseySection.Collar]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'Collar',
              colorCode: selectedJerseySectionColor[JerseySection.Collar],
            }
          ];
        }
        if (selectedJerseySectionColor[JerseySection.CuffLeft]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'CuffLeft',
              colorCode: selectedJerseySectionColor[JerseySection.CuffLeft],
            }
          ];
        }
        if (selectedJerseySectionColor[JerseySection.CuffRight]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'CuffRight',
              colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
            }
          ];
        }
        if (selectedJerseySectionColor[JerseySection.CuffRight]) {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'CuffRight',
              colorCode: selectedJerseySectionColor[JerseySection.CuffRight],
            }
          ];
        }
        // shirt design possible color
        if (onlyPossibleDesignAreas) {
          Object.keys(onlyPossibleDesignAreas).forEach((designKey) => {
            payloadCuttingFormElements = [
              ...payloadCuttingFormElements,
              {
                viewCode: designKey,
                colorCode: selectedJerseySectionColor[designKey],
              }
            ];
          });
        }
        Object.keys(selectedJerseySectionPattern).forEach((itemKey) => {
          payloadCuttingFormElements = [
            ...payloadCuttingFormElements,
            {
              viewCode: 'Pattern_' + itemKey,
              colorCode: selectedJerseySectionPattern[itemKey].fill || (mainSchwarz ? '#'+mainSchwarz.colorHexCode : '#2A2A2D'),
            }
          ];
        });
        const correlationId = contextCorrelationId  || '111';
        const screenshots = await getDefaultScreenShot(isSaveBeforeSubmitAddToCart);
        return JakoServices.postIndividualisation({
          correlationId,
          designHeaderId: parseInt(designHeaderId),
          cuttingFormCode: currentCuttingFormCodeRef.current,
          itemNo: currentCuttingFormItemNoRef.current,
          configurationName: updateSaveConfigDataRef.current ? updateSaveConfigDataRef.current.configurationName : 'unknown_configurationName_testjfnet',
          isPrintable: !hasUnprocessableImage,
          designLines: [
            ...payloadTexts,
            ...payloadLogos,
          ],
          previewImages: [
            {
              viewCode: IndividualisationViewCode.Front,
              value: screenshots[0]
            },
            {
              viewCode: IndividualisationViewCode.Back,
              value: screenshots[1]
            },
            {
              viewCode: IndividualisationViewCode.SideLeft,
              value: screenshots[2]
            },
            {
              viewCode: IndividualisationViewCode.SideRight,
              value: screenshots[3]
            }
          ],
          cuttingFormElements: payloadCuttingFormElements
        });
      }
    } catch {
      setIsLoading(false);
    }
  }

  function saveConfiguration({
    configurationName,
    designHeaderId,
    isSaveBeforeSubmitAddToCart,
    isTestSave
  }: {
    configurationName: string,
    designHeaderId: string,
    isSaveBeforeSubmitAddToCart?: boolean | undefined,
    isTestSave?: boolean,
  }) {
    const fabricObjects = configuratorRef.current
      ? configuratorRef.current.fabricCanvas.current
        ? configuratorRef.current.fabricCanvas.current._objects
        : []
      : [];
    let tmpLogoObjects = {} as AddedLogoItems;
    Object.keys(addedFabricLogoObjectsRef.current).forEach((itemKey) => {
      if (addedFabricLogoObjectsRef.current[itemKey].x !== 0
        && addedFabricLogoObjectsRef.current[itemKey].y !== 0
        && addedFabricLogoObjectsRef.current[itemKey].reference
      ) {
        tmpLogoObjects = {
          ...tmpLogoObjects,
          [itemKey]: {
            ...addedFabricLogoObjectsRef.current[itemKey],
            src: '',
            indexLayer: fabricObjects.findIndex((item) => item.name === itemKey)
          }
        };
      }
    });

    let tmpTextObjects = {} as AddedTextItems;
    Object.keys(addedFabricTextObjectsRef.current).forEach((itemKey) => {
      if (addedFabricTextObjectsRef.current[itemKey].x !== 0 && addedFabricTextObjectsRef.current[itemKey].y !== 0) {
        tmpTextObjects = {
          ...tmpTextObjects,
          [itemKey]: {
            ...addedFabricTextObjectsRef.current[itemKey],
            indexLayer: fabricObjects.findIndex((item) => item.name === itemKey)
          },
        };
      }
    });

    const payload: SaveConfigState = {
      designHeaderId,
      visitorId: contextVisitorId || '222',
      configurationName,
      selectedDesign: selectedDesign,
      colorLogoHexRef: colorLogoHexRef.current,
      colorDotsLeftHexRef: colorDotsLeftHexRef.current,
      colorDotsRightHexRef: colorDotsRightHexRef.current,
      colorInvertedBgRef: colorInvertedBgRef.current,
      selectedJerseySectionColor,
      selectedJerseySectionPattern,
      addedFabricLogoObjects: tmpLogoObjects,
      addedFabricTextObjects: tmpTextObjects,
      jakoLogoPosition: jakoLogoPositionRef.current,
    };
    const jfnetSavePayloadString = JSON.stringify(payload);

    if (isTestSave) {
      return JfnetServices.saveConfigs('0', btoa(jfnetSavePayloadString));
    }

    if (!isSaveBeforeSubmitAddToCart) {
      return JfnetServices.saveConfigs(designHeaderId.toString(), btoa(jfnetSavePayloadString))
        .then(() => {
          updateSaveConfigDataRef.current = payload;

          // save SVG result file to jfnet API
          getSvgResultFile().then((blobRes) => {
            if (blobRes) {
              JfnetServices.savePrintFileMaster({
                designHeaderId: designHeaderId.toString(),
                printMaster: blobRes
              });
            }
          });
        });
    }

    return JfnetServices.saveConfigs(designHeaderId.toString(), btoa(jfnetSavePayloadString))
      .then(() => {
        updateSaveConfigDataRef.current = payload;

        // save SVG result file to jfnet API
        // getSvgResultFile().then((blobRes) => {
        //   if (blobRes) {
        //     JfnetServices.savePrintFileMaster({
        //       designHeaderId: designHeaderId.toString(),
        //       printMaster: blobRes
        //     });
        //   }
        // });
      });
  }

  async function handleSaveConfiguration(isSaveBeforeSubmitAddToCart?: boolean, isRefresh?: boolean) {
    function onSubmitSaveWithName(e: any, closeFn: () => void) {
      e.preventDefault();
      const el = document.getElementById('confirmAddName') as HTMLInputElement;
      const name: string = el ? el.value : '';
      if (!name) {
        return;
      }
      onSetLoading(true);
      const response = createJakoIndividualisation(name, isSaveBeforeSubmitAddToCart);
      if (response) {
        response.then((res) => {
          if (!res) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
            onSetLoading(false);
            return;
          }
          saveConfiguration({
            designHeaderId: res.data.designHeaderId,
            configurationName: name,
            isSaveBeforeSubmitAddToCart
          }).then(() => {
            if (isRefresh) {
              window.location.reload();
            } else if (isSaveBeforeSubmitAddToCart) {
              onCompleteConfiguration();
            } else {
              onSetLoading(false);
            }
          }).finally(() => {
            closeFn();
          });

        })
        .catch((err) => {
          closeFn();
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                  <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary !bg-theme-primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            }
          });
          onSetLoading(false);
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary !bg-theme-primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    }

    function confirmAddNameHandler() {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <div className="flex flex-col items-center mb-5">
                <IconSave className="w-16 h-16 mb-5" />
                <h5 className="font-semibold mb-5 text-[#141414] text-[25px]">
                  { t('configurator_3d_confirm_save_add_name_title') }
                </h5>
                <p className="text-[#141414]" dangerouslySetInnerHTML={{__html: t('configurator_3d_confirm_save_add_name_description').toString() || '' }} />
              </div>
              <form onSubmit={(e) => onSubmitSaveWithName(e, onClose)}>
                <input id="confirmAddName" type="text" className="mb-4" placeholder={t('configurator_3d_please_enter_name').toString()} />
                <div className="flex flex-col-reverse lg:flex-row justify-between">
                  <button className="outlineButton" type="button" onClick={onClose}>{ t('configurator_3d_cancel') }</button>
                  <button type="submit" className="button_primary !bg-theme-primary  mb-5 lg:mb-0">
                  { t('configurator_3d_submit') }
                  </button>
                </div>
              </form>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
    }

    if (!updateSaveConfigDataRef.current) {
      confirmAddNameHandler();
      return;
    }

    try {
      const response = updateJakoIndividualisation(updateSaveConfigDataRef.current.designHeaderId, isSaveBeforeSubmitAddToCart);
      if (response) {
        response.then(async (res) => {
          if (res && res.data && res.data.designHeaderId && updateSaveConfigDataRef.current) {
            const newUpdateDesignHeaderId = res.data.designHeaderId.toString();
            try {
              await saveConfiguration({
                designHeaderId: newUpdateDesignHeaderId,
                configurationName: updateSaveConfigDataRef.current.configurationName,
                isSaveBeforeSubmitAddToCart
              });
              if (isRefresh) {
                window.location.reload();
              } else if (isSaveBeforeSubmitAddToCart) {
                onCompleteConfiguration();
              } else {
                onSetLoading(false);
              }
            } catch {
              onSetLoading(false);
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                      <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                        { t('configurator_3d_error_something_wrong') }
                      </h5>
                      <button className="button_primary !bg-theme-primary" onClick={onClose}>
                        { t('configurator_3d_ok') }
                      </button>
                    </div>
                  );
                }
              });
            }
          } else if (updateSaveConfigDataRef.current) {
            try {
              await saveConfiguration({
                designHeaderId: updateSaveConfigDataRef.current.designHeaderId,
                configurationName: updateSaveConfigDataRef.current.configurationName,
                isSaveBeforeSubmitAddToCart
              });
              if (isRefresh) {
                window.location.reload();
              } else if (isSaveBeforeSubmitAddToCart) {
                onCompleteConfiguration();
              } else {
                onSetLoading(false);
              }
            } catch {
              onSetLoading(false);
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                      <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                        { t('configurator_3d_error_something_wrong') }
                      </h5>
                      <button className="button_primary !bg-theme-primary" onClick={onClose}>
                        { t('configurator_3d_ok') }
                      </button>
                    </div>
                  );
                }
              });
            }
          }
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                  { t('configurator_3d_error_something_wrong') }
                </h5>
                <button className="button_primary !bg-theme-primary" onClick={onClose}>
                  { t('configurator_3d_ok') }
                </button>
              </div>
            );
          }
        });
        onSetLoading(false);
      }
    } catch (err) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_error_something_wrong') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      onSetLoading(false);
    }
  }

  async function localDevelopmentTest_handleSaveConfiguration(isSaveBeforeSubmitAddToCart?: boolean, isRefresh?: boolean) {
    function onSubmitSaveWithNameTest(e: any, closeFn: () => void) {
      e.preventDefault();
      const el = document.getElementById('confirmAddName') as HTMLInputElement;
      const name: string = el ? el.value : '';
      if (!name) {
        return;
      }
      const response = new Promise((resolve) => {
        resolve('');
      });
      if (response) {
        onSetLoading(true);
        response.then(() => {
          saveConfiguration({
            designHeaderId: '111222333',
            configurationName: 'testname_111222333',
            isSaveBeforeSubmitAddToCart
          }).then(() => {
            if (isRefresh) {
              window.location.reload();
            } else if (isSaveBeforeSubmitAddToCart) {
              localDevelopmentTest_onCompleteConfiguration();
            } else {
              onSetLoading(false);
            }
          }).finally(() => {
            closeFn();
          });

        })
        .catch((err) => {
          closeFn();
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                  <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                    { t('configurator_3d_error_something_wrong') }
                  </h5>
                  <button className="button_primary !bg-theme-primary" onClick={onClose}>
                    { t('configurator_3d_ok') }
                  </button>
                </div>
              );
            },
            closeOnClickOutside: false,
            closeOnEscape: false
          });
          onSetLoading(false);
        });
      }
    }

    function confirmAddNameHandler() {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <div className="flex flex-col items-center mb-5">
                <IconSave className="w-16 h-16 mb-5" />
                <h5 className="font-semibold mb-5 text-[#141414] text-[25px]">
                  { t('configurator_3d_confirm_save_add_name_title') }
                </h5>
                <p className="text-[#141414]" dangerouslySetInnerHTML={{__html: t('configurator_3d_confirm_save_add_name_description').toString() || '' }} />
              </div>
              <form onSubmit={(e) => onSubmitSaveWithNameTest(e, onClose)}>
                <input id="confirmAddName" type="text" className="mb-4" placeholder={t('configurator_3d_please_enter_name').toString()} />
                <div className="flex flex-col-reverse lg:flex-row justify-between">
                  <button className="outlineButton" type="button" onClick={onClose}>{ t('configurator_3d_cancel') }</button>
                  <button type="submit" className="button_primary !bg-theme-primary  mb-5 lg:mb-0">
                  { t('configurator_3d_submit') }
                  </button>
                </div>
              </form>
            </div>
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false
      });
    }

    if (!updateSaveConfigDataRef.current) {
      confirmAddNameHandler();
      return;
    }

    try {
      // const response = updateJakoIndividualisation(updateSaveConfigDataRef.current.designHeaderId, isSaveBeforeSubmitAddToCart);
      const response = new Promise((resolve) => {
        resolve('');
      });
      if (response) {
        response.then(async (res) => {
          // if (res && res.data && res.data.designHeaderId && updateSaveConfigDataRef.current) {
          //   const newUpdateDesignHeaderId = res.data.designHeaderId.toString();
          //   await saveConfiguration({
          //     designHeaderId: newUpdateDesignHeaderId,
          //     configurationName: updateSaveConfigDataRef.current.configurationName,
          //     isSaveBeforeSubmitAddToCart
          //   });
          //   if (isSaveBeforeSubmitAddToCart) {
          //     onCompleteConfiguration();
          //   } else {
          //     onSetLoading(false);
          //   }
          // } else if (updateSaveConfigDataRef.current) {
          //   await saveConfiguration({
          //     designHeaderId: updateSaveConfigDataRef.current.designHeaderId,
          //     configurationName: updateSaveConfigDataRef.current.configurationName,
          //     isSaveBeforeSubmitAddToCart
          //   });
          //   if (isSaveBeforeSubmitAddToCart) {
          //     onCompleteConfiguration();
          //   } else {
          //     onSetLoading(false);
          //   }
          // }
          try {
            await saveConfiguration({
              designHeaderId: '111222333',
              configurationName: 'testname_111222333',
              isSaveBeforeSubmitAddToCart
            });
            if (isRefresh) {
              window.location.reload();
            } else if (isSaveBeforeSubmitAddToCart) {
              localDevelopmentTest_onCompleteConfiguration();
            } else {
              onSetLoading(false);
            }
          } catch {
            onSetLoading(false);
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
                    <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                      { t('configurator_3d_error_something_wrong') }
                    </h5>
                    <button className="button_primary !bg-theme-primary" onClick={onClose}>
                      { t('configurator_3d_ok') }
                    </button>
                  </div>
                );
              }
            });
          }
        });
      }
    } catch (err) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_error_something_wrong') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      onSetLoading(false);
    }
  }
  // ---- End of Left side UI handler ----------
  return (
    <main id="jako-configurator-3d-wc" className="jako configurator3D__wrapper">
      <div className="configurator__container">
        {/* <!-- Sidebar Steps --> */}
        {
          isLoadingCustomFields
            ? <></>
            : isMobile
              ? <div className="configurator__sidebarSteps">
                  <SimpleBar
                    style={{
                      height: 84,
                      width: '100%',
                    }}
                    autoHide={false}
                  >
                  {
                    menuTabFiltered.map((tabItem, index) =>
                      tabItem.fieldCode
                        ? customFields.length && customFields.find((field) => field.code === tabItem.fieldCode)
                          ? <div
                            key={'sidebarStepCustomField' + index + tabItem.fieldCode}
                            className={
                              "sidebarStep__item"
                              + (selectTab === tabItem.functionTab ? ' active' : '')
                            }
                            onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                          >
                            <div className="sidebarStep__icon">
                              <span className={"icon " + tabItem.icon} />
                            </div>
                            <div className="sidebarStep__description">
                              { t(tabItem.label) }
                            </div>
                          </div>
                          : <></>
                        : <div
                          key={'sidebarStep' + index + tabItem.label}
                          className={
                            "sidebarStep__item"
                            + (selectTab === tabItem.functionTab ? ' active' : '')
                          }
                          onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                        >
                          <div className="sidebarStep__icon">
                            <span className={"icon " + tabItem.icon} />
                          </div>
                          <div className="sidebarStep__description">
                            { t(tabItem.label) }
                          </div>
                        </div>
                    )
                  }
                </SimpleBar>
              </div>
              : <div className="configurator__sidebarSteps">
                <CustomScrollbar>
                  {
                    menuTabFiltered.map((tabItem, index) =>
                      tabItem.fieldCode
                        ? customFields.length && customFields.find((field) => field.code === tabItem.fieldCode)
                          ? <div
                            key={'sidebarStepCustomField' + index + tabItem.fieldCode}
                            className={
                              "sidebarStep__item"
                              + (selectTab === tabItem.functionTab ? ' active' : '')
                            }
                            onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                          >
                            <div className="sidebarStep__icon">
                              <span className={"icon " + tabItem.icon} />
                            </div>
                            <div className="sidebarStep__description">
                              { t(tabItem.label) }
                            </div>
                          </div>
                          : <></>
                        : <div
                          key={'sidebarStep' + index + tabItem.label}
                          className={
                            "sidebarStep__item"
                            + (selectTab === tabItem.functionTab ? ' active' : '')
                          }
                          onClick={() => onSetSelectTabHandler(tabItem.functionTab, index)}
                        >
                          <div className="sidebarStep__icon">
                            <span className={"icon " + tabItem.icon} />
                          </div>
                          <div className="sidebarStep__description">
                            { t(tabItem.label) }
                          </div>
                        </div>
                    )
                  }
                </CustomScrollbar>
              </div>
        }
        {/* <!-- End of Sidebar Steps --> */}
        {
          selectTab === FunctionTab.Cut
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
              {
                cuttingOptions.current.length
                  || ModelStyle.Short === selectModelStyle
                  ? <JakoCuttingTab
                    cuttingOptions={cuttingOptions.current}
                    cuttingFormLinksResponse={cuttingFormLinksResponseRef.current}
                    isEdited={isEditedMemo}
                    onSelectCut={onSelectCut}
                    onSelectMaterial={onSelectCutMaterial}
                    setSelectTabBack={setSelectTabBack}
                    setSelectTabNext={setSelectTabNext}
                    isMobile={!!isMobile}
                    onCloseMobile={handleOnCloseMobile}
                  />
                  : <></>
              }
            </SidebarContentWrapper>
            : <></>
        }
        {
          cuttingFileTemplateResponseRef.current && selectTab === FunctionTab.Design
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
              <JakoDesignTab
                blankoDesignFile={cuttingFileTemplateResponseRef.current.blankoDesignFile}
                blankoDesignPreviewFile={cuttingFileTemplateResponseRef.current.blankoDesignPreviewFile}
                designOptions={cuttingFileTemplateResponseRef.current.designs}
                isRaglan={jfnetCurrentCuttingFormCodeRef.current.includes('K4241')}
                selectedDesign={selectedDesign}
                onSelectDesign={onSelectDesign}
                onSelectCustomDesign={onSelectCustomDesign}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
            </SidebarContentWrapper>
            : <></>
        }
        {
          selectTab === FunctionTab.Pattern
          ? <SidebarContentWrapper
            showScrollDown={showScrollDownSidebar}
            sidebarScrollTopPos={sidebarScrollTopPos}
            sidebarScrollBoundingRect={sidebarScrollBoundingRect}
            isMobile={!!isMobile}
          >
            <JakoPatternTab
              modelStyle={selectModelStyle}
              jerseySection={patternJerseySection}
              possibleDesignAreaId={possibleDesignAreaId}
              selectedJerseySectionColor={selectedJerseySectionColor}
              selectedJerseySectionPattern={selectedJerseySectionPattern}
              patternList={patternList}
              colorList={sortedColorListRef.current}
              onSelectPatternSvg={onSelectPatternSvg}
              onSelectPatternFill={onSelectPatternFill}
              onSelectBgColor={onSelectColorFromPattern}
              onSetJerseySection={onSetPatternJerseySection}
              onPatternScale={onPatternScale}
              onPatternMoveOffset={onPatternMoveOffset}
              onPatternRotate={onPatternRotate}
              setSelectTabBack={setSelectTabBack}
              setSelectTabNext={setSelectTabNext}
              isMobile={!!isMobile}
              onCloseMobile={handleOnCloseMobile}
            />
          </SidebarContentWrapper>
          : <></>
        }
        {
          selectTab === FunctionTab.Color
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
              <JakoColorTab
                modelStyle={selectModelStyle}
                colorList={sortedColorList}
                possibleDesignAreaId={possibleDesignAreaId}
                selectedJerseySectionColor={selectedJerseySectionColor}
                onSelectColor={onSelectColor}
                setSelectTabBack={setSelectTabBack}
                setSelectTabNext={setSelectTabNext}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
            </SidebarContentWrapper>
            : <></>
        }
        {
          selectTab === FunctionTab.Logo
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
              <JakoLogoTab
                ref={jakoLogoTabRef}
                modelStyle={selectModelStyle}
                currentCuttingFormCode={currentCuttingFormCodeRef.current}
                selectedAddedLogoKey={selectedAddedLogoKey}
                addedLogoItems={addedFabricLogoObjects}
                jakoLogoPosition={jakoLogoPosition}
                colorList={sortedColorList}
                onChangeLogoColor={onChangeLogoColor}
                onClickLogoLock={onClickLogoLock}
                onClickDeleteLogo={onClickDeleteLogo}
                onSelectLogo={onSelectLogo}
                onChangeLogoPosition={onChangeLogoPosition}
                onChangeWidthSize={onChangeLogoWidthSize}
                onChangeHeightSize={onChangeLogoHeightSize}
                onSelectAddLogoPosition={onSelectAddLogoPosition}
                onSelectJakoLogoPosition={onSelectJakoLogoPosition}
                onSendLogoToFront={onSendToFront}
                onSendLogoToBack={onSendToBack}
                onAddLogoInput={onAddLogoInput}
                onSetLoading={onSetLoading}
                setSelectTabBack={setSelectTabBack}
                setSelectTabNext={setSelectTabNext}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
            </SidebarContentWrapper>
            : <></>
        }
        {
          selectTab === FunctionTab.Number
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
              <JakoNumberTab
                ref={jakoNumberTabRef}
                modelStyle={selectModelStyle}
                functionTab={FunctionTab.Number}
                selectedAddedTextKey={selectedAddedTextKey}
                addedTextItems={addedFabricTextObjects}
                showAddTextInputRef={showAddTextInputRef}
                showEditTextInputRef={showEditTextInputRef}
                colorList={sortedColorList}
                onClickTextLock={onClickTextLock}
                onClickDeleteText={onClickDeleteText}
                onChangeTextColor={onChangeTextColor}
                onChangeFontFamily={onChangeFontFamily}
                onChangeFontSizeWidth={onChangeFontSizeWidth}
                onChangeFontSizeHeight={onChangeFontSizeHeight}
                onChangePosition={onChangeTextPosition}
                onSelectText={onSelectText}
                onSubmitAddTextInput={onSubmitAddTextInputFromTab}
                onSubmitEditTextInput={onSubmitEditTextInput}
                onSelectAddNumberPosition={onSelectAddNumberPosition}
                onSendTextToFront={onSendToFront}
                onSendTextToBack={onSendToBack}
                onBack={onTextTabBack}
                setSelectTabBack={setSelectTabBack}
                setSelectTabNext={setSelectTabNext}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
            </SidebarContentWrapper>
            : <></>
        }

        {
          (selectTab === FunctionTab.Name
            || selectTab === FunctionTab.Initial)
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
                <JakoNameTab
                  ref={jakoNameTabRef}
                  modelStyle={selectModelStyle}
                  functionTab={selectTab}
                  selectedAddedTextKey={selectedAddedTextKey}
                  addedTextItems={addedFabricTextObjects}
                  showAddTextInputRef={showAddTextInputRef}
                  showEditTextInputRef={showEditTextInputRef}
                  colorList={sortedColorList}
                  onClickTextLock={onClickTextLock}
                  onClickDeleteText={onClickDeleteText}
                  onChangeTextColor={onChangeTextColor}
                  onSelectAddNamePosition={onSelectAddNamePosition}
                  onChangeFontFamily={onChangeFontFamily}
                  onChangeFontSizeWidth={onChangeFontSizeWidth}
                  onChangeFontSizeHeight={onChangeFontSizeHeight}
                  onChangePosition={onChangeTextPosition}
                  onSelectText={onSelectText}
                  onSubmitAddTextInput={onSubmitAddTextInputFromTab}
                  onSubmitEditTextInput={onSubmitEditTextInput}
                  onSendTextToFront={onSendToFront}
                  onSendTextToBack={onSendToBack}
                  onBack={onTextTabBack}
                  onSetTabName={onSetTabName}
                  setSelectTabBack={setSelectTabBack}
                  setSelectTabNext={setSelectTabNext}
                  isMobile={!!isMobile}
                  onCloseMobile={handleOnCloseMobile}
                />
              </SidebarContentWrapper>
            : <></>
        }

        {
          selectTab === FunctionTab.Teamname
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
                <JakoTeamnameTab
                  ref={jakoTeamnameTabRef}
                  modelStyle={selectModelStyle}
                  functionTab={FunctionTab.Teamname}
                  selectedAddedTextKey={selectedAddedTextKey}
                  addedTextItems={addedFabricTextObjects}
                  showAddTextInputRef={showAddTextInputRef}
                  showEditTextInputRef={showEditTextInputRef}
                  colorList={sortedColorList}
                  onClickTextLock={onClickTextLock}
                  onClickDeleteText={onClickDeleteText}
                  onChangeTextColor={onChangeTextColor}
                  onChangeFontFamily={onChangeFontFamily}
                  onChangeFontSizeWidth={onChangeFontSizeWidth}
                  onChangeFontSizeHeight={onChangeFontSizeHeight}
                  onChangePosition={onChangeTextPosition}
                  onSelectText={onSelectText}
                  onSubmitAddTextInput={onSubmitAddTextInputFromTab}
                  onSubmitEditTextInput={onSubmitEditTextInput}
                  onSelectAddTeamnamePosition={onSelectAddTeamnamePosition}
                  onSendTextToFront={onSendToFront}
                  onSendTextToBack={onSendToBack}
                  onBack={onTextTabBack}
                  setSelectTabBack={setSelectTabBack}
                  setSelectTabNext={setSelectTabNext}
                  isMobile={!!isMobile}
                  onCloseMobile={handleOnCloseMobile}
                />
              </SidebarContentWrapper>
            : <></>
        }

        {
          selectTab === FunctionTab.Text
            ? <SidebarContentWrapper
              showScrollDown={showScrollDownSidebar}
              sidebarScrollTopPos={sidebarScrollTopPos}
              sidebarScrollBoundingRect={sidebarScrollBoundingRect}
              isMobile={!!isMobile}
            >
              <JakoTextTab
                ref={jakoTextTabRef}
                functionTab={FunctionTab.Text}
                modelStyle={selectModelStyle}
                selectedAddedTextKey={selectedAddedTextKey}
                addedTextItems={addedFabricTextObjects}
                showAddTextInputRef={showAddTextInputRef}
                showEditTextInputRef={showEditTextInputRef}
                colorList={sortedColorList}
                onClickTextLock={onClickTextLock}
                onClickDeleteText={onClickDeleteText}
                onChangeTextColor={onChangeTextColor}
                onChangeFontFamily={onChangeFontFamily}
                onChangeFontSizeWidth={onChangeFontSizeWidth}
                onChangeFontSizeHeight={onChangeFontSizeHeight}
                onChangePosition={onChangeTextPosition}
                onSelectText={onSelectText}
                onSubmitAddTextInput={onSubmitAddTextInputFromTab}
                onSubmitEditTextInput={onSubmitEditTextInput}
                onSelectAddTextPosition={onSelectAddTextPosition}
                onSendTextToFront={onSendToFront}
                onSendTextToBack={onSendToBack}
                onBack={onTextTabBack}
                setSelectTabBack={setSelectTabBack}
                setSelectTabNext={setSelectTabNext}
                isMobile={!!isMobile}
                onCloseMobile={handleOnCloseMobile}
              />
            </SidebarContentWrapper>
            : <></>
        }

        {/* <!-- Main Area --> */}
        <div
          className="configurator__mainArea z-10"
        >
          <div className="mainArea__logo">
            <a href="/">
              <img alt="Logo JAKO" src="https://cdn.jako.de/userdata/images/Basics/logo-blue.svg" style={{height: '40px', width: '140px'}} />
            </a>
          </div>
          <div className="mainArea__menuControl flex flex-col">
            {
              selectTab === FunctionTab.Design
                && hasBackDesign
                && selectedDesign
                && selectedDesign.name !== 'Blanko'
                ? activateDesignOnBack
                  ? <button
                    className="outlineButton max-w-[110px] text-[11px] md:text-[14px] md:max-w-[150px] mt-4"
                    onClick={() => setActivateDesignOnBack(false)}
                  >
                    { t('configurator_3d_hide_back_design') }
                  </button>
                  : <button
                    className="outlineButton max-w-[110px] text-[11px] md:text-[14px] md:max-w-[150px] mt-4"
                    onClick={() => setActivateDesignOnBack(true)}
                  >
                    { t('configurator_3d_show_back_design') }
                  </button>
                : <></>
            }
            {
              selectTab === FunctionTab.Logo && selectedAddedLogoKey
                ? <>
                  {
                    addedFabricLogoObjects[selectedAddedLogoKey]
                      && (addedFabricLogoObjects[selectedAddedLogoKey].x !== addedFabricLogoObjects[selectedAddedLogoKey].initialX || addedFabricLogoObjects[selectedAddedLogoKey].y !== addedFabricLogoObjects[selectedAddedLogoKey].initialY)
                      ? <button
                        className="outlineButton max-w-[110px] text-[11px] md:text-[14px] md:max-w-[150px] mt-4"
                        onClick={resetDefaultPositionLogo}
                      >
                        { t('configurator_3d_reset_default_position') }
                      </button>
                      : <></>
                  }
                </>
                : selectedAddedTextKey
                  ? <>
                    {
                      addedFabricTextObjects[selectedAddedTextKey]
                        && (addedFabricTextObjects[selectedAddedTextKey].x !== addedFabricTextObjects[selectedAddedTextKey].initialX || addedFabricTextObjects[selectedAddedTextKey].y !== addedFabricTextObjects[selectedAddedTextKey].initialY)
                        ? <button
                          className="outlineButton max-w-[110px] text-[11px] md:text-[14px] md:max-w-[150px] mt-4"
                          onClick={resetDefaultPositionText}
                        >
                          { t('configurator_3d_reset_default_position') }
                        </button>
                        : <></>
                    }
                  </>
                  : <></>
            }
          </div>

          <div className="jfnet-mainArea__controls circle">
            {
              isMobile
                ? <>
                  <button className="absolute top-1/2 transform -translate-y-1/2 left-[8%] z-30" onClick={() => onControlAreaClick(ControlArea.RotateLeft)}>
                    <IconAngle className="h-[27px] md:h-[40px] text-[#C3C3C3]" />
                  </button>
                  <button className="absolute top-1/2 transform -translate-y-1/2 right-[8%] z-30" onClick={() => onControlAreaClick(ControlArea.RotateRight)}>
                    <IconAngle className="transform rotate-180 h-[27px] md:h-[40px] text-[#C3C3C3]" />
                  </button>
                  <IconCircleArea className="absolute top-3/4 transform -translate-y-[15px] md:h-[100px]" />
                  <button
                    className="absolute top-3/4 transform translate-y-[23px] py-4 px-8 bg-white min-w-[110px] text-[#C3C3C3] z-30 md:text-[22px] md:translate-y-[55px]"
                    onClick={() => moveCameraToFrontSide()}
                  >
                    { t('configurator_3d_front_side') }
                  </button>
                </>
                : <>
                  <div className="controlsCircle" />
                  <div className="controlBar">
                    <button
                      className="icon icon-3d-arrow-left"
                      onClick={() => onControlAreaClick(ControlArea.RotateLeft)}
                    />
                    <button
                      className="icon icon-3d-zoom-out"
                      onClick={() => onControlAreaClick(ControlArea.ZoomOut)}
                    />
                    <button
                      className="icon icon-3d-zoom-in"
                      onClick={() => onControlAreaClick(ControlArea.ZoomIn)}
                    />
                    <button
                      className="icon icon-3d-arrow-right"
                      onClick={() => onControlAreaClick(ControlArea.RotateRight)}
                    />
                    <div className="absolute pt-5 top-full left-1/2 transform -translate-x-1/2">
                      {
                        isMobile
                          ? <button className="absolute bottom-[100px] left-0">
                            <IconAngle className="h-[27px] text-[#BFBFBF]" />
                          </button>
                          : <></>
                      }
                      <button
                        className="control-front-btn button_primary"
                        onClick={() => moveCameraToFrontSide()}
                      >
                        { t('configurator_3d_front_side') }
                      </button>
                    </div>
                  </div>
                </>
              }
          </div>

          <div
            ref={canvasRef}
            id="configurator-container"
            className="relative top-[5%] mainArea__canvas !z-[9]"
            style={{
              width: isSavingScreenshot ? 800 : '100%',
              height: isSavingScreenshot ? 800 : '100%',
              minHeight: isSavingScreenshot ? 800 : '100%',
              pointerEvents: isSavingScreenshot ? 'none' : undefined
            }}
          >
            {/* <!-- JFNET WebComponent --> */}
            {/* Canvas is a React Three.js 3D wrapper */}
            { cuttingFileTemplateResponseRef.current && selectedDesign && initialized && <Canvas
              className="w-full h-full absolute"
              onCreated={onCanvasCreated}
              gl={{
                toneMapping: NoToneMapping,
                pixelRatio: 0.9,
                preserveDrawingBuffer: true,
              }}
              // dpr={[1,2]}
              linear
            >
              <Suspense fallback={null}>
                <group position={[0, 0.49 + yPosition, 0]}>
                  {/* This is the main scene 3D jersey configurator component */}
                  {/* You can see that there is no API at all. All you have to do is to pass the data into this component */}
                  <ConfiguratorModelFabric
                    ref={configuratorRef}

                    isMobile={!!isMobile}

                    activateDesignOnBack={activateDesignOnBack}
                    modelStyle={selectModelStyle}
                    currentCuttingFormCode={jfnetCurrentCuttingFormCodeRef.current}
                    text={addedText} // On adding text from input
                    logo={addedLogo} // On adding logo from input
                    addedTextItems={addedFabricTextObjects} // Added texts data
                    addedLogoItems={addedFabricLogoObjects}
                    jakoLogoPosition={jakoLogoPosition}
                    isAddingState={isAddingState}
                    addSectionPositionRef={addSectionPositionRef.current}
                    addSectionPart={addSectionPartRef.current}
                    designSvg={selectedDesign.file} // selected design svg
                    colorLogoHex={colorLogoHexRef.current}
                    colorDotsLeftHex={colorDotsLeftHexRef.current}
                    colorDotsRightHex={colorDotsRightHexRef.current}
                    colorInvertedBg={colorInvertedBgRef.current}
                    selectedJerseySectionColor={selectedJerseySectionColor} // selected color for each section (section is the layer id of each partial part of the design)
                    selectedJerseySectionPattern={selectedJerseySectionPattern}
                    selectedFontFamily={selectedFontFamily}
                    selectedTextFill={selectedTextFill}

                    modelFile={cuttingFileTemplateResponseRef.current.glbFile}
                    modelSeparateFile={cuttingFileTemplateResponseRef.current.glbSeparateFile}
                    normalMap={cuttingFileTemplateResponseRef.current.normalMapFile}
                    dotsLeft={cuttingFileTemplateResponseRef.current.dotsLeftFile}
                    dotsRight={cuttingFileTemplateResponseRef.current.dotsRightFile}
                    jakoLogoLeft={cuttingFileTemplateResponseRef.current.logoLeftFile}
                    jakoLogoRight={cuttingFileTemplateResponseRef.current.logoRightFile}
                    jakoLogoCenter={cuttingFileTemplateResponseRef.current.logoCenterFile}

                    onAddTextPosition={onAddTextPositionStart}
                    onAddImagePosition={onAddImagePositionStart}
                    onAddImageLogoInit={onAddImageLogoInit}
                    onEnableControl={onConfiguratorEnableControl}
                    onDisableControl={onConfiguratorDisableControl}
                    onTextTabBack={onTextTabBack}
                    onSetPossibleDesignAreaId={onSetPossibleDesignAreaId}
                    onSetLoading={onSetLoading}
                    onSetLoadingInitial={onSetLoadingInitial}
                    onUpdateTextFontSize={onUpdateTextFontSize}
                    onUpdateLogoSize={onUpdateLogoSize}
                    onDeleteFromControl={onDeleteFromFabric}
                    onSelectObjectItem={onSelectObjectItemFromFabric}
                    onFocusCamera={onFocusCamera}
                    onObjectModified={onConfiguratorModified}
                    onObjectAdded={onConfiguratorAdded}
                    onObjectDeleted={onConfiguratorDeleted}
                    onChangeTextColor={onConfiguratorChangeTextColor}
                    onChangeColor={onConfiguratorChangeColor}
                    onAddPattern={onConfiguratorAddPattern}
                    onCloseMobileTab={handleOnCloseMobile}
                    onSetHasBackDesign={handleOnSetHasBackDesign}
                  />
                </group>
              </Suspense>
              <PerspectiveCamera
                fov={30}
                position={isMobile ? [-0.22215543323018355, 1.3760002904351893, 6.859830184793832] : [-0.18716965860240095, 1.1828048040289152, 5.89680653016052]}
                getObjectsByProperty={undefined}
                makeDefault
              />
              <CameraControls
                ref={cameraControlsRef}
                minPolarAngle={1}
                maxPolarAngle={Math.PI / 2}
                minDistance={isMobile ? 2.2 : 2.6}
                maxDistance={isMobile ? 7 : 6}
                dollyToCursor={true}
                dollySpeed={0.5}
                smoothTime={0.09}
                draggingSmoothTime ={0.15}
                truckSpeed={0}
                onChange={onCameraUpdate}
              />
              <StudioSoLights />
              {/* <Perf position="bottom-left" /> */}
            </Canvas>
            }
          </div>

          <div className="mainArea__background" />
        </div>
        {/* <!-- End of Main Area --> */}
        <div className="configurator__bottomArea">
          <div className="bottomArea--left">
            <div
              className="bottomBar__item saveItem"
              onClick={() => handleSaveConfiguration()}
            >
              <i className='icon'>
                <IconSave className='w-10 h-10' />
              </i>
              <span className="textMedium">{t('configurator_3d_save')}</span>
            </div>
          </div>
          <div className="relative bottomArea--right">
            <button
              className={
                "button_primary"
              }
              onClick={onCompleteConfiguration}
            >
              { t('configurator_3d_complete_configuration') }
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isLoading}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0 invisible"
        enterTo="opacity-100 visible"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100 invisible"
        leaveTo="opacity-0 invisible"
      >
        <div
          id="hideDiv"
          className="loadingAnimation--full filter backdrop-blur !bg-transparent"
        >
          <div className="loadingAnimation__spinner">
            <div className="loadingAnimation_ring">
              <div/>
              <div/>
              <div/>
              <div/>
              <div className="icon icon-3d-loading"/>
              <div className="ringPlaceholder"/>
            </div>
          </div>
        </div>
      </Transition>

      <Transition
        show={isLoadingInitial || isLoadingCustomFields}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0 invisible"
        enterTo="opacity-100 visible"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100 invisible"
        leaveTo="opacity-0 invisible"
      >
        <div
          id="hideDiv"
          className="loadingAnimation--full"
        >
          <div className="loadingAnimation__spinner">
            <div className="loadingAnimation_ring">
              <div/>
              <div/>
              <div/>
              <div/>
              <div className="icon icon-3d-loading"/>
              <div className="ringPlaceholder"/>
            </div>
          </div>
        </div>
      </Transition>
    </main>
  );
}

JakoReactComponent.propTypes = {
  'correlationId': PropTypes.string,
  'visitorId': PropTypes.string,
  'designheaderid': PropTypes.string,
  'company': PropTypes.string,
  'shopCode': PropTypes.string,
  'cuttingFormCode': PropTypes.string,
  'basketurl': PropTypes.string,
  'languagecode': PropTypes.string,
  'baseapiurl': PropTypes.string,
  'uiapiurl': PropTypes.string,
  'itemno': PropTypes.string,
};

export default JakoReactComponent;
