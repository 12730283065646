import { useRef, useState, useMemo, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import { BaseContext } from '../contexts/BaseContext';
import type { NumberTabInterface , NumberAddPosition} from './types';
import { FunctionTab, JerseySection, ModelStyle } from './types';
import { FONT_OPTIONS, NUMBER_ADD_POSITION, CM_FACTOR } from './constants';

import IconPosition from './icons/IconPosition';
import IconSize from './icons/IconSize';
import IconMoveForward from './icons/IconMoveForward';
import IconMoveBackward from './icons/IconMoveBackward';
import IconTrash from './icons/IconTrash';
import IconCross from './icons/IconCross';
import IconFonts from './icons/IconFonts';

export type JakoNumberTabHandler = {
  openOptionByName: (objectName: string) => void,
};

enum MobileFunction {
  Home,
  Position,
  Size,
  Font,
  Color
}

const JakoNumberTab = forwardRef<JakoNumberTabHandler, NumberTabInterface>(({
  modelStyle,
  functionTab,
  selectedAddedTextKey,
  addedTextItems,
  showAddTextInputRef,
  showEditTextInputRef,
  colorList,
  onSubmitAddTextInput,
  onSubmitEditTextInput,
  onSelectAddNumberPosition,
  onClickTextLock,
  onClickDeleteText,
  onSelectText,
  onChangeTextColor,
  onChangeFontSizeWidth,
  onChangeFontSizeHeight,
  onChangeFontFamily,
  onChangePosition,
  onSendTextToFront,
  onSendTextToBack,
  setSelectTabBack,
  setSelectTabNext,
  isMobile,
  onCloseMobile
}, ref) => {
  useImperativeHandle(ref, () => ({
    openOptionByName(name: string) {
      let results = {};
      addedItemsOfSelectedTab.forEach((itemKey) => {
        results = {
          ...results,
          [itemKey]: itemKey === name
        };
      });
      setShowOption(results);
    },
  }));
  const { customFields } = useContext(BaseContext);

  const [selectMobileFunction, setSelectMobileFunction] = useState<MobileFunction | null>(MobileFunction.Home);
  const [hasMoreScrollContainer, setHasMoreScrollContainer] = useState(false);
  const scrollBarRef = useRef(null as any);

  const [showOption, setShowOption] = useState<any>({});
  const [selectedPosition, setSelectedPosition] = useState<{ [key: string]: NumberAddPosition }>({});
  const [showAddPosition, setShowAddPosition] = useState(false);

  const [showMoreColor, setShowMoreColor] = useState(false);
  const [showFontFamily, setShowFontFamily] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [addSectionPosition, setAddSectionPosition] = useState<NumberAddPosition | null>(null);
  const addSectionPart = JerseySection.Front;

  // const [initialized, setInitialized] = useState(true);
  const inputWidthRef = useRef(null as any);
  const inputHeightRef = useRef(null as any);
  const [inputSizeWidth, setInputSizeWidth] = useState({} as { [key: string]: number });
  const [inputSizeHeight, setInputSizeHeight] = useState({} as { [key: string]: number });

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    scrollBarRef.current.getScrollElement()
      .addEventListener("scroll", function(e: any) { onScrollContainerUpdate(e.target); });
  }, []);

  useEffect(() => {
    if (!scrollBarRef.current) {
      return;
    }

    setHasMoreScrollContainer(false);
    onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
  }, [selectMobileFunction]);

  useEffect(() => {
    if (selectedAddedTextKey && addedTextItems[selectedAddedTextKey]) {
      const widthCm = addedTextItems[selectedAddedTextKey].scaledWidth;
      const widthFloatToFixedOneBackToFloat = Math.round(widthCm * 10) / 10;
      if (inputWidthRef.current) {
        inputWidthRef.current.value = widthFloatToFixedOneBackToFloat;
      }
      const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
      if (getWidthInput) {
        getWidthInput.value = widthFloatToFixedOneBackToFloat;
      }
      setInputSizeWidth((prevState) => ({
        ...prevState,
        [selectedAddedTextKey]: widthFloatToFixedOneBackToFloat
      }));

      const heightCm = addedTextItems[selectedAddedTextKey].scaledHeight;
      const heightFloatToFixedOneBackToFloat = Math.round(heightCm * 10) / 10;
      if (inputHeightRef.current) {
        inputHeightRef.current.value = heightFloatToFixedOneBackToFloat;
      }
      const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
      if (getHeightInput) {
        getHeightInput.value = heightFloatToFixedOneBackToFloat;
      }
      setInputSizeHeight((prevState) => ({
        ...prevState,
        [selectedAddedTextKey]: heightFloatToFixedOneBackToFloat
      }));
      const addedTextPosition = addedTextItems[selectedAddedTextKey].position as NumberAddPosition;
      setSelectedPosition({
        ...selectedPosition,
        [selectedAddedTextKey]:
          selectedPosition[selectedAddedTextKey]
            ? selectedPosition[selectedAddedTextKey]
            : addedTextPosition
      });
    } else {
      let results = {};
      addedItemsOfSelectedTab.forEach((itemKey) => {
        results = {
          ...results,
          [itemKey]: false
        };

        if (addedTextItems[itemKey].position) {
          const pos = addedTextItems[itemKey].position as NumberAddPosition;
          setSelectedPosition({
            ...selectedPosition,
            [itemKey]: pos
          });
        }
      });
      setShowOption(results);
      setShowMoreColor(false);
      setShowAddPosition(false);
      setShowFontFamily(false);
    }
    // onSelectAddNumberPosition(addSectionPart, addSectionPosition);
    // eslint-disable-next-line
  }, [addedTextItems, selectedAddedTextKey]);

  useEffect(() => {
    /* TOOLTIPS */
    // Get an array of all items on the page with class 'tooltip'.
    if (!selectedAddedTextKey) {
      return;
    }
    if (!isMobile) {
      return;
    }
    setTimeout(() => {
      const tooltipList = document.querySelectorAll('.number-tab .tooltip');
      // Loop through that array
      tooltipList.forEach(function(el) {
        const element = el as any;
        if (el.children.length) {
          return;
        }
        // Create a <label> element
        const tooltip = document.createElement('label');
        // Give it a class of 'tooltipBubble'
        tooltip.classList.add('tooltipBubble');
        // Set the text inside that element to be our 'data-tooltip' value
        tooltip.innerHTML = element.dataset.tooltip;
        // Then insert it in the element we're on in the loop
        element.appendChild(tooltip);
      });
    }, 300);
  }, [selectedAddedTextKey, colorList, isMobile]);

  const { t } = useTranslation('translations');

  const customFieldTab = useMemo(() => customFields.find((item) => item.code === '3D_NUMMER'), [customFields]);

  const tabHeadline = useMemo(() => {
    return t('configurator_3d_add_number');
  }, [t]);
  const tabDescription = useMemo(() => {
    return t('configurator_3d_add_number_text');
  }, [t]);
  const inputPlaceholder = t('configurator_3d_input_number_placeholder');

  const addedItemsOfSelectedTab = useMemo(() => {
    return Object.keys(addedTextItems).slice()
      .filter((itemKey) => addedTextItems[itemKey].functionTab === FunctionTab.Number);
  }, [addedTextItems]);

  const isNoPositionInput = useMemo(() =>
    selectedAddedTextKey
      ? selectedPosition[selectedAddedTextKey] === null || selectedPosition[selectedAddedTextKey] === undefined
      : false
  , [selectedPosition, selectedAddedTextKey]);

  const mobileTabTitle = useMemo(() => {
    if (!selectedAddedTextKey) {
      return t('configurator_3d_add_number');
    }
    if (isNoPositionInput) {
      return t('configurator_3d_choose_position');
    }
    if (selectMobileFunction === MobileFunction.Font) {
      return t('configurator_3d_font');
    }
    if (selectMobileFunction === MobileFunction.Color) {
      return t('configurator_3d_color');
    }
    if (selectMobileFunction !== null && selectMobileFunction !== MobileFunction.Home) {
      return '';
    }
    if (selectMobileFunction !== null) {
      return '';
    }
    return '';
  }, [selectedAddedTextKey, selectMobileFunction, t, isNoPositionInput]);

  const addPositionOptionKeys = useMemo(() => modelStyle === ModelStyle.Short
    ? Object.keys(NUMBER_ADD_POSITION).filter((key) => key.includes('Short_'))
    : Object.keys(NUMBER_ADD_POSITION).filter((key) => !key.includes('Short_'))
  , [modelStyle]);

  function handleOnSelectText(name: string) {
    if (name === selectedAddedTextKey) {
      handleSetShowMoreOption(name);

      if (showOption[name]) {
        onSelectText(null);
      } else {
        onSelectText(name);
      }
      return;
    }
    onSelectText(name);
    let results = {};
    addedItemsOfSelectedTab.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: itemKey === name
      };
    });
    setShowOption(results);
  }

  function handleSetShowMoreOption(name: string) {
    let results = {};
    addedItemsOfSelectedTab.forEach((itemKey) => {
      results = {
        ...results,
        [itemKey]: false
      };
    });
    results = {
      ...results,
      [name]: !showOption[name],
    };
    setShowOption(results);
  }

  function handleOnDecreaseFontSize(dimension: string) {
      if (!selectedAddedTextKey) {
        return;
      }
      if (dimension === 'width') {
        const currentValue = inputSizeWidth[selectedAddedTextKey];
        if (currentValue - 0.1 < 1) {
          return;
        }
        const widthCmToPixel = (currentValue - 0.1) * CM_FACTOR;
        onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
      } else {
        const currentValue = inputSizeHeight[selectedAddedTextKey];
        if (currentValue - 0.1 < 1) {
          return;
        }
        const heightCmToPixel = (currentValue - 0.1) * CM_FACTOR;
        onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
      }
  }
  function handleOnIncreaseFontSize(dimension: string) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (dimension === 'width') {
      const currentValue = inputSizeWidth[selectedAddedTextKey];
      const widthCmToPixel = (currentValue + 0.1) * CM_FACTOR;
      onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
    } else {
      const currentValue = inputSizeHeight[selectedAddedTextKey];
      const heightCmToPixel = (currentValue + 0.1) * CM_FACTOR;
      onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
    }
  }
  function handleOnChangeWidthFontSize(event: any, fontSize:  number) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = 1;
          const widthCmToPixel = 1 * CM_FACTOR;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
        const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = 1;
          const widthCmToPixel = 1 * CM_FACTOR;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
      } else {
        if (inputWidthRef.current) {
          inputWidthRef.current.value = inputSizeWidth[selectedAddedTextKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedTextKey] * CM_FACTOR;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
        const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
        if (getWidthInput) {
          getWidthInput.value = inputSizeWidth[selectedAddedTextKey];
          const widthCmToPixel = inputSizeWidth[selectedAddedTextKey] * CM_FACTOR;
          onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputWidthRef.current) {
      inputWidthRef.current.value = floatToFixedOneBackToFloat;
    }
    const getWidthInput = document.getElementById(`inputQtyWidth_${selectedAddedTextKey}`) as any;
    if (getWidthInput) {
      getWidthInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeWidth((prevState) => ({
      ...prevState,
      [selectedAddedTextKey]: floatToFixedOneBackToFloat
    }));

    const widthCmToPixel = parseFloat(event.target.value) * CM_FACTOR;
    onChangeFontSizeWidth(selectedAddedTextKey, widthCmToPixel);
  }
  function handleOnChangeHeightFontSize(event: any) {
    if (!selectedAddedTextKey) {
      return;
    }
    if (!event.target.value) {
      if (parseInt(event.target.value) < 1) {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = 1;
          const heightCmToPixel = 1 * CM_FACTOR;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
        const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = 1;
          const heightCmToPixel = 1 * CM_FACTOR;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
      } else {
        if (inputHeightRef.current) {
          inputHeightRef.current.value = inputSizeHeight[selectedAddedTextKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedTextKey] * CM_FACTOR;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
        const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
        if (getHeightInput) {
          getHeightInput.value = inputSizeHeight[selectedAddedTextKey];
          const heightCmToPixel = inputSizeHeight[selectedAddedTextKey] * CM_FACTOR;
          onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
        }
      }
      return;
    }
    const floatToFixedOneBackToFloat = Math.round(parseFloat(event.target.value) * 10) / 10;
    if (inputHeightRef.current) {
      inputHeightRef.current.value = floatToFixedOneBackToFloat;
    }
    const getHeightInput = document.getElementById(`inputQtyHeight_${selectedAddedTextKey}`) as any;
    if (getHeightInput) {
      getHeightInput.value = floatToFixedOneBackToFloat;
    }
    setInputSizeHeight((prevState) => ({
      ...prevState,
      [selectedAddedTextKey]: floatToFixedOneBackToFloat
    }));

    const heightCmToPixel = parseFloat(event.target.value) * CM_FACTOR;
    onChangeFontSizeHeight(selectedAddedTextKey, heightCmToPixel);
  }

  function handleOnSubmitInput (
    e: any,
    functionTab: FunctionTab,
    part: JerseySection,
    position: NumberAddPosition | null
  ) {
    if (position === null) {
      e.preventDefault();
      nextStepAddPosition(e, FunctionTab.Number, part, position);
      return;
    }

    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }
    if (!customFieldTab) {
      nextStepAddPosition(e, FunctionTab.Number, part, position);
      return;
    }
    if (!customFieldTab.requireUniqueValue) {
      nextStepAddPosition(e, FunctionTab.Number, part, position);
      return;
    }
    const foundedSameText = addedItemsOfSelectedTab.length === 0 || addedItemsOfSelectedTab.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      nextStepAddPosition(e, FunctionTab.Number, part, position);
    } else {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      e.target[0].value = '';
    }
  }
  function nextStepAddPosition(
    e: any,
    functionTab: FunctionTab,
    part: JerseySection,
    position: NumberAddPosition | null
  ) {
    e.preventDefault();
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }

    if (!customFieldTab) {
      showSelectAddPositionHandler(e);
      return;
    }
    if (!customFieldTab.requireUniqueValue) {
      showSelectAddPositionHandler(e);
      return;
    }
    const foundedSameText = addedItemsOfSelectedTab.length === 0 || addedItemsOfSelectedTab.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      showSelectAddPositionHandler(e);
    } else {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      e.target[0].value = '';
      setAddSectionPosition(null);
    }
  }
  function showSelectAddPositionHandler(e: any) {
    setAddSectionPosition(null);
    onSubmitAddTextInput(functionTab);
    setTimeout(() => {
      e.target[0].value = '';
    }, 500);
  }

  function handleOnSubmitEdit(
    e: any,
    functionTab: FunctionTab,
  ) {
    const value = e.target[0] ? e.target[0].value : '';
    if (value.toString().replaceAll(/\s/g,'').length === 0) {
      e.preventDefault();
      return;
    }
    if (!selectedAddedTextKey) {
      e.preventDefault();
      return;
    }
    if (!customFieldTab) {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      return;
    }

    if (!customFieldTab.requireUniqueValue) {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      return;
    }
    const foundedSameText = addedItemsOfSelectedTab.length === 0 || addedItemsOfSelectedTab.find((itemKey) => addedTextItems[itemKey].text === value);
    if (foundedSameText) {
      e.preventDefault();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div id="jako-configurator-3d-wc" className="jako w-full min-w-[280px] mx-auto p-6 bg-white md:p-0 md:w-full md:min-w-[480px]">
              <h5 className="block text-[24px] md:text-[2.25rem] font-bold mb-3">
                { t('configurator_3d_require_unique_value') }
              </h5>
              <button className="button_primary !bg-theme-primary" onClick={onClose}>
                { t('configurator_3d_ok') }
              </button>
            </div>
          );
        }
      });
      setIsEdit(false);
    } else {
      onSubmitEditTextInput(selectedAddedTextKey, e, functionTab);
      setIsEdit(false);
      e.preventDefault();
    }
  }

  function handleClickEditBtn(selectText?: string) {
    if (!selectText) {
      setIsEdit((prevState) => !prevState);
      setTimeout(() => {
        showEditTextInputRef.current?.focus();
      }, 100);
      return;
    }

    onSelectText(selectText);
    setTimeout(() => {
      setIsEdit((prevState) => !prevState);
      setTimeout(() => {
        showEditTextInputRef.current?.focus();
      }, 100);
    }, 200);
  }

  function handleOnChangePosition(name: string, positionKey: NumberAddPosition, isInit?: boolean) {
    setSelectedPosition({
      ...selectedPosition,
      [name]: NUMBER_ADD_POSITION[positionKey].position
    });
    onSelectAddNumberPosition(NUMBER_ADD_POSITION[positionKey].jerseySection, NUMBER_ADD_POSITION[positionKey].position, true);
    onChangePosition(name, positionKey, NUMBER_ADD_POSITION[positionKey].jerseySection, isInit);
    onCloseMobile();

    setTimeout(() => {
      if (scrollBarRef.current) {
        onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
      }
    }, 1000);
  }

  function onScrollContainerUpdate(el: any) {
    if (el.clientHeight + el.scrollTop >= el.scrollHeight) {
      setHasMoreScrollContainer(false);
    } else {
      setHasMoreScrollContainer(true);
    }
  }

  function handleOnClickCloseMobile() {
    if (scrollBarRef.current) {
      onScrollContainerUpdate(scrollBarRef.current.getScrollElement());
    }
    if (!selectedAddedTextKey) {
      onCloseMobile();
      return;
    }
    if (isNoPositionInput) {
      onSelectText(null);
      return;
    }
    if (selectMobileFunction === MobileFunction.Home) {
      setHasMoreScrollContainer(false);
    }
    if (selectMobileFunction !== null && selectMobileFunction !== MobileFunction.Home) {
      setSelectMobileFunction(MobileFunction.Home);
      return;
    }
    if (selectMobileFunction !== null) {
      onSelectText(null);
      return;
    }
    onCloseMobile();
  }

  return isMobile
  ? <div
    className={
      "relative w-full px-6 pt-10 pb-6 "
      // + (
      //   selectedAddedTextKey
      //     ? isNoPositionInput || selectMobileFunction === MobileFunction.Position || selectMobileFunction === MobileFunction.Font || selectMobileFunction === MobileFunction.Color
      //       ? 'h-[280px]'
      //       : 'h-[180px]'
      //     : 'h-[200px]'
      // )
    }
  >
    <button
      className="absolute top-[21px] right-[7px] z-10"
      onClick={handleOnClickCloseMobile}
    >
      <IconCross className="h-[26px]" />
    </button>
    <p className="text-[#141414] text-[14px] font-semibold text-center min-h-[21px] pb-5">
      { mobileTabTitle }
    </p>
    <SimpleBar
      ref={scrollBarRef}
      style={{
        width: '100%',
        height: selectedAddedTextKey
          ? isNoPositionInput || selectMobileFunction === MobileFunction.Position || selectMobileFunction === MobileFunction.Font || selectMobileFunction === MobileFunction.Color
            ? '221px'
            : 'auto'
          : '142px'
      }}
      autoHide={false}
      onScroll={onScrollContainerUpdate}
    >
      {
        selectedAddedTextKey
          ? isNoPositionInput
            ? <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-10 pr-[16px]">
              {
                addPositionOptionKeys.map((addPositionKey, index) => {
                  const position = addPositionKey as NumberAddPosition;
                  return <button
                    key={'OPEN_NUMBER_ADD_POSITION_INITIAL' + selectedAddedTextKey + index}
                    className="bg-white group flex flex-col items-center w-[125px]"
                    onClick={() => handleOnChangePosition(selectedAddedTextKey, position, true)}
                  >
                    <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                      { t(NUMBER_ADD_POSITION[position].label) }
                    </p>
                    <div
                      className={
                        "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                        + (selectedPosition[selectedAddedTextKey] === NUMBER_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                      }
                    >
                      <img
                        src={NUMBER_ADD_POSITION[position].thumbnail}
                        alt=""
                        className="absolute !w-full h-[220px] object-cover"
                        style={{
                          objectPosition: 'center ' + NUMBER_ADD_POSITION[position].thumbnailYPostionOption
                        }}
                      />
                    </div>
                  </button>;
                })
              }
            </div>
            : <>
            {
              selectMobileFunction === MobileFunction.Home
                ? <div className="mt-5 pr-[16px]">
                  <div className="flex items-center justify-between mb-5">
                    {
                      isEdit
                      ? <form onSubmit={(event) => handleOnSubmitEdit(event, FunctionTab.Number)} className="w-full form-group space--between">
                        <div className="inputInlineBtn saveInput">
                          <input
                            ref={showEditTextInputRef}
                            inputMode="numeric" pattern="[0-9]*" type="text"
                            className="text"
                            id="input_text"
                            placeholder={inputPlaceholder}
                            defaultValue={addedTextItems[selectedAddedTextKey].text}
                            maxLength={customFieldTab?.maxLength}
                          />
                        </div>
                        <button type="submit" className="iconButton !text-sm">
                          { t('configurator_3d_save_edit_text') }
                        </button>
                      </form>
                      : <p className="font-bold text-[14px]">
                        { addedTextItems[selectedAddedTextKey].text }
                      </p>
                    }
                    {
                      isEdit
                      ? <></>
                      : <div className="flex gap-4">
                        <button onClick={() => setIsEdit(true)} className="iconButton !text-sm">
                          { t('configurator_3d_save_edit_text') }
                        </button>
                        <button
                          className="relative iconButton delete z-20 text-[40px] text-[#141414]"
                          onClick={() => onClickDeleteText(selectedAddedTextKey)}
                        >
                          <IconTrash />
                        </button>
                      </div>
                    }
                  </div>
                  <div className="grid grid-cols-6 justify-center overflow-hidden">
                    <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Position)}>
                      <IconPosition className="h-[40px] text-[#141414]" />
                      <span className="text-[12px] whitespace-nowrap font-medium text-[#141414]">
                        { t('configurator_3d_position') }
                      </span>
                    </button>
                    <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Size)}>
                      <IconSize className="h-[40px] text-[#141414]" />
                      <span className="text-[12px] whitespace-nowrap font-medium text-[#141414]">
                        { t('configurator_3d_size') }
                      </span>
                    </button>
                    <button className="flex flex-col items-center group" onClick={() => onSendTextToFront(selectedAddedTextKey)}>
                      <IconMoveForward className="h-[40px] text-[#141414]" />
                      <span className="text-[12px] whitespace-nowrap font-medium text-[#141414]">
                        { t('configurator_3d_foreground') }
                      </span>
                    </button>
                    <button className="flex flex-col items-center group" onClick={() => onSendTextToBack(selectedAddedTextKey)}>
                      <IconMoveBackward className="h-[40px] text-[#141414]" />
                      <span className="text-[12px] whitespace-nowrap font-medium text-[#141414]">
                        { t('configurator_3d_background') }
                      </span>
                    </button>
                    <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Font)}>
                      <IconFonts className="h-[40px] text-[#141414]"  />
                      <span className="text-[12px] whitespace-nowrap font-medium text-[#141414]">
                        { t('configurator_3d_font') }
                      </span>
                    </button>
                    <button className="flex flex-col items-center group" onClick={() => setSelectMobileFunction(MobileFunction.Color)}>
                      <div className="relative w-[40px] h-[40px] min-w-[40px] border border-[#141414]">
                        <div
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[30px] h-[30px]"
                          style={{
                            backgroundColor: addedTextItems[selectedAddedTextKey].fill
                          }}
                        />
                      </div>
                      <span className="text-[12px] whitespace-nowrap font-medium text-[#141414]">
                        { t('configurator_3d_color') }
                      </span>
                    </button>
                  </div>
                </div>
                : <></>
            }
            {
              selectMobileFunction === MobileFunction.Position
                ? <div className="pr-[16px]">
                  <p className="text-[#141414] text-[14px] font-semibold text-center mt-5">
                    { t('configurator_3d_choose_position') }
                  </p>
                  <div className="grid grid-cols-2 items-center justify-items-center gap-x-5 gap-y-4 mt-5">
                    {
                      addPositionOptionKeys.map((addPositionKey, index) => {
                        const position = addPositionKey as NumberAddPosition;
                        return <button
                          key={'NUMBER_ADD_POSITION' + selectedAddedTextKey + index}
                          className="bg-white group flex flex-col items-center w-[125px]"
                          onClick={() => handleOnChangePosition(selectedAddedTextKey, position)}
                        >
                          <p className="font-semibold text-[#141414] mb-3 text-[12px] whitespace-nowrap">
                            { t(NUMBER_ADD_POSITION[position].label) }
                          </p>
                          <div
                            className={
                              "relative w-full h-[150px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                              + (selectedPosition[selectedAddedTextKey] === NUMBER_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                            }
                          >
                            <img
                              src={NUMBER_ADD_POSITION[position].thumbnail}
                              alt=""
                              className="absolute !w-full h-[220px] object-cover"
                              style={{
                                objectPosition: 'center ' + NUMBER_ADD_POSITION[position].thumbnailYPostionOption
                              }}
                            />
                          </div>
                        </button>;
                      })
                    }
                  </div>
                </div>
                : <></>
            }
            {
              selectMobileFunction === MobileFunction.Size
                ? <div className="mt-10 pr-[16px]">
                  <div className="flex items-center justify-around">
                    <div className="flex flex-col items-center">
                      <p className="text-[14px] mb-5">
                        { t('configurator_3d_font_size') } { t('configurator_3d_height_cm') }
                      </p>
                      <div className="quantity quantitySizes">
                        <div className="spinner_wrapper">
                          <div
                            className="spinner_down"
                            onClick={() => handleOnDecreaseFontSize('height')}
                          >
                            <i className="icon icon-spinner-down"/>
                          </div>
                          {/* // 1cm = 37.79527559055118px */}
                          <input
                            ref={inputHeightRef}
                            defaultValue={Math.round((addedTextItems[selectedAddedTextKey].scaledHeight) * 10) / 10}
                            className="input_quantity_line"
                            name="input_item_quantity_sizes_"
                            id="input_item_quantity_sizes_"
                            inputMode="numeric" pattern="[0-9]*" type="text"
                            onFocus={(e) => e.target.select()}
                            onChange={(event) => handleOnChangeHeightFontSize(event)}
                          />
                          <div
                            className="spinner_up"
                            onClick={() => handleOnIncreaseFontSize('height')}
                          >
                            <i className="icon icon-spinner-up"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-[14px] mb-5">
                        { t('configurator_3d_font_size') } { t('configurator_3d_width_cm') }
                      </p>
                      <div className="quantity quantitySizes">
                        <div className="spinner_wrapper">
                          <div
                            className="spinner_down"
                            onClick={() => handleOnDecreaseFontSize('width')}
                          >
                            <i className="icon icon-spinner-down"/>
                          </div>
                          <input
                            ref={inputWidthRef}
                            defaultValue={Math.round((addedTextItems[selectedAddedTextKey].scaledWidth) * 10) / 10}
                            className="input_quantity_line"
                            name="input_item_quantity_sizes_"
                            id="input_item_quantity_sizes_"
                            inputMode="numeric" pattern="[0-9]*" type="text"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleOnChangeWidthFontSize(e, addedTextItems[selectedAddedTextKey].scaledWidth)}
                          />
                          <div
                            className="spinner_up"
                            onClick={() => handleOnIncreaseFontSize('width')}
                          >
                            <i className="icon icon-spinner-up"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <></>
            }
            {
              selectMobileFunction === MobileFunction.Font
              ? <div className="mt-10 pr-[16px]">
                  <div className="itemsWrapper--1col">
                    {
                      FONT_OPTIONS.map((font, index) =>
                        <div
                          key={'fontOption' + index + font.src}
                          className={
                            "itemTemplate "
                            + (font.fontFamily === addedTextItems[selectedAddedTextKey].fontFamily ? 'active' : '')
                          }
                          onClick={() => onChangeFontFamily(selectedAddedTextKey, font.fontFamily)}
                        >
                          <div className="itemTemplate__description" data-value={font.fontFamily}>
                            <span
                              style={{
                                fontFamily: font.fontFamily
                              }}
                            >
                              {font.fontFamily}
                            </span>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                : <></>
            }
            {
              selectMobileFunction === MobileFunction.Color
              ? <div className="pr-[16px]">
                  <div className="colorWrapper colorWrapper--responsive !pr-0">
                    {
                      colorList.map((itemColor, index) =>
                        <div
                          key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                          className={
                            "itemColor "
                            + (addedTextItems[selectedAddedTextKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                          }
                          onClick={() => onChangeTextColor('#' + itemColor.colorHexCode)}
                        >
                          <div
                            className="itemColor--bgColor tooltip"
                            style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
                : <></>
            }
          </>
          : addedItemsOfSelectedTab.length
            ? <div className="flex flex-col gap-5">
              <form onSubmit={(event) => handleOnSubmitInput(event, functionTab, addSectionPart, addSectionPosition)} className="form-group space--between mt-10">
                <div className="inputInlineBtn saveInput">
                  <input
                    ref={showAddTextInputRef}
                    inputMode="numeric" pattern="[0-9]*" type="text"
                    className="text"
                    id="input_name"
                    placeholder={inputPlaceholder}
                    maxLength={customFieldTab ? customFieldTab.maxLength : 30}
                  />
                </div>
                <button type="submit" className="iconButton add">
                  <span className="icon icon-3d-plus"/>
                </button>
              </form>
              {
                addedItemsOfSelectedTab.map((itemKey, index: number) =>
                  <div key={'addedTeamnameObjMobile' + itemKey + addedTextItems[itemKey].text + index}>
                    <form onClick={() => handleOnSelectText(itemKey)} className="form-group space--between border-b border-[#F0F0F0]">
                      <div className="inputInlineBtn saveInput">
                        <input
                          inputMode="numeric" pattern="[0-9]*" type="text"
                          className="text pointer-events-none"
                          id="input_text"
                          placeholder={inputPlaceholder}
                          defaultValue={addedTextItems[itemKey].text}
                          maxLength={customFieldTab?.maxLength}
                        />
                      </div>
                      <button
                        type="button"
                        className="relative iconButton z-20"
                        onClick={() => onClickDeleteText(itemKey)}
                      >
                        <i className="icon icon-3d-trash" />
                      </button>
                    </form>
                  </div>
                )
              }
            </div>
            : <form onSubmit={(event) => handleOnSubmitInput(event, functionTab, addSectionPart, addSectionPosition)} className="form-group space--between mt-10">
              <div className="inputInlineBtn saveInput">
                <input
                  ref={showAddTextInputRef}
                  inputMode="numeric" pattern="[0-9]*" type="text"
                  className="text"
                  id="input_name"
                  placeholder={inputPlaceholder}
                  maxLength={customFieldTab ? customFieldTab.maxLength : 30}
                />
              </div>
              <button type="submit" className="iconButton add">
                <span className="icon icon-3d-plus"/>
              </button>
            </form>
      }
    </SimpleBar>
    {
      hasMoreScrollContainer
        ? <div
          className={
            "absolute w-full h-[40px] bottom-[-1px] left-0 bg-gradient-to-b from-transparent to-white transition-all duration-200"
            + (hasMoreScrollContainer ? ' opacity-100' : ' opacity-0')
          }
        />
        : <></>
    }
  </div>
  : <>
    <div className="h1 text-center">{ tabHeadline }</div>
    <div className="text-center marginBottom--small">{ tabDescription }</div>
    <form onSubmit={(event) => handleOnSubmitInput(event, functionTab, addSectionPart, addSectionPosition)} className="form-group space--between">
      <div className="inputInlineBtn saveInput">
        <input
          ref={showAddTextInputRef}
          type="number"
          className="text"
          id="input_number"
          placeholder={inputPlaceholder}
          maxLength={customFieldTab?.maxLength}
        />
      </div>
      <button type="submit" className="iconButton add">
        <span className="icon icon-3d-plus"/>
      </button>
    </form>
    <div className="slideContent slideContent__borderTop">
    {
      addedItemsOfSelectedTab.length
        ? <div className="relative py-4">
          <div className="slideContainer__headline">
            { t('configurator_3d_added_number') }
          </div>
          <div className="flex-col">
          {
            addedItemsOfSelectedTab.map((itemKey, index: number) =>
              <div key={'addedNumberObj' + itemKey + index}>
                {
                  isEdit && showOption[itemKey]
                    ? <form onSubmit={(event) => handleOnSubmitEdit(event, functionTab)} className="form-group space--between py-8 border-b border-[#F0F0F0]">
                      <div className="inputInlineBtn saveInput">
                        <input
                          ref={showEditTextInputRef}
                          className="text"
                          id="input_number"
                          type="number"
                          placeholder={inputPlaceholder}
                          defaultValue={addedTextItems[itemKey].text}
                          maxLength={customFieldTab?.maxLength}
                          onBlur={(event) => handleOnSubmitEdit(event, functionTab)}
                        />
                      </div>
                      <button type="submit" className="iconButton !text-sm">
                        { t('configurator_3d_save_edit_text') }
                      </button>
                    </form>
                    : <div
                      id={'trigger' + itemKey + index}
                      key={addedTextItems[itemKey].text + '_' + index}
                      className={
                        "relative w-full flex items-center justify-between py-8 px-4 border-b"
                        + (showOption[itemKey] ? ' border-transparent' : ' border-[#F0F0F0]')
                      }
                    >
                      <button
                        className="absolute w-full h-full inset-0 z-10"
                        onClick={() => handleOnSelectText(itemKey)}
                      />
                      <p className={
                        "font-semibold"
                        + (
                          showOption[itemKey]
                            ? ' text-[#008AC9]'
                            : ''
                        )
                      }>
                        {index + 1}. {addedTextItems[itemKey].text}
                      </p>
                      <div className="relative flex items-center gap-4 z-20">
                        <button
                          className="relative iconButton !text-sm z-20"
                          onClick={() => handleClickEditBtn(itemKey)}
                        >
                          {t('configurator_3d_save_edit_text')}
                        </button>
                        <button
                          className="relative iconButton delete z-20"
                          onClick={() => onClickDeleteText(itemKey)}
                        >
                          <i className="icon icon-3d-trash" />
                        </button>
                        <button onClick={() => handleOnSelectText(itemKey)}>
                          <i
                            className={
                              "icon icon-angle-right transform transition-all duration-500"
                              + (showOption[itemKey] ? ' rotate-90' : ' rotate-0')
                            }
                          />
                        </button>
                      </div>
                    </div>
                }

                {
                  selectedPosition[itemKey] === null || selectedPosition[itemKey] === undefined
                    ? <div className={showOption[itemKey] ? ' block' : ' hidden'}>
                      {/* Add Position */}
                      <div className="slideContainer">
                        <div className="flex justify-between items-center">
                          <p className="text-[#141414]">
                            <span className="font-semibold">{t('configurator_3d_choose_position')}</span>
                          </p>
                          <button
                            className="flex items-center gap-4"
                          >
                            <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                              <div className='absolute top-0 left-0 w-[100px] h-[115px] object-cover bg-white' />
                            </div>
                            <i className={
                              "icon icon-angle-right transform transition-all duration-500 rotate-90"
                            } />
                          </button>
                        </div>
                        <div
                          className={
                            "slideContent__show active"
                          }
                        />
                        <div className="slideContent__contentContainer mt-4">
                          <div
                            className={
                              "relative border-t-2 border-[#f0f0f0]"
                              + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                            }
                          >
                            <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                              {
                                addPositionOptionKeys.map((addPositionKey, index) => {
                                  const position = addPositionKey as NumberAddPosition;
                                  return <button
                                    key={'OPEN_TEXT_ADD_POSITION' + itemKey + index}
                                    className="bg-white group flex flex-col min-w-[123px]"
                                    onClick={() => handleOnChangePosition(itemKey, position, true)}
                                  >
                                    <div
                                      className={
                                        "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                        + (selectedPosition[itemKey] === NUMBER_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                      }
                                    >
                                      <img
                                        src={NUMBER_ADD_POSITION[position].thumbnail}
                                        alt=""
                                        className="absolute !w-full h-auto object-cover"
                                        style={{
                                          objectPosition: 'center ' + NUMBER_ADD_POSITION[position].thumbnailYPostionOption
                                        }}
                                      />
                                    </div>
                                    <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                      { t(NUMBER_ADD_POSITION[position].label) }
                                    </div>
                                  </button>;
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : <div className={showOption[itemKey] ? ' block' : ' hidden'}>
                    <div className="controlBarWrapper borderBottom">
                      <div className="controlBar--left">
                        <button
                          className="icon icon-3d-lock"
                          style={{
                            color: addedTextItems[itemKey].isLock ? '#008AC9' : 'inherit'
                          }}
                          onClick={() => onClickTextLock(itemKey)}
                        />
                      </div>
                    </div>

                    {/* Add Position */}
                    <div className="slideContainer">
                      <div className="flex justify-between items-center">
                        <p className="text-[#141414]">
                          <span className="font-semibold">{t('configurator_3d_choose_position')}</span> / {t(NUMBER_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].label)}
                        </p>
                        <button
                          className="flex items-center gap-4"
                          onClick={() => setShowAddPosition(!showAddPosition)}
                        >
                          <div className="relative w-[44px] h-[30px] border border-[#D4D4D4] overflow-hidden z-10">
                            <img
                              src={NUMBER_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].thumbnail}
                              alt=''
                              className="absolute top-0 left-0 w-[100px] h-[115px] object-cover"
                              style={{
                                objectPosition: 'center ' + NUMBER_ADD_POSITION[selectedPosition[itemKey] || addedTextItems[itemKey].position].thumbnailYPostionOption
                              }}
                            />
                          </div>
                          <i className={
                            "icon icon-angle-right transform transition-all duration-500"
                            + (showAddPosition ? ' rotate-90' : ' rotate-0')
                          } />
                        </button>
                      </div>

                      <div
                        className={
                          "slideContent__show"
                          + (showAddPosition ? ' active' : '')
                        }
                      />
                      <div className="slideContent__contentContainer mt-4">
                        <div
                          className={
                            "relative border-t-2 border-[#f0f0f0]"
                            + " before:bottom-0 before:left-0 before:w-full before:h-6 before:absolute before:z-10 before:bg-gradient-to-t before:from-white before:to-transparent"
                          }
                        >
                          <div className="itemsWrapper--3cols gap-4 pt-6 pr-6 pb-8 transition-all">
                            {
                              addPositionOptionKeys.map((addPositionKey, index) => {
                                const position = addPositionKey as NumberAddPosition;
                                return <button
                                  key={'OPEN_TEXT_ADD_POSITION' + itemKey + index}
                                  className="bg-white group flex flex-col min-w-[123px]"
                                  onClick={() => handleOnChangePosition(itemKey, position)}
                                >
                                  <div
                                    className={
                                      "relative w-full h-[110px] border border-[#f0f0f0] overflow-hidden transition-all group-hover:border-[#D4D4D4]"
                                      + (selectedPosition[itemKey] === NUMBER_ADD_POSITION[position].position ? ' !border-b-2 !border-b-[#008AC9]' : '')
                                    }
                                  >
                                    <img
                                      src={NUMBER_ADD_POSITION[position].thumbnail}
                                      alt=""
                                      className="absolute !w-full h-auto object-cover"
                                      style={{
                                        objectPosition: 'center ' + NUMBER_ADD_POSITION[position].thumbnailYPostionOption
                                      }}
                                    />
                                  </div>
                                  <div className="font-semibold text-[#141414] mt-3 text-[12px]">
                                    { t(NUMBER_ADD_POSITION[position].label) }
                                  </div>
                                </button>;
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div className="slideContent">
                      <div className="slideContainer">
                        <button
                          className="flex justify-between items-center py-4 w-full"
                          onClick={() => setShowFontFamily(!showFontFamily)}
                        >
                          <p className="text-[#141414]">
                            <span className="font-semibold">{t('configurator_3d_font')}</span> / {addedTextItems[itemKey].fontFamily}
                          </p>
                          <i className={
                            "block icon icon-angle-right transform transition-all duration-500"
                            + (showFontFamily ? ' rotate-90' : ' rotate-0')
                          } />
                        </button>
  
                        <div
                          className={
                            "slideContent__show slideContent__show--small z-[-1] "
                            + (showFontFamily ? 'active' : '')
                          }
                        />
                        <div className="slideContent__contentContainer">
                          <div className="itemsWrapper--1col">
                            {
                              FONT_OPTIONS.map((font, index) =>
                                <div
                                  key={'fontOption' + index + font.src}
                                  className={
                                    "itemTemplate "
                                    + (font.fontFamily === addedTextItems[itemKey].fontFamily ? 'active' : '')
                                  }
                                  onClick={() => onChangeFontFamily(itemKey, font.fontFamily)}
                                >
                                  <div className="itemTemplate__description" data-value={font.fontFamily}>
                                    <span
                                      style={{
                                        fontFamily: font.fontFamily
                                      }}
                                    >
                                      {font.fontFamily}
                                    </span>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="slideContent">
                      <div className="slideContainer">
                        <div className="slideContainer__headline marginBottom--xsmall">
                          { t('configurator_3d_choose_colors') }
                        </div>
                        <div className="colorWrapper">
                          <button
                            className="itemColor active !cursor-pointer "
                            onClick={() => setShowMoreColor(!showMoreColor)}
                          >
                            <div className="itemColor--bgColor" style={{ backgroundColor: addedTextItems[itemKey].fill }} />
                          </button>
                        </div>
                        <div
                          className={
                            "slideContent__show "
                            + (showMoreColor ? 'active' : '')
                          }
                        />
                        <div className="number-tab slideContent__contentContainer">
                          <div className="colorWrapper">
                            {
                              colorList.map((itemColor, index) =>
                                <div
                                  key={'colorMore' + itemColor.colorHexCode + index + itemColor.description}
                                  className={
                                    "itemColor "
                                    + (addedTextItems[itemKey].fill === '#' + itemColor.colorHexCode ? 'active' : '')
                                  }
                                  onClick={() => onChangeTextColor('#' + itemColor.colorHexCode)}
                                >
                                  <div
                                    className="itemColor--bgColor tooltip"
                                    style={{ backgroundColor: '#' + itemColor.colorHexCode }}
                                    data-tooltip={itemColor.description}
                                  />
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div className="form-group borderBottom fontSize space--between">
                      <div className="form-group--headline">
                        { t('configurator_3d_font_size') } { t('configurator_3d_width_cm') }
                      </div>
                      <div className="quantity quantitySizes">
                        <div className="spinner_wrapper">
                          <div
                            className="spinner_down"
                            onClick={() => handleOnDecreaseFontSize('width')}
                          >
                            <i className="icon icon-spinner-down"/>
                          </div>
                          {/* // 1cm = 37.79527559055118px */}
                          <input
                            id={`inputQtyWidth_${itemKey}`}
                            defaultValue={Math.round((addedTextItems[itemKey].scaledWidth) * 10) / 10}
                            className="input_quantity_line"
                            type="number"
                            onFocus={(e) => e.target.select()}
                            onChange={(e) => handleOnChangeWidthFontSize(e, addedTextItems[itemKey].scaledWidth)}
                          />
                          <div
                            className="spinner_up"
                            onClick={() => handleOnIncreaseFontSize('width')}
                          >
                            <i className="icon icon-spinner-up"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group borderBottom fontSize space--between">
                      <div className="form-group--headline">
                        { t('configurator_3d_font_size') } { t('configurator_3d_height_cm') }
                      </div>
                      <div className="quantity quantitySizes">
                        <div className="spinner_wrapper">
                          <div
                            className="spinner_down"
                            onClick={() => handleOnDecreaseFontSize('height')}
                          >
                            <i className="icon icon-spinner-down"/>
                          </div>
                          {/* // 1cm = 37.79527559055118px */}
                          <input
                            id={`inputQtyHeight_${itemKey}`}
                            defaultValue={Math.round((addedTextItems[itemKey].scaledHeight) * 10) / 10}
                            className="input_quantity_line"
                            type="number"
                            onFocus={(e) => e.target.select()}
                            onChange={(event) => handleOnChangeHeightFontSize(event)}
                          />
                          <div
                            className="spinner_up"
                            onClick={() => handleOnIncreaseFontSize('height')}
                          >
                            <i className="icon icon-spinner-up"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative z-20 controlBarWrapper marginBottom--large">
                      <div className="controlBar--left">
                        <button
                          className="icon icon-3d-foreground"
                          onClick={() => onSendTextToFront(itemKey)}
                        />
                        <button
                          className="icon icon-3d-background"
                          onClick={() => onSendTextToBack(itemKey)}
                        />
                      </div>
                      <div className="controlBar--right">
                        <button
                          className="icon icon-3d-trash"
                          onClick={() => onClickDeleteText(itemKey)}
                        />
                      </div>
                    </div>
                  </div>
                }
              </div>
            )
          }
          </div>
        </div>
        : <></>
    }
    </div>
    <div className="flex justify-between mt-20">
      <button
        className="px-[27px] py-[12px] bg-[#FAFAFA] text-[#5B5B5B]"
        onClick={setSelectTabBack}
      >
        <div className="flex items-center">
          <i className="icon icon-angle-left text-[14px] mr-2" />
          <span>{ t('configurator_3d_back') }</span>
        </div>
      </button>
      <button
        className="px-[27px] py-[12px] bg-[#008AC9] text-[#FFFFFF]"
        onClick={setSelectTabNext}
      >
        <div className="flex items-center">
          <span>{ t('configurator_3d_basket_next') }</span>
          <i className="icon icon-angle-right text-[14px] ml-2" />
        </div>
      </button>
    </div>
  </>;
});

export default JakoNumberTab;
