import { createContext } from 'react';
import type { CustomFieldsResponseItem } from '../webcomponent/responses';

interface IBaseContext {
  isLoadingCustomFields: boolean,
  customFields: CustomFieldsResponseItem[],
  correlationId: string | null,
  cuttingFormCode: string,
  basketUrl: string,
  languagecode: string,
  designheaderid: string,
  company: string | null,
  shopCode: string | null,
  visitorId: string | null,
  baseapiurl: string,
  uiapiurl: string,
  itemno: string,
  onclicksubmit?: any
}

const INITIAL_CUTTING_CODE = 'K4211SSRNSIMFCS';
// K4211SSRNSIMFCS
// K4400

const isLoadingCustomFields = false;
const customFields: CustomFieldsResponseItem[] = [];

const wc = document.querySelector('web-configurator');

let correlationId: string | null = '111';
let company: string | null = 'JAKO AG';
let shopCode: string | null = 'B2C';
let visitorId: string | null = '222';
let cuttingFormCode: string = INITIAL_CUTTING_CODE;
let basketUrl: string = 'https://jakob2b-dev.dc-test.de/b2b/de/3d_configurator/?step=basket';
let languagecode: string = 'DE';
let designheaderid: string = '0';
let baseapiurl: string = 'https://jako-api.dc-test.de/public';
let uiapiurl: string = 'https://jako.jfnet.de/api/v1/';
let itemno: string = '55';
let onclicksubmit;
if (wc) {
  correlationId = wc.getAttribute('correlationId');
  company = wc.getAttribute('company');
  shopCode = wc.getAttribute('shopCode');
  visitorId = wc.getAttribute('visitorId');
  cuttingFormCode = wc.getAttribute('cuttingFormCode') || INITIAL_CUTTING_CODE;
  basketUrl = wc.getAttribute('basketurl') || 'https://jakob2b-dev.dc-test.de/b2b/de/3d_configurator/?step=basket';
  languagecode = wc.getAttribute('languagecode') || 'DE';
  designheaderid = wc.getAttribute('designheaderid') || '0';
  baseapiurl = wc.getAttribute('baseapiurl') || 'https://jako-api.dc-test.de/public';
  uiapiurl = wc.getAttribute('uiapiurl') || 'https://jako.jfnet.de/api/v1/';
  itemno = wc.getAttribute('itemno') || '55';
  const wcAny = wc as any;
  const submitFunc = wcAny.onclicksubmit as any;
  onclicksubmit = submitFunc;
} else {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('testCutting');
  cuttingFormCode = myParam ? myParam : INITIAL_CUTTING_CODE.slice(0, 5) + 'BE' + INITIAL_CUTTING_CODE.slice(5);
}

const IS_IOS =
  /^(iPad|iPhone|iPod)/.test(window.navigator.platform) ||
  (/^Mac/.test(window.navigator.platform) && window.navigator.maxTouchPoints > 1);
if (IS_IOS) {
  window.createImageBitmap = undefined as any;
}

export const BaseContext = createContext<IBaseContext>({
  isLoadingCustomFields,
  customFields,
  correlationId,
  visitorId,
  company,
  shopCode,
  cuttingFormCode,
  basketUrl,
  languagecode,
  designheaderid,
  baseapiurl,
  uiapiurl,
  itemno,
  onclicksubmit
});