import React from 'react';
import * as ReactDOMClient from "react-dom/client";
import './i18n';

import reactToWebComponent from "react-to-webcomponent";
import Configurator from './App';

const WcJakoConfigurator = reactToWebComponent(Configurator, React, ReactDOMClient);

customElements.define("web-configurator", WcJakoConfigurator);
